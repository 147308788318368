import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

import FormBuyback from "./FormBuyback";
import TableBuyback from "./TableBuyback";

interface TabBuybackProps {
  baitulmalAssetId?: number;
}

const TabBuyback: React.FC<TabBuybackProps> = ({ baitulmalAssetId }) => {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const [baitulmalBuybackId, setBaitulmalBuybackId] = useState<number | null>(null);
  const [showFormBuyback, setShowFormBuyback] = useState(false);

  const toggleFormBuyback = (action: "create" | "edit" | "show", baitulmalBuybackId?: number) => {
    setAction(action);
    setBaitulmalBuybackId(baitulmalBuybackId);
    setShowFormBuyback(true);
  };

  return (
    <Flex direction="column">
      <TableBuyback
        baitulmalAssetId={baitulmalAssetId}
        toggleFormBuyback={toggleFormBuyback}
      />

      {showFormBuyback && (
        <FormBuyback
          action={action}
          baitulmalAssetId={baitulmalAssetId}
          baitulmalBuybackId={baitulmalBuybackId}
        />
      )}
    </Flex>
  );
}

export default TabBuyback;
