import axios from "utils/axios";
import { IStateSetting } from "entities/setting/dataSettings";

export async function getNegeriOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/negeri",
    params,
  });
  return data;
}

export async function getNegeriOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/negeri/${id}`,
  });
  return data;
}


export async function createEditNegeriOption(
  values: IStateSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/negeri/${id}` : "/general/negeri";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteNegeriOption(
  id: string | number | null
) {
  const apiUrl = `/general/negeri/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

