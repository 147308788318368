import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Flex,
  Text,
  Link,
  List,
  Icon,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";

import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import Resource from "components/resource/Resource";
import Section from "components/section/Section";
import FullScreenLoader from "components/loader/FullScreenLoader";

import FormPurchaseInfo from "./Form/FormPurchaseInfo";
import FormAcquisitionInfo from "./Form/FormAcquisitionInfo";

import {
  IWakafIstibdalInfoTable,
  IWakafIstibdalInfoFormValues,
} from "entities/admin/wakafManagement";
import { useWakafIstibdalInfos } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalInfo/useWakafIstibdalAssessments";
import { useDeleteWakafIstibdalInfo } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalInfo/useDeleteWakafMaintenance";

import { formatDate } from "utils/helpers";

interface TableIstibdalInfoProps {
  wakafAssetId?: number;
  wakafIstibdalAssessmentId?: number;
}

const columnHelper = createColumnHelper<IWakafIstibdalInfoTable>();

const TableIstibdalInfo = ({
  wakafAssetId,
  wakafIstibdalAssessmentId,
}: TableIstibdalInfoProps) => {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const [showForm, setShowForm] = useState(false);
  const [istibdalType, setIstibdalType] = useState("1");
  const [wakafIstibdalInfo, setWakafIstibdalInfo] =
    useState<IWakafIstibdalInfoFormValues | null>(null);

  const { wakafIstibdalInfos, isLoading } = useWakafIstibdalInfos({
    idPengambilanPenilaianIstibdal: wakafIstibdalAssessmentId,
  });
  const { removeWakafIstibdalInfo, isDeleting } = useDeleteWakafIstibdalInfo();

  const toggleFormIstibdalInfo = (
    action: "create" | "edit" | "show",
    wakafIstibdalInfo?: IWakafIstibdalInfoFormValues,
    close = false
  ) => {
    setAction(action);
    setWakafIstibdalInfo(wakafIstibdalInfo);
    setShowForm(!close);

    if (wakafIstibdalInfo?.jenis_istibdal == "1") {
      setIstibdalType("1");
    } else if (wakafIstibdalInfo?.jenis_istibdal == "2") {
      setIstibdalType("2");
    }
  };

  const columns = [
    columnHelper.accessor("updated_at", {
      header: () => <Text variant="tableHeading">Tarikh Kemaskini</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("jenis_istibdal", {
      header: () => <Text variant="tableHeading">Jenis Istibdal</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue() == "1"
            ? "Istibdal Melalui Pembelian"
            : "Istibdal Melalui Pengambilan"}
        </Text>
      ),
    }),
    columnHelper.accessor("jum_istibdal_pembelian_rm", {
      header: () => <Text variant="tableHeading">Amaun Istibdal</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id_aset_wakaf_baru", {
      header: () => <Text variant="tableHeading">Aset Baru</Text>,
      cell: (info) => {
        if (info.row.original?.id_aset_wakaf_baru) {
          return (
            <NavLink to={`/admin/wakaf/aset/show/${info.getValue()}`}>
              <Text variant="link" fontSize="sm" fontWeight="500">
                {info.getValue()}
              </Text>
            </NavLink>
          );
        }
        return <Text></Text>;
      },
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <Link
            as={"button"}
            onClick={() => {
              toggleFormIstibdalInfo("edit", info.row.original);
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </Link>

          <Link
            as={"button"}
            onClick={() => {
              toggleFormIstibdalInfo("show", info.row.original);
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </Link>

          <Link
            as={"button"}
            onClick={() => {
              removeWakafIstibdalInfo(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) return <FullScreenLoader />;

  return (
    <Flex mb="1.5rem" flexDirection={"column"}>
      <Resource
        columns={columns}
        data={wakafIstibdalInfos}
        title={"Senarai Istibdal"}
        createTitle="Tambah Rekod Istibdal"
        onCreate={() => toggleFormIstibdalInfo("create", null)}
        allowDownload
        onClickDownload={() => console.log}
      />

      {showForm && (
        <Flex flexDirection={"column"} paddingTop={"10px"} paddingLeft={"20px"}>
          <Section size={"xl"} title="Tambah Rekod Istibdal">
            <FormControl display={"flex"} flexDirection={"row"}>
              <FormLabel mr={"80px"} as="legend">
                Jenis Istibdal
              </FormLabel>

              <RadioGroup
                onChange={(value) => setIstibdalType(value)}
                value={istibdalType}
                isDisabled={action !== "create"}
              >
                <Stack direction={"row"} spacing={"18px"}>
                  <Radio value={"1"}>Istibdal Melalui Pembelian</Radio>
                  <Radio value={"2"}>Istibdal Melalui Pengambilan</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            {istibdalType === "1" ? (
              <FormPurchaseInfo
                action={action}
                wakafAssetId={wakafAssetId}
                wakafIstibdalAssessmentId={wakafIstibdalAssessmentId}
                initialData={wakafIstibdalInfo}
              />
            ) : (
              <FormAcquisitionInfo
                action={action}
                wakafAssetId={wakafAssetId}
                wakafIstibdalAssessmentId={wakafIstibdalAssessmentId}
                initialData={wakafIstibdalInfo}
              />
            )}
          </Section>
        </Flex>
      )}
    </Flex>
  );
};

export default TableIstibdalInfo;
