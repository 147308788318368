import React from "react";
import FormUnitSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useUnitSetting } from "hooks/setting/dataSetting/unitSetting/useUnitSetting";

export default function EditDepartmentSetting() {
  const { isLoading, unit, error } = useUnitSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormUnitSetting action="edit" initialData={unit?.data} />;
}
