import React from "react";
import FormReportSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useReportSetting } from "hooks/setting/dataSetting/reportSetting/useReportSetting";

export default function EditDepartmentSetting() {
  const { isLoading, report, error } = useReportSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormReportSetting action="edit" initialData={report?.data} />;
}
