import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getUser } from "services/setting/userService";

export function useUser() {
  const { userId } = useParams<{ userId: string }>();
  const toast = useToast();

  const {
    isLoading,
    data: user,
    error,
  } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => getUser(userId),
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: "always",
    onError: (err) => {
      toast({
        title: "Error fetching data",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isLoading, error, user };
}