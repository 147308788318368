import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditNoFailPtgOption } from "services/setting/dataSetting/noFailPtgSettingService";
import { INoFailPtgSetting } from "entities/setting/dataSettings";

export function useEditNoFailPtgSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: editNoFailPtgSetting, isLoading: isEditing } = useMutation({
    mutationFn: ({
      noFailPtgSetting,
      id,
    }: {
      noFailPtgSetting: INoFailPtgSetting;
      id: string | null;
    }) => createEditNoFailPtgOption(noFailPtgSetting, id),
    onSuccess: () => {
      toast({
        title: "No fail ptg successfully updated",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["noFailPtg"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isEditing, editNoFailPtgSetting };
}
