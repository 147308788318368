export const formLabelStyles = {
  components: {
    FormLabel: {
      variants: {
        main: (props: any) => ({
          ms: "4px",
          fontSize: "sm",
          fontWeight: "500",
          color: "navy.700",
          display: "flex",
        }),
      },
    },
  },
};
