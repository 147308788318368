import axios from "utils/axios";
import { IBaitulmalAssetFormValues } from "entities/admin/baitulmalManagement";

export async function getBaitulmalAsset(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/aset-baitulmal/${id}`,
  });
  return data;
}

export async function getBaitulmalAssets(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/aset-baitulmal",
    params
  });
  return data;
}

export async function createEditBaitulmalAsset(
  values: IBaitulmalAssetFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/aset-baitulmal/${id}` : "/aset-baitulmal";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteBaitulmalAsset(
  id: string | number | null
) {
  const apiUrl = `/aset-baitulmal/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}