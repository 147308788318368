import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { deleteNegeriOption } from "services/setting/dataSetting/stateSettingService";

export function useDeleteStateSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: removeStateSetting, isLoading: isDeleting } = useMutation({
    mutationFn: (id: number) => deleteNegeriOption(id),
    onSuccess: () => {
      toast({
        title: "State successfully deleted",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["states"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isDeleting, removeStateSetting };
}

