import React from "react";
import FormAcquisition from "./FormAcquisition";
import { IWakafAsset } from "entities/admin/wakafManagement";

interface ShowAcquisitionProps {
  wakafAsset?: IWakafAsset;
}

export default function ShowAcquisition({ wakafAsset }: ShowAcquisitionProps) {
  return (
    <FormAcquisition
      action="show"
      initialData={{
        id: wakafAsset?.id,
        tarikh_belian: wakafAsset?.tarikh_belian,
        deskripsi_belian: wakafAsset?.deskripsi_belian,
        harga_belian: wakafAsset?.harga_belian,
        harga_nilaian: wakafAsset?.harga_nilaian,
        senarai_lampiran: wakafAsset?.senarai_lampiran.filter((file: any) => file.tab_name === "aset_wakaf"),
      }}
    />
  );
}
