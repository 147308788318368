import React, { useState } from "react";
import { Box, Code, Text } from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";

import Resource from "components/resource/Resource";
import { IWakafAudit } from "entities/admin/wakafManagement";

import { formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<IWakafAudit>();

interface TabAuditProps {
  audits: IWakafAudit[];
}

const TabAudit = ({ audits }: TabAuditProps) => {
  const [data, setData] = useState({ data: audits });

  const columns = [
    columnHelper.accessor("updated_at", {
      header: () => <Text variant="tableHeading">Tarikh Kemaskini</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary" verticalAlign="top">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("user.name", {
      header: () => <Text variant="tableHeading">Nama</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "old_values",
      header: () => <Text variant="tableHeading">Nilai Lama</Text>,
      cell: (info) => (
        <Box maxWidth={"340px"}>
          <Code>
            {typeof info.row.original.old_values === "object" &&
              Object.keys(info.row.original.old_values).length === 0 && (
                <Text>Tiada Nilai Lama</Text>
              )}
            {typeof info.row.original.old_values === "object" &&
              Object.entries(info.row.original.old_values).map(
                ([key, value]) => <Text key={key}>{`${key} : ${value}`}</Text>
              )}
          </Code>
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "new_values",
      header: () => <Text variant="tableHeading">Nilai Baru</Text>,
      cell: (info) => (
        <Box maxWidth={"340px"}>
          <Code>
            {typeof info.row.original.new_values === "object" &&
              Object.entries(info.row.original.new_values).map(
                ([key, value]) => <Text key={key}>{`${key} : ${value}`}</Text>
              )}
          </Code>
        </Box>
      ),
    }),
  ];

  return (
    <Resource
      columns={columns}
      data={data}
      title={"Senarai Rekod Audit Data"}
      allowDownload
      onClickDownload={() => console.log}
    />
  );
};

export default TabAudit;
