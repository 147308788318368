import React, { useRef, useEffect } from "react";
import {
  SimpleGrid,
  FormControl,
  Input,
  FormLabel,
  Button,
  Flex,
  Text,
  Icon,
  Link,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import CreatableSelectField from "components/fields/CreatableSelectField";
import useDataAttachment from "hooks/setting/dataSetting/useDataAttachment";

interface FileDescription {
  id?: string;
  id_senarai_lampiran: string;
  tab_name: any;
  file_name: string;
  file_description: string;
  file_path?: string;
  file_type?: string;
}

interface FileUploadFieldProps {
  files: any;
  fileDescriptions: FileDescription[];
  setFiles: (files: any) => void;
  setFileDescriptions: (fileDescriptions: FileDescription[]) => void;
  tabName: string;
  readOnly?: boolean;
}

export default function FileUploadField({
  files,
  fileDescriptions,
  setFiles,
  setFileDescriptions,
  readOnly = false,
  tabName,
}: FileUploadFieldProps) {
  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const {
    attachmentNameOps,
    createAttachmentNameOptions,
    isCreatingAttachmentNameOptions,
  } = useDataAttachment();

  const updateFiles = (index: number, key: keyof FileDescription, value: any) => {
    const updatedFileDescriptions = [...fileDescriptions];

    if (key === "file_name" && value) {
      updatedFileDescriptions[index]["file_name"] = value.name;
      setFiles((prevItems: any) => [...prevItems, value]);
    } else {
      updatedFileDescriptions[index][key] = value;
    }

    setFileDescriptions(updatedFileDescriptions);
  };

  const handleChooseFile = (index: number) => {
    if (!readOnly && fileInputRefs.current[index]) {
      fileInputRefs.current[index]?.click();
    }
  };

  const handleDeleteFile = (index: number) => {
    const updatedFiles = files.filter((_: any, i: number) => i !== index);
    const updatedFileDescriptions = fileDescriptions.filter((_: any, i: number) => i !== index);

    setFiles(updatedFiles);
    setFileDescriptions(updatedFileDescriptions);
  };

  const handleAddField = () => {
    if (fileDescriptions.every((fileDescription) => fileDescription.file_name && fileDescription.file_name.trim())) {
      setFileDescriptions([
        ...fileDescriptions,
        {
          id_senarai_lampiran: "",
          tab_name: tabName,
          file_name: "",
          file_description: "",
        },
      ]);
    } else {
      alert("Please fill in all fields before adding a new file.");
    }
  };

  useEffect(() => {
    if(fileDescriptions.length > 0) {
      const fileNames = fileDescriptions.map((fileDescription: FileDescription) => fileDescription.file_name);
      const updatedFiles = files.filter((file: any) => fileNames.includes(file?.name));
      setFiles(updatedFiles);
    }
  }, [fileDescriptions]);

  return (
    <>
      {fileDescriptions.map((fileDescription, index) => (
        <SimpleGrid
          columns={{ base: 3 }}
          gap={{ sm: "6px", md: "18px" }}
          mb={"18px"}
          key={index}
        >
          <CreatableSelectField
            label={"Nama Lampiran"}
            defaultOptions={attachmentNameOps}
            defaultValue={fileDescription?.id_senarai_lampiran}
            isMulti={false}
            onChange={(value) =>
              updateFiles(index, "id_senarai_lampiran", value)
            }
            onCreateOption={(value) =>
              createAttachmentNameOptions({
                newOption: { nama: value },
                id: null,
              })
            }
            isLoading={isCreatingAttachmentNameOptions}
            readOnly={readOnly}
          />

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Fail Lampiran
            </FormLabel>
            <Flex gap={"10px"} alignItems={"center"} paddingTop={"4px"}>
              <Button
                variant="action"
                fontSize={"xs"}
                size="sm"
                onClick={() => handleChooseFile(index)}
                disabled={readOnly}
              >
                Choose File
              </Button>

              {fileDescription.file_path ? (
                <Link
                  href={fileDescription.file_path}
                  maxWidth={"200px"}
                  color={"brand.800"}
                  textDecoration={"underline"}
                  fontSize={"sm"}
                  isExternal
                >
                  {fileDescription.file_name}
                </Link>
              ) : (
                <Text
                  maxWidth={"200px"}
                  color={fileDescription.file_name ? "brand.800" : "secondaryGray.800"}
                  fontSize={"sm"}
                >
                  {fileDescription.file_name || "No file chosen"}
                </Text>
              )}
            </Flex>

            <input
              ref={(el) => (fileInputRefs.current[index] = el)}
              hidden
              type="file"
              accept="application/pdf"
              onChange={(e) =>
                updateFiles(
                  index,
                  "file_name",
                  e.target.files ? e.target.files[0] : null
                )
              }
            />
          </FormControl>

          <Flex alignItems={"flex-end"} gap={"10px"}>
            <FormControl>
              <FormLabel variant="main" mb="8px">
                Deskripsi Lampiran
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                fontWeight="500"
                size="md"
                value={fileDescription.file_description}
                onChange={(e) =>
                  updateFiles(index, "file_description", e.target.value)
                }
                readOnly={readOnly}
              />
            </FormControl>

            {(!fileDescription.file_path && !readOnly) && (
              <Button
                type="button"
                width={"max-content"}
                onClick={() => {
                  handleDeleteFile(index);
                }}
              >
                <Icon
                  w="24px"
                  h="24px"
                  me="5px"
                  color={"red.500"}
                  as={MdDelete}
                />
              </Button>
            )}
          </Flex>
        </SimpleGrid>
      ))}

      {!readOnly && (
        <Button
          variant="action"
          width={"max-content"}
          fontSize="sm"
          mb={20}
          onClick={handleAddField}
        >
          Tambah lampiran
        </Button>
      )}
    </>
  );
}