import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getBaitulmalAsset } from "services/admin/baitulmalManagement/baitulmalAssetService";

export function useBaitulmalAsset() {
  const { assetId } = useParams<{ assetId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: baitulmalAsset,
    error,
  } = useQuery({
    queryKey: ["baitulmalAsset", assetId],
    queryFn: () => getBaitulmalAsset(assetId),
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: "always",
    enabled: !!assetId,
  });

  return { isLoading, isInitialLoading, error, baitulmalAsset };
}

