import React, { useState, useMemo, useEffect } from "react";
import { Flex, Input } from "@chakra-ui/react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";

import markerIconPng from "leaflet/dist/images/marker-icon.png";

interface MapLocatorProps {
  onLocationChange: (location: [number, number]) => void;
  required?: boolean;
  readOnly?: boolean;
}

export default function MapLocator({ onLocationChange, required = false, readOnly = false }: MapLocatorProps) {
  const [markerPosition, setMarkerPosition] = useState<[number, number]>([5.3488, 100.4701]);

  const markerRef = React.useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          const newPosition = marker.getLatLng();
          setMarkerPosition([parseFloat(newPosition.lat.toFixed(4)), parseFloat(newPosition.lng.toFixed(4))]);
          onLocationChange([parseFloat(newPosition.lat.toFixed(4)), parseFloat(newPosition.lng.toFixed(4))]);
        }
      },
    }),
    [],
  )

  useEffect(() => {
    onLocationChange(markerPosition);
  }, [])
  
  
  return (
    <Flex flexDirection={"column"} gap={"12px"} zIndex={"1"}>
      <MapContainer
        center={[5.3488, 100.4701]}
        zoom={12}
        style={{ height: "400px", width: "100%", zIndex: 1 }}
        scrollWheelZoom={false}
        className="map-container"
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker
          draggable={!readOnly}
          eventHandlers={eventHandlers}
          position={markerPosition}
          ref={markerRef}
          icon={
            new Icon({
              iconUrl: markerIconPng,
              iconSize: [25, 41],
              iconAnchor: [12, 41],
            })
          }
        >
          <Popup>Your location</Popup>
        </Marker>
      </MapContainer>

      <Input
        variant="auth"
        fontSize="sm"
        type="text"
        fontWeight="500"
        size="md"
        value={markerPosition.join(", ")}
        placeholder="Koordinat X, Koordinat Y"
        required={required}
        readOnly={readOnly}
        disabled
      />
    </Flex>
  );
}