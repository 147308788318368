import axios from "utils/axios";
import { IBaitulmalRegistrationFormValues } from "entities/admin/baitulmalRegistration";

export async function getBaitulmalRegistration(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/cadangan-beli-aset/${id}`,
  });
  return data;
}

export async function getBaitulmalRegistrations(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/cadangan-beli-aset",
    params
  });
  return data;
}

export async function createEditBaitulmalRegistration(
  values: IBaitulmalRegistrationFormValues,
  id: number | null
) {
  const apiUrl = id ? `/cadangan-beli-aset/${id}` : "/cadangan-beli-aset";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteBaitulmalRegistration(
  id: string | number | null
) {
  const apiUrl = `/cadangan-beli-aset/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}