import React from "react";
import FormSumberWakafSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useSumberWakafSetting } from "hooks/setting/dataSetting/sumberWakafSetting/useSumberWakafSetting";

export default function EditSumberWakafSetting() {
  const { isLoading, sumberWakaf, error } = useSumberWakafSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormSumberWakafSetting action="edit" initialData={sumberWakaf?.data} />;
}
