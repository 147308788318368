import { useQuery } from "@tanstack/react-query";
import { getOptions } from "services/setting/optionService";

export default function useOptions() {
  const { data: options } = useQuery({
    queryKey: ["options"],
    queryFn: getOptions,
  });

  // API
  const extractOptions = (optionKey: string) =>
    options?.data?.[optionKey] || [];
  const negeriOps = extractOptions("negeri");
  const daerahOps = extractOptions("daerah");
  const bandarPekanMukimOps = extractOptions("bandarPekanMukim");
  const dunOps = extractOptions("dun");
  const parlimenOps = extractOptions("parlimen");
  const seksyenOps = extractOptions("seksyen");
  const kariahOps = extractOptions("kariah");
  const jenisPermohonanWakafOps = extractOptions("jenisPermohonanWakaf");
  const jenisAsetOps = extractOptions("jenisAset");
  const tujuanSighahWakafOps = extractOptions("tujuanSighahWakaf");
  const jenisWakafOps = extractOptions("jenisWakaf");
  const kategoriGunaTanahGeranOps = extractOptions("kategoriGunaTanahGeran");
  const senaraiLampiranOps = extractOptions("senaraiLampiran");
  const noBangunanOps = extractOptions("noBangunan");
  const roleOps = extractOptions("role");
  const unitOps = extractOptions("unit");
  const jabatanOps = extractOptions("jabatan");
  const kaedahPerolehanOps = extractOptions("kaedahPerolehan");
  const gunaTanahSemasaOps = extractOptions("gunaTanahSemasa");
  const kegunaanTanahWakafSemasaOps = extractOptions("kegunaanTanahWakafSemasa");
  const jenisAduanOps = extractOptions("jenisAduan");
  const kontraktorOps = extractOptions("kontraktor");
  const jenisCukaiOps = extractOptions("jenisCukai");
  const tujuanPengambilanOps = extractOptions("tujuanPengambilan");
  const istibdalStatusOps = extractOptions("istibdalStatus");
  const jenisPengambilanIstibdalOps = extractOptions("jenisPengambilanIstibdal");
  const cadanganBeliAsetStatusOps = extractOptions("cadanganBeliAsetStatus");
  const statusAsetBaitulmalOps = extractOptions("statusAsetBaitulmal");
  const userStatusOps = extractOptions("userStatus");
  const statusAsetWakafOps = extractOptions("asetWakafStatus");

  const statusPermohonanOps = [
    { value: 0, label: "Dalam Proses" },
    { value: 1, label: "Lulus" },
    { value: -1, label: "Gagal" },
  ];

  const rayuanCukaiOps = [
    { value: "1", label: "Ya" },
    { value: "0", label: "Tidak" },
  ];

  const statusBayaranOps = [
    { value: "1", label: "Selesai" },
    { value: "0", label: "Belum Selesai" },
  ];

  // OUTPUT
  return {
    negeriOps,
    daerahOps,
    bandarPekanMukimOps,
    dunOps,
    parlimenOps,
    seksyenOps,
    kariahOps,
    jenisPermohonanWakafOps,
    jenisAsetOps,
    tujuanSighahWakafOps,
    jenisWakafOps,
    kategoriGunaTanahGeranOps,
    senaraiLampiranOps,
    noBangunanOps,
    roleOps,
    unitOps,
    jabatanOps,
    kaedahPerolehanOps,
    gunaTanahSemasaOps,
    kegunaanTanahWakafSemasaOps,
    jenisAduanOps,
    kontraktorOps,
    jenisCukaiOps,
    statusPermohonanOps,
    rayuanCukaiOps,
    tujuanPengambilanOps,
    istibdalStatusOps,
    jenisPengambilanIstibdalOps,
    cadanganBeliAsetStatusOps,
    statusBayaranOps,
    statusAsetBaitulmalOps,
    userStatusOps,
    statusAsetWakafOps
  };
}
