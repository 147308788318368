import React from "react";
import FormGunatanahSemasaSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useGunatanahSemasaSetting } from "hooks/setting/dataSetting/gunatanahSemasaSetting/useGunatanahSemasaSetting";

export default function EditGunatanahSemasaSetting() {
  const { isLoading, gunatanahSemasa, error } = useGunatanahSemasaSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormGunatanahSemasaSetting action="edit" initialData={gunatanahSemasa?.data} />;
}
