import React from "react";
import FormBaitulmalRegistration from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useBaitulmalRegistration } from "hooks/admin/baitulmalRegistration/useBaitulmalRegistration";

const EditBaitulmalRegistration = () => {
  const { baitulmalRegistration, isInitialLoading } = useBaitulmalRegistration();

  if (isInitialLoading) {
    return <FullScreenLoader />;
  }

  return <FormBaitulmalRegistration action="edit" initialData={baitulmalRegistration?.data} />;
}

export default EditBaitulmalRegistration;

