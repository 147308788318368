export const textStyles = {
  components: {
    Text: {
      variants: {
        main: (props: any) => ({
          fontWeight: "500",
          color: "navy.700",
        }),
        secondary: (props: any) => ({
          fontWeight: "700",
          color: "secondaryGray.900",
        }),
        subtitle: (props: any) => ({
          fontWeight: "400",
          color: "gray.400",
        }),
        link: (props: any) => ({
          fontWeight: "500",
          color: "brand.500",
        }),
        brand: (props: any) => ({
          color: "brand.500",
        }),
        tableHeading: (props: any) => ({
          fontWeight: "700",
          color: "gray.400",
          justifyContent: "space-between",
          align: "center",
          fontSize: { sm: "10px", lg: "12px" }
        }),
      },
    },
  },
};
