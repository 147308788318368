import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getWakafTaxes } from "services/admin/wakafManagement/wakafTaxService";
import { PAGE_SIZE } from "utils/constants";

export function useWakafTaxes(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("limit")
    ? PAGE_SIZE
    : Number(searchParams.get("limit"));

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["wakafTaxes", currentPage, currentLimit],
      queryFn: () => getWakafTaxes({ ...params, page: currentPage, limit: currentLimit }),
    });
  }, [currentPage, currentLimit, params, queryClient]);

  const {
    isLoading,
    data: wakafTaxes,
    error,
  } = useQuery({
    queryKey: ["wakafTaxes", currentPage, currentLimit],
    queryFn: () => getWakafTaxes({ ...params, page: currentPage, limit: currentLimit }),
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: "always",
  });

  return { isLoading, error, wakafTaxes };
}

