import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";

import Card from "components/card/Card";
import TabBasicInformation from "./components/TabBasicInformation";
import TabChronology from "./components/TabChronology/TabChronology";
import TabAcquisition from "./components/TabAcquisition";
import TabAssessment from "./components/TabAssessment";
import TabMaintenance from "./components/TabMaintenance";
import TabTax from "./components/TabTax";
import TabHistory from "./components/TabHistory";
import TabAudit from "./components/TabAudit/TabAudit";
import TabIstibdal from "./components/TabIstibdal";
import TabDevelopment from "./components/TabDevelopment";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { useWakafAsset } from "hooks/admin/wakafManagement/wakafAsset/useWakafAsset";

interface LocationState {
  tabIndex?: number;
}

const WakafManagement = () => {
  const [action, setAction] = useState<"create" | "show" | "edit">("create");
  const [tabIndex, setTabIndex] = useState(0);

  const { wakafAsset, isInitialLoading } = useWakafAsset();
  const location = useLocation<LocationState>();

  useEffect(() => {
    if (location.pathname.includes("show")) {
      setAction("show");
    } else if (location.pathname.includes("kemaskini")) {
      setAction("edit");
    } else {
      setAction("create");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.state && location.state.tabIndex !== undefined) {
      setTabIndex(location.state.tabIndex);
    }
  }, [location.state]);

  if (isInitialLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Tabs variant="main" defaultIndex={tabIndex}>
          <TabList>
            <Tab>Maklumat Asas</Tab>
            <Tab isDisabled={action === "create"}>Pembelian</Tab>
            <Tab isDisabled={action === "create"}>Penilaian</Tab>
            <Tab isDisabled={action === "create"}>Penyelenggaraan</Tab>
            <Tab isDisabled={action === "create"}>Cukai</Tab>
            <Tab isDisabled={action === "create"}>Istibdal</Tab>
            <Tab isDisabled={action === "create"}>Pembangunan</Tab>
            <Tab isDisabled={action === "create"}>Sejarah</Tab>
            <Tab isDisabled={action === "create"}>Kronologi</Tab>
            <Tab isDisabled={action === "create"}>Audit Data</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <TabBasicInformation action={action} />
            </TabPanel>
            <TabPanel>
              <TabAcquisition action={action} wakafAsset={wakafAsset?.data} />
            </TabPanel>
            <TabPanel>
              <TabAssessment wakafAssetId={wakafAsset?.data?.id} />
            </TabPanel>
            <TabPanel>
              <TabMaintenance wakafAssetId={wakafAsset?.data?.id} />
            </TabPanel>
            <TabPanel>
              <TabTax wakafAssetId={wakafAsset?.data?.id} />
            </TabPanel>
            <TabPanel>
              <TabIstibdal
                wakafAssetId={wakafAsset?.data?.id}
                wakafIstibdalAssessment={
                  wakafAsset?.data?.pengambilan_penilaian_istibdal
                }
                assetArea={wakafAsset?.data?.luas_aset}
                istibdalSummary={wakafAsset?.data?.istibdal_summary}
              />
            </TabPanel>
            <TabPanel>
              <TabDevelopment
                wakafAssetId={wakafAsset?.data?.id}
                pecahBahagian={wakafAsset?.data?.pecah_bahagian_wakaf}
                pecahSempadan={wakafAsset?.data?.pecah_sempadan_wakaf}
                pencantuman={wakafAsset?.data?.pencantuman_wakaf}
                assetArea={wakafAsset?.data?.luas_aset}
              />
            </TabPanel>
            <TabPanel>
              <TabHistory wakafAssetId={wakafAsset?.data?.id} />
            </TabPanel>
            <TabPanel>
              <TabChronology chronologies={wakafAsset?.data?.kronologi} />
            </TabPanel>
            <TabPanel>
              <TabAudit audits={wakafAsset?.data?.audits} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Box>
  );
};

export default WakafManagement;
