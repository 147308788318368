import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { createEditDunOption } from "services/setting/dataSetting/dunSettingService";
import { IDunSetting } from "entities/setting/dataSettings";

export function useCreateDunSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createDunSetting, isLoading: isCreating } = useMutation({
    mutationFn: ({dunSetting, id}: {dunSetting: IDunSetting, id?: string | null}) => createEditDunOption(dunSetting, id),
    onSuccess: () => {
      toast({
        title: "New dun successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["dun"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createDunSetting };
}