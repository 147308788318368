import { Icon } from "@chakra-ui/react";
import { MdSettings } from "react-icons/md";

import AuditTrail from "views/setting/auditTrail";
import AccessControl from "views/setting/accessControl";

import User from "views/setting/user";
import CreateUser from "views/setting/user/Create";
import EditUser from "views/setting/user/Edit";
import ShowUser from "views/setting/user/Show";

import DataSetting from "views/setting/dataSetting";
import CreateStateSetting from "views/setting/dataSetting/components/StateSetting/Create";
import EditStateSetting from "views/setting/dataSetting/components/StateSetting/Edit";
import CreateDistrictSetting from "views/setting/dataSetting/components/DistrictSetting/Create";
import EditDistrictSetting from "views/setting/dataSetting/components/DistrictSetting/Edit";
import CreateTownSetting from "views/setting/dataSetting/components/TownSetting/Create";
import EditTownSetting from "views/setting/dataSetting/components/TownSetting/Edit";
import CreateSectionSetting from "views/setting/dataSetting/components/SectionSetting/Create";
import EditSectionSetting from "views/setting/dataSetting/components/SectionSetting/Edit";
import CreateKariahSetting from "views/setting/dataSetting/components/KariahSetting/Create";
import EditKariahSetting from "views/setting/dataSetting/components/KariahSetting/Edit";
import CreateParliamentSetting from "views/setting/dataSetting/components/ParliamentSetting/Create";
import EditParliamentSetting from "views/setting/dataSetting/components/ParliamentSetting/Edit";
import CreateDunSetting from "views/setting/dataSetting/components/DunSetting/Create";
import EditDunSetting from "views/setting/dataSetting/components/DunSetting/Edit";
import CreateDepartmentSetting from "views/setting/dataSetting/components/DepartmentSetting/Create";
import EditDepartmentSetting from "views/setting/dataSetting/components/DepartmentSetting/Edit";
import CreateUnitSetting from "views/setting/dataSetting/components/UnitSetting/Create";
import EditUnitSetting from "views/setting/dataSetting/components/UnitSetting/Edit";
import CreateWaqafTypeSetting from "views/setting/dataSetting/components/WaqafTypeSetting/Create";
import EditWaqafTypeSetting from "views/setting/dataSetting/components/WaqafTypeSetting/Edit";
import CreateJenisPermohonanWakafSetting from "views/setting/dataSetting/components/JenisPermohonanWakafSetting/Create";
import EditJenisPermohonanWakafSetting from "views/setting/dataSetting/components/JenisPermohonanWakafSetting/Edit";
import CreateBuildingNoSetting from "views/setting/dataSetting/components/BuildingNoSetting/Create";
import EditBuildingNoSetting from "views/setting/dataSetting/components/BuildingNoSetting/Edit";
import CreateTujuanSighahSetting from "views/setting/dataSetting/components/TujuanSighahSetting/Create";
import EditTujuanSighahSetting from "views/setting/dataSetting/components/TujuanSighahSetting/Edit";
import CreatePerolehWakafSetting from "views/setting/dataSetting/components/PerolehWakafSetting/Create";
import EditPerolehWakafSetting from "views/setting/dataSetting/components/PerolehWakafSetting/Edit";
import CreateContractorSetting from "views/setting/dataSetting/components/ContractorSetting/Create";
import EditContractorSetting from "views/setting/dataSetting/components/ContractorSetting/Edit";
import CreateOrganizationSetting from "views/setting/dataSetting/components/OrganizationSetting/Create";
import EditOrganizationSetting from "views/setting/dataSetting/components/OrganizationSetting/Edit";
import CreateReportSetting from "views/setting/dataSetting/components/ReportSetting/Create";
import EditReportSetting from "views/setting/dataSetting/components/ReportSetting/Edit";
import CreateOperasiTanahSemasaSetting from "views/setting/dataSetting/components/OperasiTanahSemasaSetting/Create";
import EditOperasiTanahSemasaSetting from "views/setting/dataSetting/components/OperasiTanahSemasaSetting/Edit";
import CreateKegunaanTanahSemasaSetting from "views/setting/dataSetting/components/KegunaanTanahSemasaSetting/Create";
import EditKegunaanTanahSemasaSetting from "views/setting/dataSetting/components/KegunaanTanahSemasaSetting/Edit";
import CreateNoFailMainppSetting from "views/setting/dataSetting/components/NoFailMainppSetting/Create";
import EditNoFailMainppSetting from "views/setting/dataSetting/components/NoFailMainppSetting/Edit";
import CreateNoFailPtgSetting from "views/setting/dataSetting/components/NoFailPtgSetting/Create";
import EditNoFailPtgSetting from "views/setting/dataSetting/components/NoFailPtgSetting/Edit";
import CreateAttachmentSetting from "views/setting/dataSetting/components/AttachmentSetting/Create";
import EditAttachmentSetting from "views/setting/dataSetting/components/AttachmentSetting/Edit";
import CreateKategoriGunatanahSetting from "views/setting/dataSetting/components/KategoriGunatanahSetting/Create";
import EditKategoriGunatanahSetting from "views/setting/dataSetting/components/KategoriGunatanahSetting/Edit";
import CreateGunatanahSemasaSetting from "views/setting/dataSetting/components/GunatanahSemasaSetting/Create";
import EditGunatanahSemasaSetting from "views/setting/dataSetting/components/GunatanahSemasaSetting/Edit";
import CreateKaedahPerolehanSetting from "views/setting/dataSetting/components/KaedahPerolehanSetting/Create";
import EditKaedahPerolehanSetting from "views/setting/dataSetting/components/KaedahPerolehanSetting/Edit";
import CreateTujuanPengambilanSetting from "views/setting/dataSetting/components/TujuanPengambilanSetting/Create";
import EditTujuanPengambilanSetting from "views/setting/dataSetting/components/TujuanPengambilanSetting/Edit";
import CreateJenisAsetBaitulmalSetting from "views/setting/dataSetting/components/JenisAsetBaitulmalSetting/Create";
import EditJenisAsetBaitulmalSetting from "views/setting/dataSetting/components/JenisAsetBaitulmalSetting/Edit";
import CreateSumberWakafSetting from "views/setting/dataSetting/components/SumberWakafSetting/Create";
import EditSumberWakafSetting from "views/setting/dataSetting/components/SumberWakafSetting/Edit";

import StateSetting from "views/setting/dataSetting/components/StateSetting/index";
import DistrictSetting from "views/setting/dataSetting/components/DistrictSetting";
import TownSetting from "views/setting/dataSetting/components/TownSetting";
import SectionSetting from "views/setting/dataSetting/components/SectionSetting";
import KariahSetting from "views/setting/dataSetting/components/KariahSetting";
import ParliamentSetting from "views/setting/dataSetting/components/ParliamentSetting";
import DunSetting from "views/setting/dataSetting/components/DunSetting";
import DepartmentSetting from "views/setting/dataSetting/components/DepartmentSetting";
import UnitSetting from "views/setting/dataSetting/components/UnitSetting";
import WaqafTypeSetting from "views/setting/dataSetting/components/WaqafTypeSetting";
import JenisPermohonanWakafSetting from "views/setting/dataSetting/components/JenisPermohonanWakafSetting";
import BuildingNoSetting from "views/setting/dataSetting/components/BuildingNoSetting";
import TujuanSighahSetting from "views/setting/dataSetting/components/TujuanSighahSetting";
import PerolehWakafSetting from "views/setting/dataSetting/components/PerolehWakafSetting";
import ContractorSetting from "views/setting/dataSetting/components/ContractorSetting";
import OrganizationSetting from "views/setting/dataSetting/components/OrganizationSetting";
import ReportSetting from "views/setting/dataSetting/components/ReportSetting";
import OperasiTanahSemasaSetting from "views/setting/dataSetting/components/OperasiTanahSemasaSetting";
import KegunaanTanahSemasaSetting from "views/setting/dataSetting/components/KegunaanTanahSemasaSetting";
import NoFailMainppSetting from "views/setting/dataSetting/components/NoFailMainppSetting";
import NoFailPtgSetting from "views/setting/dataSetting/components/NoFailPtgSetting";
import AttachmentSetting from "views/setting/dataSetting/components/AttachmentSetting";
import KategoriGunatanahSetting from "views/setting/dataSetting/components/KategoriGunatanahSetting";
import GunatanahSemasaSetting from "views/setting/dataSetting/components/GunatanahSemasaSetting";
import KaedahPerolehanSetting from "views/setting/dataSetting/components/KaedahPerolehanSetting";
import TujuanPengambilanSetting from "views/setting/dataSetting/components/TujuanPengambilanSetting";
import JenisAsetBaitulmalSetting from "views/setting/dataSetting/components/JenisAsetBaitulmalSetting";
import SumberWakafSetting from "views/setting/dataSetting/components/SumberWakafSetting";

const settingsRoutes = [
  {
    name: "Konfigurasi Sistem",
    path: "/settings",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Pengurusan Pengguna",
        layout: "/admin",
        path: "/settings/users",
        component: User,
      },
      {
        name: "Kawalan Akses",
        layout: "/admin",
        path: "/settings/access",
        component: AccessControl,
      },
      {
        name: "Tetapan Data",
        layout: "/admin",
        path: "/settings/data",
        component: DataSetting,
      },
      {
        name: "Audit Trail Sistem",
        layout: "/admin",
        path: "/settings/logs",
        component: AuditTrail,
      },
    ],
  },

  {
    name: "Daftar Pengguna",
    layout: "/admin",
    path: "/settings/users/baru",
    component: CreateUser,
    secondary: true,
  },
  {
    name: "Kemaskini Pengguna",
    layout: "/admin",
    path: "/settings/users/kemaskini/:userId",
    component: EditUser,
    secondary: true,
  },
  {
    name: "Maklumat Pengguna",
    layout: "/admin",
    path: "/settings/users/show/:userId",
    component: ShowUser,
    secondary: true,
  },
  {
    name: "Maklumat Negeri",
    layout: "/admin",
    path: "/settings/data/negeri",
    component: StateSetting,
    secondary: true,
  },
  {
    name: "Tambah Negeri",
    layout: "/admin",
    path: "/settings/data/negeri/baru",
    component: CreateStateSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Negeri",
    layout: "/admin",
    path: "/settings/data/negeri/kemaskini/:stateId",
    component: EditStateSetting,
    secondary: true,
  },
  {
    name: "Maklumat Daerah",
    layout: "/admin",
    path: "/settings/data/daerah",
    component: DistrictSetting,
    secondary: true,
  },
  {
    name: "Tambah Daerah",
    layout: "/admin",
    path: "/settings/data/daerah/baru",
    component: CreateDistrictSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Daerah",
    layout: "/admin",
    path: "/settings/data/daerah/kemaskini/:districtId",
    component: EditDistrictSetting,
    secondary: true,
  },
  {
    name: "Maklumat Mukim",
    layout: "/admin",
    path: "/settings/data/mukim",
    component: TownSetting,
    secondary: true,
  },
  {
    name: "Tambah Mukim",
    layout: "/admin",
    path: "/settings/data/mukim/baru",
    component: CreateTownSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Mukim",
    layout: "/admin",
    path: "/settings/data/mukim/kemaskini/:townId",
    component: EditTownSetting,
    secondary: true,
  },
  {
    name: "Maklumat Seksyen",
    layout: "/admin",
    path: "/settings/data/seksyen",
    component: SectionSetting,
    secondary: true,
  },
  {
    name: "Tambah Seksyen",
    layout: "/admin",
    path: "/settings/data/seksyen/baru",
    component: CreateSectionSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Seksyen",
    layout: "/admin",
    path: "/settings/data/seksyen/kemaskini/:sectionId",
    component: EditSectionSetting,
    secondary: true,
  },
  {
    name: "Maklumat Kariah",
    layout: "/admin",
    path: "/settings/data/kariah",
    component: KariahSetting,
    secondary: true,
  },
  {
    name: "Tambah Kariah",
    layout: "/admin",
    path: "/settings/data/kariah/baru",
    component: CreateKariahSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Kariah",
    layout: "/admin",
    path: "/settings/data/kariah/kemaskini/:kariahId",
    component: EditKariahSetting,
    secondary: true,
  },
  {
    name: "Maklumat Parlimen",
    layout: "/admin",
    path: "/settings/data/parliament",
    component: ParliamentSetting,
    secondary: true,
  },
  {
    name: "Tambah Parlimen",
    layout: "/admin",
    path: "/settings/data/parliament/baru",
    component: CreateParliamentSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Parlimen",
    layout: "/admin",
    path: "/settings/data/parliament/kemaskini/:parliamentId",
    component: EditParliamentSetting,
    secondary: true,
  },
  {
    name: "Maklumat Dun",
    layout: "/admin",
    path: "/settings/data/dun",
    component: DunSetting,
    secondary: true,
  },
  {
    name: "Tambah Dun",
    layout: "/admin",
    path: "/settings/data/dun/baru",
    component: CreateDunSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Dun",
    layout: "/admin",
    path: "/settings/data/dun/kemaskini/:dunId",
    component: EditDunSetting,
    secondary: true,
  },
  {
    name: "Maklumat Jabatan",
    layout: "/admin",
    path: "/settings/data/jabatan",
    component: DepartmentSetting,
    secondary: true,
  },
  {
    name: "Tambah Jabatan",
    layout: "/admin",
    path: "/settings/data/department/baru",
    component: CreateDepartmentSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Jabatan",
    layout: "/admin",
    path: "/settings/data/department/kemaskini/:departmentId",
    component: EditDepartmentSetting,
    secondary: true,
  },
  {
    name: "Maklumat Unit",
    layout: "/admin",
    path: "/settings/data/unit",
    component: UnitSetting,
    secondary: true,
  },
  {
    name: "Tambah Unit",
    layout: "/admin",
    path: "/settings/data/unit/baru",
    component: CreateUnitSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Unit",
    layout: "/admin",
    path: "/settings/data/unit/kemaskini/:unitId",
    component: EditUnitSetting,
    secondary: true,
  },
  {
    name: "Maklumat Jenis Wakaf",
    layout: "/admin",
    path: "/settings/data/jeniswakaf",
    component: WaqafTypeSetting,
    secondary: true,
  },
  {
    name: "Tambah Jenis Wakaf",
    layout: "/admin",
    path: "/settings/data/jeniswakaf/baru",
    component: CreateWaqafTypeSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Jenis Wakaf",
    layout: "/admin",
    path: "/settings/data/jeniswakaf/kemaskini/:waqafTypeId",
    component: EditWaqafTypeSetting,
    secondary: true,
  },
  {
    name: "Maklumat Jenis Permohonan Wakaf",
    layout: "/admin",
    path: "/settings/data/jenispermohonanwakaf",
    component: JenisPermohonanWakafSetting,
    secondary: true,
  },
  {
    name: "Tambah Jenis Permohonan Wakaf",
    layout: "/admin",
    path: "/settings/data/jenispermohonanwakaf/baru",
    component: CreateJenisPermohonanWakafSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Jenis Permohonan Wakaf",
    layout: "/admin",
    path: "/settings/data/jenispermohonanwakaf/kemaskini/:jenisPermohonanWakafId",
    component: EditJenisPermohonanWakafSetting,
    secondary: true,
  },
  {
    name: "Maklumat No. Bangunan",
    layout: "/admin",
    path: "/settings/data/nobangunan",
    component: BuildingNoSetting,
    secondary: true,
  },
  {
    name: "Tambah No. Bangunan",
    layout: "/admin",
    path: "/settings/data/nobangunan/baru",
    component: CreateBuildingNoSetting,
    secondary: true,
  },
  {
    name: "Kemaskini No. Bangunan",
    layout: "/admin",
    path: "/settings/data/nobangunan/kemaskini/:buildingNoId",
    component: EditBuildingNoSetting,
    secondary: true,
  },
  {
    name: "Maklumat Tujuan Sighah",
    layout: "/admin",
    path: "/settings/data/tujuansighah",
    component: TujuanSighahSetting,
    secondary: true,
  },
  {
    name: "Tambah Tujuan Sighah",
    layout: "/admin",
    path: "/settings/data/tujuansighah/baru",
    component: CreateTujuanSighahSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Tujuan Sighah",
    layout: "/admin",
    path: "/settings/data/tujuansighah/kemaskini/:tujuanSighahId",
    component: EditTujuanSighahSetting,
    secondary: true,
  },
  {
    name: "Maklumat Kaedah Peroleh Tanah Wakaf",
    layout: "/admin",
    path: "/settings/data/perolehwakaf",
    component: PerolehWakafSetting,
    secondary: true,
  },
  {
    name: "Tambah Kaedah Peroleh Tanah Wakaf",
    layout: "/admin",
    path: "/settings/data/perolehwakaf/baru",
    component: CreatePerolehWakafSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Kaedah Peroleh Tanah Wakaf",
    layout: "/admin",
    path: "/settings/data/perolehwakaf/kemaskini/:perolehWakafId",
    component: EditPerolehWakafSetting,
    secondary: true,
  },
  {
    name: "Maklumat Kontraktor",
    layout: "/admin",
    path: "/settings/data/kontraktor",
    component: ContractorSetting,
    secondary: true,
  },
  {
    name: "Tambah Kontraktor",
    layout: "/admin",
    path: "/settings/data/kontraktor/baru",
    component: CreateContractorSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Kontraktor",
    layout: "/admin",
    path: "/settings/data/kontraktor/kemaskini/:contractorId",
    component: EditContractorSetting,
    secondary: true,
  },
  {
    name: "Maklumat Pertubuhan",
    layout: "/admin",
    path: "/settings/data/pertubuhan",
    component: OrganizationSetting,
    secondary: true,
  },
  {
    name: "Tambah Pertubuhan",
    layout: "/admin",
    path: "/settings/data/pertubuhan/baru",
    component: CreateOrganizationSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Pertubuhan",
    layout: "/admin",
    path: "/settings/data/pertubuhan/kemaskini/:organizationId",
    component: EditOrganizationSetting,
    secondary: true,
  },
  {
    name: "Maklumat Jenis Aduan",
    layout: "/admin",
    path: "/settings/data/jenisaduan",
    component: ReportSetting,
    secondary: true,
  },
  {
    name: "Tambah Jenis Aduan",
    layout: "/admin",
    path: "/settings/data/jenisaduan/baru",
    component: CreateReportSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Jenis Aduan",
    layout: "/admin",
    path: "/settings/data/jenisaduan/kemaskini/:reportId",
    component: EditReportSetting,
    secondary: true,
  },
  {
    name: "Maklumat Operasi Tanah Wakaf Semasa",
    layout: "/admin",
    path: "/settings/data/operasitanahsemasa",
    component: OperasiTanahSemasaSetting,
    secondary: true,
  },
  {
    name: "Tambah Operasi Tanah Wakaf Semasa",
    layout: "/admin",
    path: "/settings/data/operasitanahsemasa/baru",
    component: CreateOperasiTanahSemasaSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Operasi Tanah Wakaf Semasa",
    layout: "/admin",
    path: "/settings/data/operasitanahsemasa/kemaskini/:operasiTanahSemasaId",
    component: EditOperasiTanahSemasaSetting,
    secondary: true,
  },
  {
    name: "Maklumat Kegunaan Tanah Wakaf Semasa",
    layout: "/admin",
    path: "/settings/data/kegunaantanahsemasa",
    component: KegunaanTanahSemasaSetting,
    secondary: true,
  },
  {
    name: "Tambah Kegunaan Tanah Wakaf Semasa",
    layout: "/admin",
    path: "/settings/data/kegunaantanahsemasa/baru",
    component: CreateKegunaanTanahSemasaSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Kegunaan Tanah Wakaf Semasa",
    layout: "/admin",
    path: "/settings/data/kegunaantanahsemasa/kemaskini/:kegunaanTanahSemasaId",
    component: EditKegunaanTanahSemasaSetting,
    secondary: true,
  },
  {
    name: "Maklumat No Fail Mainpp",
    layout: "/admin",
    path: "/settings/data/nofailmainpp",
    component: NoFailMainppSetting,
    secondary: true,
  },
  {
    name: "Tambah No Fail Mainpp",
    layout: "/admin",
    path: "/settings/data/nofailmainpp/baru",
    component: CreateNoFailMainppSetting,
    secondary: true,
  },
  {
    name: "Kemaskini No Fail Mainpp",
    layout: "/admin",
    path: "/settings/data/nofailmainpp/kemaskini/:noFailMainppId",
    component: EditNoFailMainppSetting,
    secondary: true,
  },
  {
    name: "Maklumat No Fail Ptg",
    layout: "/admin",
    path: "/settings/data/nofailptg",
    component: NoFailPtgSetting,
    secondary: true,
  },
  {
    name: "Tambah No Fail Ptg",
    layout: "/admin",
    path: "/settings/data/nofailptg/baru",
    component: CreateNoFailPtgSetting,
    secondary: true,
  },
  {
    name: "Kemaskini No Fail Ptg",
    layout: "/admin",
    path: "/settings/data/nofailptg/kemaskini/:noFailPtgId",
    component: EditNoFailPtgSetting,
    secondary: true,
  },
  {
    name: "Maklumat Lampiran",
    layout: "/admin",
    path: "/settings/data/lampiran",
    component: AttachmentSetting,
    secondary: true,
  },
  {
    name: "Tambah Lampiran",
    layout: "/admin",
    path: "/settings/data/lampiran/baru",
    component: CreateAttachmentSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Lampiran",
    layout: "/admin",
    path: "/settings/data/lampiran/kemaskini/:attachmentId",
    component: EditAttachmentSetting,
    secondary: true,
  },
  {
    name: "Maklumat Kategori Gunatanah",
    layout: "/admin",
    path: "/settings/data/kategorigunatanah",
    component: KategoriGunatanahSetting,
    secondary: true,
  },
  {
    name: "Tambah Kategori Gunatanah",
    layout: "/admin",
    path: "/settings/data/kategorigunatanah/baru",
    component: CreateKategoriGunatanahSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Kategori Gunatanah",
    layout: "/admin",
    path: "/settings/data/kategorigunatanah/kemaskini/:kategoriGunatanahId",
    component: EditKategoriGunatanahSetting,
    secondary: true,
  },
  {
    name: "Maklumat Gunatanah Semasa",
    layout: "/admin",
    path: "/settings/data/gunatanahsemasa",
    component: GunatanahSemasaSetting,
    secondary: true,
  },
  {
    name: "Tambah Gunatanah Semasa",
    layout: "/admin",
    path: "/settings/data/gunatanahsemasa/baru",
    component: CreateGunatanahSemasaSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Gunatanah Semasa",
    layout: "/admin",
    path: "/settings/data/gunatanahsemasa/kemaskini/:gunatanahSemasaId",
    component: EditGunatanahSemasaSetting,
    secondary: true,
  },
  {
    name: "Maklumat Kaedah Perolehan",
    layout: "/admin",
    path: "/settings/data/kaedahperolehan",
    component: KaedahPerolehanSetting,
    secondary: true,
  },
  {
    name: "Tambah Kaedah Perolehan",
    layout: "/admin",
    path: "/settings/data/kaedahperolehan/baru",
    component: CreateKaedahPerolehanSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Kaedah Perolehan",
    layout: "/admin",
    path: "/settings/data/kaedahperolehan/kemaskini/:kaedahPerolehanId",
    component: EditKaedahPerolehanSetting,
    secondary: true,
  },
  {
    name: "Maklumat Tujuan Pengambilan",
    layout: "/admin",
    path: "/settings/data/tujuanpengambilan",
    component: TujuanPengambilanSetting,
    secondary: true,
  },
  {
    name: "Tambah Tujuan Pengambilan",
    layout: "/admin",
    path: "/settings/data/tujuanpengambilan/baru",
    component: CreateTujuanPengambilanSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Tujuan Pengambilan",
    layout: "/admin",
    path: "/settings/data/tujuanpengambilan/kemaskini/:tujuanPengambilanId",
    component: EditTujuanPengambilanSetting,
    secondary: true,
  },
  {
    name: "Maklumat Jenis Aset Baitulmal",
    layout: "/admin",
    path: "/settings/data/jenisasetbaitulmal",
    component: JenisAsetBaitulmalSetting,
    secondary: true,
  },
  {
    name: "Tambah Jenis Aset Baitulmal",
    layout: "/admin",
    path: "/settings/data/jenisasetbaitulmal/baru",
    component: CreateJenisAsetBaitulmalSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Jenis Aset Baitulmal",
    layout: "/admin",
    path: "/settings/data/jenisasetbaitulmal/kemaskini/:jenisAsetBaitulmalId",
    component: EditJenisAsetBaitulmalSetting,
    secondary: true,
  },
  {
    name: "Maklumat Sumber Wakaf",
    layout: "/admin",
    path: "/settings/data/sumberwakaf",
    component: SumberWakafSetting,
    secondary: true,
  },
  {
    name: "Tambah Sumber Wakaf",
    layout: "/admin",
    path: "/settings/data/sumberwakaf/baru",
    component: CreateSumberWakafSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Sumber Wakaf",
    layout: "/admin",
    path: "/settings/data/sumberwakaf/kemaskini/:sumberWakafId",
    component: EditSumberWakafSetting,
    secondary: true,
  },
];

export default settingsRoutes;