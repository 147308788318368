import React, { useState } from "react";
import { Box, Flex, Input, Link } from "@chakra-ui/react";

interface LinkGroupFieldProps {
  label: string;
  labelWidth?: string | number;
  disabled?: boolean;
  readOnly?: boolean;
}

const LinkGroupField = React.forwardRef<any, LinkGroupFieldProps>(
  ({ label, labelWidth, disabled = false, readOnly = false }, ref) => {
    return (
      <Flex height={"55px"}>
        <Box
          height={"100%"}
          width={labelWidth}
          background={"secondaryGray.400"}
          paddingX={"12px"}
          alignContent={"center"}
          textAlign={"center"}
          borderLeftRadius={"8px"}
          fontSize={"sm"}
        >
          <Link fontWeight="500" color={"brand.500"} href="#">
            {label}
          </Link>
        </Box>

        {disabled && <Flex w="3px" bg="white" />}

        <Input
          borderLeftRadius={"2px"}
          borderRightRadius={"8px"}
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          type="text"
          fontWeight="500"
          size="md"
          height={"100%"}
          disabled={disabled}
          readOnly={readOnly}
          _disabled={{
            background: "secondaryGray.400",
          }}
          ref={ref}
        />
      </Flex>
    );
  }
);

export default LinkGroupField;
