import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getWakafAssets } from "services/admin/wakafManagement/wakafAssetService";
import { PAGE_SIZE } from "utils/constants";

export function useWakafAssets(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let currentSearchParams = {};

  for (const [key, value] of searchParams) {
    currentSearchParams = {
      [key]: value,
    };
  }

  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("limit")
    ? PAGE_SIZE
    : Number(searchParams.get("limit"));

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["wakafAssets", currentPage, currentLimit, currentSearchParams],
      queryFn: () =>
        getWakafAssets({
          ...params,
          ...currentSearchParams,
          page: currentPage,
          limit: currentLimit,
        }),
    });
  }, [currentPage, currentLimit, params, queryClient]);

  const {
    isLoading,
    data: wakafAssets,
    error,
  } = useQuery({
    queryKey: ["wakafAssets", currentPage, currentLimit, currentSearchParams],
    queryFn: () =>
      getWakafAssets({
        ...params,
        ...currentSearchParams,
        page: currentPage,
        limit: currentLimit,
      }),
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: "always",
  });

  return { isLoading, error, wakafAssets };
}
