import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditWakafHistory } from "services/admin/wakafManagement/wakafHistoryService";
import { IWakafHistoryFormValues } from "entities/admin/wakafManagement";

export function useCreateWakafHistory() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createWakafHistory, isLoading: isCreating } = useMutation({
    mutationFn: ({newWakafHistory, id}: {newWakafHistory: IWakafHistoryFormValues, id: number | null}) => createEditWakafHistory(newWakafHistory, id),
    onSuccess: () => {
      toast({
        title: "New wakaf history successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["wakafHistory"] });
      history.push("/admin/wakaf/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createWakafHistory };
}

