import React from "react";
import { Flex, VStack, Box, Icon, Text, Circle } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  FaClipboardCheck,
  FaClock,
  FaLandmark,
  FaMoneyBillAlt,
} from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";

import { formatDate } from "utils/helpers";

interface TabChronologyProps {
  chronologies: {
    id: string;
    title: string;
    tab: string;
    id_aset_wakaf: string;
    created_at: string;
    updated_at: string;
  }[];
}

const TabChronology = ({ chronologies = [] }: TabChronologyProps) => {
  // const steps = [
  //   {
  //     icon: MdOutlineEdit,
  //     label: "Pemilik Memberitahu Akad Wakaf",
  //     date: "27 Jul 2022",
  //     status: "success",
  //   },
  //   {
  //     icon: FaClipboardCheck,
  //     label: "Daftar Aset Wakaf Di Mainpp",
  //     date: "27 Jul 2022",
  //     status: "failed",
  //   },
  //   {
  //     icon: FaTruck,
  //     label: "Aduan",
  //     date: "29 Jul 2022",
  //     status: "pending",
  //   },
  // ];

  const renderIcon = (tab: string) => {
    switch (tab) {
      case "mohon_wakaf":
        return <Icon as={MdOutlineEdit} boxSize="24px" />;
      case "pecah_sempadan_wakaf":
        return <Icon as={FaClipboardCheck} boxSize="24px" />;
      case "aset_wakaf_pembelian":
        return <Icon as={FaLandmark} boxSize="24px" />;
      case "penilaian_istibdal":
        return <Icon as={FaMoneyBillAlt} boxSize="24px" />;
      default:
        return <Icon as={FaClock} boxSize="24px" />;
    }
  };

  return (
    <Flex
      px="25px"
      mt="1rem"
      mb="1.5rem"
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <VStack width={"60%"} spacing={14} align="stretch" position="relative">
        {chronologies.map((chronology, index) => (
          <Flex key={index} align="flex-start" position="relative">
            <Circle
              size="50px"
              bg="blue.500"
              color="white"
              position="relative"
              zIndex={"2"}
            >
              {renderIcon(chronology.tab)}
            </Circle>
            {index < chronologies.length - 1 && (
              <Box
                position="absolute"
                left="25px"
                top="50%"
                height="calc(100% + 32px)"
                width="2px"
                bg="gray.200"
                zIndex={"1"}
                style={{ borderLeft: "2px dashed gray" }}
              />
            )}
            <Box flex="1" pl={6}>
              <Text fontWeight="bold">{chronology.title}</Text>
              <Text fontSize="sm">
                {formatDate(chronology.updated_at, "dd MMMM yyyy")}
              </Text>
            </Box>

            <Circle size="30px" bg="green.500" color="white" ml={2}>
              <CheckIcon />
            </Circle>
          </Flex>
        ))}
      </VStack>

      {chronologies.length < 1 && (
        <Text fontWeight="bold" fontSize={{ sm: "14px" }}>
          Tiada maklumat buat masa ini
        </Text>
      )}
    </Flex>
  );
};

export default TabChronology;
