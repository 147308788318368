import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getBaitulmalRegistration } from "services/admin/baitulmalRegistration/baitulmalRegistrationService";
import { useToast } from "@chakra-ui/react";

export function useBaitulmalRegistration() {
  const { applicationId } = useParams<{ applicationId: string }>();
  const toast = useToast();

  const {
    isLoading,
    isInitialLoading,
    data: baitulmalRegistration,
    error,
  } = useQuery({
    queryKey: ["baitulmalRegistration", applicationId],
    queryFn: () => getBaitulmalRegistration(applicationId),
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: "always",
    enabled: !!applicationId,
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isLoading, isInitialLoading, error, baitulmalRegistration };
}

