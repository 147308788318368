import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
} from "@chakra-ui/react";

export function SidebarLinks(props: { routes: RoutesType[] }) {
  const { routes } = props;

  let location = useLocation();

  let activeColor = "gray.700";
  let inactiveColor = "secondaryGray.600";
  let activeIcon = "brand.500";
  let textColor = "secondaryGray.500";
  let brandColor = "brand.500";

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route: RoutesType, index: number) => {
      if (route.layout !== "/auth" && !route.secondary) {
        if (route.collapse) {
          return (
            <Accordion allowToggle key={index}>
              <AccordionItem border={"none"} maxW={"100%"} key={`${index}-item`}>
                <AccordionButton
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  bg={"transparent"}
                  display={"flex"}
                  outline={"none"}
                  _focus={{
                    outline: "none",
                  }}
                  _active={{
                    outline: "none",
                  }}
                >
                  <Flex w="100%" alignItems={"center"}>
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me="18px"
                    >
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel>
                  <List spacing={2}>
                    {route.items.map((routeItem, routeItemKey) => (
                      <ListItem pl="38px" key={`${index}-${routeItemKey}`}>
                        <NavLink
                          key={routeItemKey}
                          to={routeItem.layout + routeItem.path}
                        >
                          <Text
                            fontSize={"sm"}
                            color={
                              activeRoute(routeItem.path.toLowerCase())
                                ? activeColor
                                : inactiveColor
                            }
                            fontWeight={
                              activeRoute(routeItem.path.toLowerCase())
                                ? "bold"
                                : "normal"
                            }
                          >
                            {routeItem.name}
                          </Text>
                        </NavLink>
                      </ListItem>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        }

        return (
          <NavLink key={index} to={route.layout + route.path}>
            <Box>
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                }
                py="5px"
                ps="15px"
              >
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeIcon
                        : textColor
                    }
                    me="18px"
                  >
                    {route.icon}
                  </Box>
                  <Text
                    me="auto"
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : textColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                  >
                    {route.name}
                  </Text>
                </Flex>
                <Box
                  h="36px"
                  w="4px"
                  bg={
                    activeRoute(route.path.toLowerCase())
                      ? brandColor
                      : "transparent"
                  }
                  borderRadius="5px"
                />
              </HStack>
            </Box>
          </NavLink>
        );
      }

      return null; 
    });
  };

  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
