import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { useHistory, useLocation } from "react-router-dom";

export default function SortingWakafRegistration() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const [searchValues, setSearchValues] = useState({
    namaPewakaf: "",
    noKadPengenalanBaru: "",
    daerah: "",
    seksyen: "",
    bandarPekanMukim: "",
    noLot: "",
    noPT: "",
    jenisPermohonan: "",
    statusPermohonan: "",
  });

  const {
    jenisPermohonanWakafOps,
    statusPermohonanOps,
    daerahOps,
    bandarPekanMukimOps,
    seksyenOps,
  } = useOptions();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const search = () => {
    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      namaPewakaf: "",
      noKadPengenalanBaru: "",
      daerah: "",
      seksyen: "",
      bandarPekanMukim: "",
      noLot: "",
      noPT: "",
      jenisPermohonan: "",
      statusPermohonan: "",
    });
    document.querySelectorAll('select').forEach(select => select.value = "");

    history.push({ search: searchParams.toString() });
  };

  const getSearchParams = (key: string) => {
    return searchParams.get(key);
  };

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <SelectField
          label={"Jenis Permohonan"}
          value={
            searchValues.jenisPermohonan ?? getSearchParams("jenisPermohonan")
          }
          options={jenisPermohonanWakafOps}
          onChange={(e: any) => handleOnChange(e, "jenisPermohonan")}
        />

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Pewakaf
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="Ramli Saad"
            fontWeight="500"
            size="md"
            value={searchValues.namaPewakaf ?? getSearchParams("namaPewakaf")}
            onChange={(e: any) => handleOnTextChange(e, "namaPewakaf")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No Kad Pengenalan Baru
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.noKadPengenalanBaru ?? getSearchParams("noKadPengenalanBaru")}
            onChange={(e: any) => handleOnTextChange(e, "noKadPengenalanBaru")}
          />
        </FormControl>

        <SelectField
          label={"Daerah"}
          value={searchValues.daerah ?? getSearchParams("daerah")}
          options={daerahOps}
          onChange={(e: any) => handleOnChange(e, "daerah")}
        />

        <SelectField
          label={"Bandar/Mukim"}
          value={searchValues.bandarPekanMukim ?? getSearchParams("bandarPekanMukim")}
          options={bandarPekanMukimOps}
          onChange={(e: any) => handleOnChange(e, "bandarPekanMukim")}
        />

        <SelectField
          label={"Seksyen"}
          value={searchValues.seksyen ?? getSearchParams("seksyen")}
          options={seksyenOps}
          onChange={(e: any) => handleOnChange(e, "seksyen")}
        />

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No Lot
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="100897"
            fontWeight="500"
            size="md"
            value={searchValues.noLot ?? getSearchParams("noLot")}
            onChange={(e: any) => handleOnTextChange(e, "noLot")}
          />
        </FormControl>

        <SelectField
          label={"Status Permohonan"}
          value={searchValues.statusPermohonan ?? getSearchParams("statusPermohonan")}
          options={statusPermohonanOps}
          onChange={(e: any) => handleOnChange(e, "statusPermohonan")}
        />

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No PT
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.noPT ?? getSearchParams("noPT")}
            onChange={(e: any) => handleOnTextChange(e, "noPT")}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>
      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
