import React from 'react'
import CreateAcquisition from './Create'
import EditAcquisition from './Edit'
import ShowAcquisition from './Show'

import { IBaitulmalAsset } from 'entities/admin/baitulmalManagement'

interface TabAcquisitionProps {
  action: "create" | "edit" | "show";
  baitulmalAsset?: IBaitulmalAsset;
}

const TabAcquisition = ({ action, baitulmalAsset }: TabAcquisitionProps) => {
  const renderComponent = () => {
    switch (action) {
      case "create":
        return <CreateAcquisition />;
      case "edit":
        return <EditAcquisition baitulmalAsset={baitulmalAsset} />;
      case "show":
        return <ShowAcquisition baitulmalAsset={baitulmalAsset} />;
      default:
        return <CreateAcquisition />;
    }
  };
  
  return <div>{renderComponent()}</div>;
};

export default TabAcquisition;

