import React from "react";
import FormBasicInformation from "./FormBasicInformation";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useWakafAsset } from "hooks/admin/wakafManagement/wakafAsset/useWakafAsset";

export default function ShowBasicInformation() {
  const { wakafAsset, isLoading } = useWakafAsset();

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <FormBasicInformation
      action="show"
      initialData={{
        ...wakafAsset?.data,
        senarai_lampiran: wakafAsset?.data?.senarai_lampiran.filter(
          (file: any) => file.tab_name === "aset_wakaf_index"
        ),
      }}
    />
  );
}
