import { NavLink } from "react-router-dom";
import {
  Text,
  Flex,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";

interface SettingListProps {
  title: string;
  settings: any;
}

const SettingList = ({ title, settings }: SettingListProps) => {
  return (
    <Card>
      <Text variant="secondary" fontSize={"xl"} mb="4px" lineHeight="100%">
        {title}
      </Text>

      <Flex paddingTop={2} paddingBottom={4}>
        <HSeparator />
      </Flex>

      <UnorderedList>
        {settings.map((setting: any, index: number) => (
          <ListItem key={index} pb="4px">
            <NavLink to={setting.link}>
              <Text fontSize={"md"} variant="link">
                {setting.text}
              </Text>
            </NavLink>
          </ListItem>
        ))}
      </UnorderedList>
    </Card>
  );
};

export default SettingList;
