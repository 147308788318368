import { useQuery } from "@tanstack/react-query";
import { getSumberWakafNameOptions } from "services/setting/dataSetting/sumberWakafService";

export default function useSumberWakafOptionSettings() {
  const { isLoading, data: sumberWakafNameOptions } = useQuery({
    queryKey: ["sumberWakafNameOptions"],
    queryFn: () => getSumberWakafNameOptions(),
  });

  const sumberWakafNameOps = sumberWakafNameOptions?.data || [];

  // OUTPUT
  return {
    sumberWakafNameOps,
  };
}
