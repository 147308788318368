import React from "react";
import FormNoFailMainppSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useNoFailMainppSetting } from "hooks/setting/dataSetting/noFailMainppSetting/useNoFailMainppSetting";

export default function EditNoFailMainppSetting() {
  const { isLoading, noFailMainpp, error } = useNoFailMainppSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormNoFailMainppSetting action="edit" initialData={noFailMainpp?.data} />;
}
