import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, Grid, Button, FormControl, FormLabel } from "@chakra-ui/react";

import Section from "components/section/Section";
import NumberGroupField from "components/fields/NumberGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";

import { IWakafIstibdalAssessmentFormValues } from "entities/admin/wakafManagement";
import { useCreateWakafIstibdalAssessment } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalAssessment/useCreateWakafIstibdalAssessment";
import { useEditWakafIstibdalAssessment } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalAssessment/useEditWakafIstibdalAssessment";

import { formatDate } from "utils/helpers";

interface FormCancellingLandProps {
  action: "create" | "edit" | "show";
  wakafAssetId?: number;
  acquisitionType?: string;
  initialData?: IWakafIstibdalAssessmentFormValues;
}

const schema = yup
  .object({
    pemberi_pindahmilik: yup.string().required(),
    harga_jualan: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    balasan_rm: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
  })
  .required();

const FormCancellingLand: React.FC<FormCancellingLandProps> = ({
  action,
  wakafAssetId,
  acquisitionType,
  initialData,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    initialData?.senarai_lampiran || [
      {
        id_senarai_lampiran: "",
        tab_name: "aset_wakaf_istibdal_pengambilan",
        file_name: "",
        file_description: "",
      },
    ]
  );

  const history = useHistory();
  const { createWakafIstibdalAssessment, isCreating } = useCreateWakafIstibdalAssessment();
  const { editWakafIstibdalAssessment, isEditing } = useEditWakafIstibdalAssessment();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IWakafIstibdalAssessmentFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      tarikh_borang_14A: formatDate(initialData?.tarikh_borang_14A),
    },
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createWakafIstibdalAssessment({
        newWakafIstibdalAssessment: {
          ...data,
          id_aset_wakaf: wakafAssetId,
          jenis_pengambilan: acquisitionType,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files
        }
      });
    } else if (action === "edit") {
      editWakafIstibdalAssessment({
        newWakafIstibdalAssessment: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                file_name: file?.file_name,
                file_description: file?.file_description,
                tab_name: "aset_wakaf_istibdal_pengambilan",
              };
            })
          ),
          lampiran: files,
        },
        id: initialData?.id,
      });
    }
  });

  useEffect(() => {
    reset();
  }, [acquisitionType])

  return (
    <Flex mt="30px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={action === "create" ? "reset" : "button"}
            onClick={() => {
              if (action !== "create") {
                history.push("/admin/wakaf/aset");
              }
            }}
          >
            Batal
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            disabled={isCreating || isEditing}
          >
            {action === "edit"
              ? "Kemaskini Harus Penjualan"
              : "Daftar Harus Penjualan"}
          </Button>
        </Flex>

        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <TextField
            id="pemberi_pindahmilik"
            label="Pemberi Pindahmilik"
            isInvalid={!!errors.pemberi_pindahmilik}
            errorMessage={errors.pemberi_pindahmilik?.message}
            readOnly={action === "show"}
            {...register("pemberi_pindahmilik")}
          />

          <TextField
            id="penerima_pindahmilik"
            label="Penerima Pindahmilik"
            isInvalid={!!errors.penerima_pindahmilik}
            errorMessage={errors.penerima_pindahmilik?.message}
            readOnly={action === "show"}
            {...register("penerima_pindahmilik")}
          />

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Harga Jualan (RM)
            </FormLabel>

            <NumberGroupField
              id="harga_jualan"
              label="RM"
              step="any"
              isInvalid={!!errors.harga_jualan}
              errorMessage={errors.harga_jualan?.message}
              readOnly={action === "show"}
              {...register("harga_jualan")}
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Balasan (RM)
            </FormLabel>

            <NumberGroupField
              id="balasan_rm"
              label="RM"
              step="any"
              isInvalid={!!errors.balasan_rm}
              errorMessage={errors.balasan_rm?.message}
              readOnly={action === "show"}
              {...register("balasan_rm")}
            />
          </FormControl>

          <TextField
            id="tarikh_borang_14A"
            label="Tarikh Borang 14A"
            type="date"
            isInvalid={!!errors.tarikh_borang_14A}
            errorMessage={errors.tarikh_borang_14A?.message}
            readOnly={action === "show"}
            {...register("tarikh_borang_14A")}
          />
        </Grid>

        <Section size="md" title="Lampiran Penjualan Tanah">
          <FileUploadField
            files={files}
            setFiles={setFiles}
            fileDescriptions={fileDescriptions}
            setFileDescriptions={setFileDescriptions}
            tabName="aset_wakaf_istibdal_pengambilan"
            readOnly={action === "show"}
          />
        </Section>
      </form>
    </Flex>
  );
};

export default FormCancellingLand;
