import React from "react";
import FormTownSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useTownSetting } from "hooks/setting/dataSetting/townSetting/useTownSetting";

export default function EditDistrictSetting() {
  const { isLoading, town, error } = useTownSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormTownSetting action="edit" initialData={town?.data} />;
}
