import axios from "utils/axios";
import { IKariahSetting } from "entities/setting/dataSettings";

export async function getKariahOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/kariah",
    params,
  });
  return data;
}

export async function getKariahOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/kariah/${id}`,
  });
  return data;
}


export async function createEditKariahOption(
  values: IKariahSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/kariah/${id}` : "/general/kariah";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteKariahOption(
  id: string | number | null
) {
  const apiUrl = `/general/kariah/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

