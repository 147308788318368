import React from "react";
import { Box, Text, Link, List, Icon, Badge } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import Resource from "components/resource/Resource";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { useBaitulmalAssets } from "hooks/admin/baitulmalManagement/baitulmalAsset/useBaitulmalAssets";
import { useDeleteBaitulmalAsset } from "hooks/admin/baitulmalManagement/baitulmalAsset/useDeleteBaitulmalAsset";

import { IBaitulmalAsset } from "entities/admin/baitulmalManagement";
import { fetchLabel } from "utils/helpers";
import Sorting from "./components/Sorting";

const columnHelper = createColumnHelper<IBaitulmalAsset>();

const getStatusDescription = (status: number) => {
  switch (status) {
    case -1:
      return <Badge colorScheme="red">Lupus</Badge>;
    case 1:
      return <Badge colorScheme="green">Jual</Badge>;
    default:
      return <Badge colorScheme="yellow">Tidak Jual</Badge>;
  }
};

export default function WakafAsset() {
  const history = useHistory();
  const { jenisAsetOps, daerahOps, bandarPekanMukimOps, dunOps } = useOptions();
  const { isLoading, error, baitulmalAssets } = useBaitulmalAssets();
  const { removeBaitulmalAsset, isDeleting } = useDeleteBaitulmalAsset();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID Aset Baitulmal</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id_jenis_aset", {
      header: () => <Text variant="tableHeading">Jenis Aset</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), jenisAsetOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("no_hak_milik", {
      header: () => <Text variant="tableHeading">No Hakmilik</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_lot", {
      header: () => <Text variant="tableHeading">No Lot</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id_daerah", {
      header: () => <Text variant="tableHeading">Daerah</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), daerahOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_bandar_pekan_mukim", {
      header: () => <Text variant="tableHeading">Bandar/Mukim</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), bandarPekanMukimOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_dun", {
      header: () => <Text variant="tableHeading">Dun</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), dunOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("status", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info) => getStatusDescription(info.getValue()),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <NavLink
            to={`/admin/baitulmal/aset/kemaskini/${info.row.original.id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink to={`/admin/baitulmal/aset/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>

          <Link
            as={"button"}
            onClick={() => {
              removeBaitulmalAsset(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={baitulmalAssets}
        title={"Senarai Aset Baitulmal"}
        createTitle="Daftar Aset Baitulmal"
        onCreate={() => history.push("/admin/baitulmal/aset/baru")}
        allowDownload
        onClickDownload={() => console.log}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
