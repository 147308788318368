import { Icon } from "@chakra-ui/react";
import { MdFileCopy } from "react-icons/md";

import BaitulmalRegistration from "views/admin/baitulmalRegistration";
import CreateBaitulmalRegistration from "views/admin/baitulmalRegistration/Create";
import EditBaitulmalRegistration from "views/admin/baitulmalRegistration/Edit";
import ShowBaitulmalRegistration from "views/admin/baitulmalRegistration/Show";
import BaitulmalAssets from "views/admin/baitulmalAsset";
import BaitulmalDevelopment from "views/admin/baitulmalDevelopment";
import BaitulmalManagement from "views/admin/baitulmalManagement";

const baitulmalRoutes = [
  {
    name: "Baitulmal",
    path: "/baitulmal",
    icon: <Icon as={MdFileCopy} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Cadangan Beli Aset",
        layout: "/admin",
        path: "/baitulmal/permohonan",
        component: BaitulmalRegistration,
      },
      {
        name: "Aset Baitulmal",
        layout: "/admin",
        path: "/baitulmal/aset",
        component: BaitulmalAssets,
      },
      {
        name: "Pembangunan",
        layout: "/admin",
        path: "/baitulmal/pembangunan",
        component: BaitulmalDevelopment,
      },
    ],
  },
  {
    name: "Daftar Cadangan Beli Baru",
    layout: "/admin",
    path: "/baitulmal/permohonan/baru",
    component: CreateBaitulmalRegistration,
    secondary: true,
  },
  {
    name: "Kemaskini Cadangan Beli",
    layout: "/admin",
    path: "/baitulmal/permohonan/kemaskini/:applicationId",
    component: EditBaitulmalRegistration,
    secondary: true,
  },
  {
    name: "Maklumat Cadangan Beli",
    layout: "/admin",
    path: "/baitulmal/permohonan/show/:applicationId",
    component: ShowBaitulmalRegistration,
    secondary: true,
  },
  {
    name: "Daftar Aset Baitulmal Baru",
    layout: "/admin",
    path: "/baitulmal/aset/baru/:applicationId?",
    component: BaitulmalManagement,
    secondary: true,
  },
  {
    name: "Maklumat Aset Baitulmal",
    layout: "/admin",
    path: "/baitulmal/aset/show/:assetId",
    component: BaitulmalManagement,
    secondary: true,
  },
  {
    name: "Maklumat Aset Baitulmal",
    layout: "/admin",
    path: "/baitulmal/aset/kemaskini/:assetId",
    component: BaitulmalManagement,
    secondary: true,
  },
];

export default baitulmalRoutes;