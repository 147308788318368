import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { useHistory, useLocation } from "react-router-dom";

export default function SortingWakafRegistration() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const [searchValues, setSearchValues] = useState({
    name: "",
    email: "",
    jabatan: "",
    unit: "",
    role: "",
    status: "",
  });

  const { jabatanOps, unitOps, roleOps, userStatusOps } = useOptions();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const search = () => {
    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      name: "",
      email: "",
      jabatan: "",
      unit: "",
      role: "",
      status: "",
    });
    document.querySelectorAll('select').forEach(select => select.value = "");

    history.push({ search: searchParams.toString() });
  };

  const getSearchParams = (key: string) => {
    return searchParams.get(key);
  };

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <SelectField
          label={"Status"}
          value={searchValues.status ?? getSearchParams("status")}
          options={userStatusOps}
          onChange={(e: any) => handleOnChange(e, "status")}
        />

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="Ramli Saad"
            fontWeight="500"
            size="md"
            value={searchValues.name ?? getSearchParams("name")}
            onChange={(e: any) => handleOnTextChange(e, "name")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Emel
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.email ?? getSearchParams("email")}
            onChange={(e: any) => handleOnTextChange(e, "email")}
          />
        </FormControl>

        <SelectField
          label={"Jabatan"}
          value={searchValues.jabatan ?? getSearchParams("jabatan")}
          options={jabatanOps}
          onChange={(e: any) => handleOnChange(e, "jabatan")}
        />

        <SelectField
          label={"unit"}
          value={searchValues.unit ?? getSearchParams("unit")}
          options={unitOps}
          onChange={(e: any) => handleOnChange(e, "unit")}
        />

        <SelectField
          label={"Peranan"}
          value={
            searchValues.role ?? getSearchParams("role")
          }
          options={roleOps}
          onChange={(e: any) => handleOnChange(e, "role")}
        />
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>
      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
