import React from "react";
import FormKategoriGunatanahSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useKategoriGunatanahSetting } from "hooks/setting/dataSetting/kategoriGunatanahSetting/useKategoriGunatanahSetting";

export default function EditKategoriGunatanahSetting() {
  const { isLoading, kategoriGunatanah, error } = useKategoriGunatanahSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormKategoriGunatanahSetting action="edit" initialData={kategoriGunatanah?.data} />;
}
