import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

import FormAssessment from "./FormAssessment";
import TableAssessment from "./TableAssessment";

interface TabAssessmentProps {
  baitulmalAssetId?: number;
}

const TabAssessment: React.FC<TabAssessmentProps> = ({ baitulmalAssetId }) => {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const [baitulmalAssessmentId, setBaitulmalAssessmentId] = useState<number | null>(null);
  const [showFormAssessment, setShowFormAssessment] = useState(false);

  const toggleFormAssessment = (action: "create" | "edit" | "show", baitulmalAssessmentId?: number) => {
    setAction(action);
    setBaitulmalAssessmentId(baitulmalAssessmentId);
    setShowFormAssessment(true);
  };

  return (
    <Flex direction="column">
      <TableAssessment
        baitulmalAssetId={baitulmalAssetId}
        toggleFormAssessment={toggleFormAssessment}
      />

      {showFormAssessment && (
        <FormAssessment
          action={action}
          baitulmalAssetId={baitulmalAssetId}
          baitulmalAssessmentId={baitulmalAssessmentId}
        />
      )}
    </Flex>
  );
};

export default TabAssessment;
