import axios from "utils/axios";
import { IKaedahPerolehanSetting } from "entities/setting/dataSettings";

export async function getKaedahPerolehanOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/kaedahPerolehan",
    params,
  });
  return data;
}

export async function getKaedahPerolehanOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/kaedahPerolehan/${id}`,
  });
  return data;
}

export async function createEditKaedahPerolehanOption(
  values: IKaedahPerolehanSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/kaedahPerolehan/${id}` : "/general/kaedahPerolehan";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteKaedahPerolehanOption(
  id: string | number | null
) {
  const apiUrl = `/general/kaedahPerolehan/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

