import { useQuery } from "@tanstack/react-query";
import { getCurrentUser } from "services/setting/userService";

export function useCurrentUser() {
  const {
    isLoading,
    data: currentUser,
    error,
  } = useQuery({
    queryKey: ["currentUser"],
    queryFn: getCurrentUser,
  });

  return { isLoading, error, currentUser };
}