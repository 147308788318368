import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { login as loginApi } from "services/auth/authService";
import { ISignInFormValues } from "entities/auth/auth";
import { useAuth } from "hooks/auth/useAuth";

export function useLogin() {
  const history = useHistory();
  const auth = useAuth();
  const toast = useToast();

  const { mutate: login, isLoading } = useMutation({
    mutationFn: ({ email, password }: ISignInFormValues) =>
      loginApi({ email, password }),
    onSuccess: ({ data }, variables) => {
      const token = data.token;
      const { rememberMe } = variables;
      
      auth.storeAuthToken(token, rememberMe);

      history.push("/admin");
    },
    onError: (err: any) => {
      console.log("ERROR", err);

      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { login, isLoading };
}
