import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

import FormTax from "./FormTax";
import TableTax from "./TableTax";

interface TabTaxProps {
  baitulmalAssetId?: number;
}

export default function TabTax({ baitulmalAssetId }: TabTaxProps) {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const [baitulmalTaxId, setBaitulmalTaxId] = useState<number | null>(null);
  const [showFormTax, setShowFormTax] = useState(false);

  const toggleFormTax = (action: "create" | "edit" | "show", baitulmalTaxId?: number) => {
    setAction(action);
    setBaitulmalTaxId(baitulmalTaxId);
    setShowFormTax(true);
  };

  const toggleCloseFormTax = () => {
    setShowFormTax(false);
  };

  return (
    <Flex direction="column">
      <TableTax
        baitulmalAssetId={baitulmalAssetId}
        toggleFormTax={toggleFormTax}
      />

      {showFormTax && (
        <FormTax
          action={action}
          baitulmalAssetId={baitulmalAssetId}
          baitulmalTaxId={baitulmalTaxId}
          toggleCloseFormTax={toggleCloseFormTax}
        />
      )}
    </Flex>
  );
}
