import React, { useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";

import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import Resource from "components/resource/Resource";
import Sorting from "./components/Sorting";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { useBaitulmalRegistrations } from "hooks/admin/baitulmalRegistration/useBaitulmalRegistrations";
import { useDeleteBaitulmalRegistration } from "hooks/admin/baitulmalRegistration/useDeleteBaitulmalRegistration";
import { IBaitulmalRegistration } from "entities/admin/baitulmalRegistration";

import { fetchLabel, formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<IBaitulmalRegistration>();

export default function BaitulmalRegistration() {
  const history = useHistory();
  const { bandarPekanMukimOps, daerahOps, cadanganBeliAsetStatusOps } =
    useOptions();
  const { baitulmalRegistrations, isLoading, error } =
    useBaitulmalRegistrations();
  const { removeBaitulmalRegistration, isDeleting } =
    useDeleteBaitulmalRegistration();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID Cadangan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_pencadang", {
      header: () => <Text variant="tableHeading">Nama Pencadang</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id_no_fail_mainpp", {
      header: () => <Text variant="tableHeading">No Fail</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_lulus", {
      header: () => <Text variant="tableHeading">Tarikh Lulus</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("no_lot", {
      id: "no_lot",
      header: () => <Text variant="tableHeading">No Lot</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id_bandar_pekan_mukim", {
      header: () => <Text variant="tableHeading">Bandar/Mukim</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), bandarPekanMukimOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_daerah", {
      header: () => <Text variant="tableHeading">Daerah</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), daerahOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("status", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), cadanganBeliAsetStatusOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_aset_baitulmal", {
      header: () => <Text variant="tableHeading">ID Asset Baitulmal</Text>,
      cell: (info) => {
        if (
          info.row.original.status === 1 &&
          info.row.original?.id_aset_baitulmal
        ) {
          return (
            <NavLink to={`/admin/baitulmal/aset/show/${info.getValue()}`}>
              <Text variant="link" fontSize="sm" fontWeight="500">
                {info.getValue()}
              </Text>
            </NavLink>
          );
        }
        return <Text></Text>;
      },
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <NavLink
            to={`/admin/baitulmal/permohonan/kemaskini/${info.row.original.id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink
            to={`/admin/baitulmal/permohonan/show/${info.row.original.id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>

          <Link
            as={"button"}
            onClick={() => {
              removeBaitulmalRegistration(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={baitulmalRegistrations}
        title={"Senarai Cadangan Beli Aset"}
        createTitle="Daftar Cadangan"
        onCreate={() => history.push("/admin/baitulmal/permohonan/baru")}
        allowDownload
        onClickDownload={() => console.log}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
