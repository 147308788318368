import React, { useState } from "react";
import { Box } from "@chakra-ui/react";

import Card from "components/card/Card";
import SwitchField from "components/fields/SwitchField";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { handleOnChangeInput } from "utils/helpers";

export default function AccessControl() {
  const [role, setRole] = useState("");

  const { roleOps } = useOptions();

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Section title="Peranan" size="xl">
          <Box width="200px">
            <SelectField
              value={role}
              options={roleOps.filter((r: any) => r.label !== "Pengguna Awam")}
              onChange={(e: any) => handleOnChangeInput(e, setRole)}
              mb={"30px"}
            />
          </Box>
        </Section>

        <Section title="Kebenaran" size="xl">
          <SwitchField
            isChecked={true}
            reversed={true}
            fontSize="sm"
            mb="20px"
            id="1"
            label="Daftar Pengguna"
            onChange={console.log}
          />

          <SwitchField
            reversed={true}
            fontSize="sm"
            mb="20px"
            id="2"
            label="Padam Pengguna"
            onChange={console.log}
          />

          <SwitchField
            reversed={true}
            fontSize="sm"
            mb="20px"
            id="3"
            label="Kemaskini Pengguna"
            onChange={console.log}
          />

          <SwitchField
            reversed={true}
            fontSize="sm"
            mb="20px"
            id="3"
            label="Lihat Maklumat Pengguna"
            onChange={console.log}
          />

          <SwitchField
            isChecked={true}
            reversed={true}
            fontSize="sm"
            mb="20px"
            id="1"
            label="Tambah Tetapan Data"
            onChange={console.log}
          />

          <SwitchField
            reversed={true}
            fontSize="sm"
            mb="20px"
            id="2"
            label="Padam Tetapan Data"
            onChange={console.log}
          />

          <SwitchField
            reversed={true}
            fontSize="sm"
            mb="20px"
            id="3"
            label="Kemaskini Tetapan Data"
            onChange={console.log}
          />
        </Section>
      </Card>
    </Box>
  );
}
