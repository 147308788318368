import React from "react";
import FormWaqafTypeSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useWaqafTypeSetting } from "hooks/setting/dataSetting/waqafTypeSetting/unitSetting/useWaqafTypeSetting";

export default function EditWaqafTypeSetting() {
  const { isLoading, waqafType, error } = useWaqafTypeSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormWaqafTypeSetting action="edit" initialData={waqafType?.data} />;
}
