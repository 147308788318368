import axios from "utils/axios";
import { IWakafIstibdalAssessmentFormValues } from "entities/admin/wakafManagement";
import { IWakafIstibdalInfoFormValues } from "entities/admin/wakafManagement";

// Wakaf Istibdal
export async function getWakafIstibdalAssessments(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/pengambilan-penilaian-istibdal",
    params
  });
  return data;
}

export async function getWakafIstibdalAssessment(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/pengambilan-penilaian-istibdal/${id}`,
  });
  return data;
}

export async function createEditWakafIstibdalAssessment(
  values: IWakafIstibdalAssessmentFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/pengambilan-penilaian-istibdal/${id}` : "/pengambilan-penilaian-istibdal";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

// Wakaf Istibdal Info
export async function getWakafIstibdalInfos(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: `/maklumat-istibdal`,
    params
  });
  return data;
}

export async function getWakafIstibdalInfo(id: number) {
  const { data } = await axios.call({
    method: "get",
    url: `/maklumat-istibdal/${id}`,
  });
  return data;
}

export async function createEditWakafIstibdalInfo(
  values: IWakafIstibdalInfoFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/maklumat-istibdal/${id}` : "/maklumat-istibdal";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}


export async function deleteWakafIstibdalInfo(
  id: string | number | null
) {
  const apiUrl = `/maklumat-istibdal/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}