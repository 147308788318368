import React, { useState, useCallback } from "react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import ResourceLimit from "./limit/ResourceLimit";
import ResourcePagination from "./pagination/ResourcePagination";
import ResourceSorting from "./sorting/ResourceSorting";
// import ResourceSorting from "components/resource/sorting/ResourceSorting";

interface ResourceProps {
  columns: any[];
  data: any;
  title: string;
  createTitle?: string;
  onCreate?: () => void;
  allowDownload?: boolean;
  onClickDownload?: () => void;
  resourceSortingChildren?: React.ReactNode;
  allowPagination?: boolean;
}

const HeaderGroup = ({
  headerGroup,
  borderColor,
}: {
  headerGroup: any;
  borderColor: string;
}) => (
  <Tr key={headerGroup.id}>
    {headerGroup.headers.map((header: any) => (
      <Th
        key={header.id}
        colSpan={header.colSpan}
        pe="10px"
        borderColor={borderColor}
        cursor="pointer"
        onClick={header.column.getToggleSortingHandler()}
      >
        <Flex
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
          {{
            asc: "",
            desc: "",
          }[header.column.getIsSorted() as string] ?? null}
        </Flex>
      </Th>
    ))}
  </Tr>
);

const Row = ({ row }: { row: any }) => (
  <Tr key={row.id}>
    {row.getVisibleCells().map((cell: any) => (
      <Td
        key={cell.id}
        fontSize={{ sm: "14px" }}
        minW={{ sm: "150px", md: "200px", lg: "auto" }}
        borderColor="transparent"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Td>
    ))}
  </Tr>
);

export default function Resource(props: ResourceProps) {
  const {
    columns,
    data: tableData,
    title,
    createTitle,
    onCreate,
    allowPagination,
    // allowDownload,
    // onClickDownload,
    resourceSortingChildren,
  } = props;

  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: tableData?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="1.5rem" justifyContent="space-between" align="end">
        <Text
          color={"secondaryGray.900"}
          fontSize="22px"
          mb="4px"
          fontWeight="700"
          lineHeight="100%"
        >
          {title}
        </Text>
        {onCreate && (
          <Button variant="action" fontSize="sm" onClick={onCreate}>
            {createTitle}
          </Button>
        )}
      </Flex>

      {resourceSortingChildren && (
        <ResourceSorting>{resourceSortingChildren}</ResourceSorting>
      )}

      <Box>
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <HeaderGroup
                key={headerGroup.id}
                headerGroup={headerGroup}
                borderColor={"gray.200"}
              />
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 11)
              .map((row) => (
                <Row key={row.id} row={row} />
              ))}
          </Tbody>
        </Table>

        {(!tableData?.data || tableData.data.length === 0) && (
          <Flex justifyContent={"center"} mb="24px">
            <Text fontWeight="bold" fontSize={{ sm: "14px" }}>
              Tiada maklumat buat masa ini
            </Text>
          </Flex>
        )}
      </Box>

      {allowPagination && (
        <Flex
          align={{ md: "center", sm: "start" }}
          direction={{ md: "row", sm: "column" }}
          justify={"space-between"}
          px={{ md: "22px" }}
          w={"100"}
          pb={"40px"}
        >
          <ResourceLimit
            totalPage={tableData?.meta?.total}
            pageSize={tableData?.meta?.per_page}
          />

          <ResourcePagination
            totalPage={tableData?.meta?.total}
            pageSize={tableData?.meta?.per_page}
            // currentPage={tableData?.meta.current_page}
          />
        </Flex>
      )}
    </Card>
  );
}
