import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import { HSeparator } from "components/separator/Separator";

interface SectionProps {
  title: string;
  size?: "sm" | "md" | "xl";
  children: React.ReactNode | React.ReactNode[];
}

export default function Section(props: SectionProps) {
  const { title, size = "xl", children } = props;

  return (
    <Flex direction="column">
      <Text variant="secondary" fontSize={size} mb="4px" lineHeight="100%">
        {title}
      </Text>

      <Flex paddingTop={2} paddingBottom={8}>
        <HSeparator />
      </Flex>

      {children}
    </Flex>
  );
}
