import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { createEditBuildingNoOption } from "services/setting/dataSetting/buildingNoSettingService";
import { IBuildingNoSetting } from "entities/setting/dataSettings";

export function useCreateBuildingNoSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createBuildingNoSetting, isLoading: isCreating } = useMutation({
    mutationFn: ({buildingNoSetting, id}: {buildingNoSetting: IBuildingNoSetting, id?: string | null}) => createEditBuildingNoOption(buildingNoSetting, id),
    onSuccess: () => {
      toast({
        title: "New building no successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["buildingNo"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createBuildingNoSetting };
}