import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditBaitulmalMaintenance } from "services/admin/baitulmalManagement/baitulmalMaintenanceService";
import { IBaitulmalMaintenanceFormValues } from "entities/admin/baitulmalManagement";

export function useEditBaitulmalMaintenance() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: editBaitulmalMaintenance, isLoading: isEditing } = useMutation({
    mutationFn: ({
      newBaitulmalMaintenance,
      id,
    }: {
      newBaitulmalMaintenance: IBaitulmalMaintenanceFormValues;
      id: string | number | null;
    }) => createEditBaitulmalMaintenance(newBaitulmalMaintenance, id),
    onSuccess: () => {
      toast({
        title: "Baitulmal maintenance successfully updated",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["baitulmalMaintenance", "baitulmalMaintenances"] });
      history.push("/admin/baitulmal/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isEditing, editBaitulmalMaintenance };
}
