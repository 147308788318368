import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Grid,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";

import Section from "components/section/Section";
import NumberGroupField from "components/fields/NumberGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";

import { IWakafIstibdalAssessmentFormValues } from "entities/admin/wakafManagement";
import { useEditWakafIstibdalAssessment } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalAssessment/useEditWakafIstibdalAssessment";

import {
  formatDate,
  handleAreaConversion
} from "utils/helpers";

interface FormValuationProps {
  action: "create" | "edit" | "show";
  wakafAssetId?: number;
  wakafIstibdalAssessmentId?: number;
  initialData?: IWakafIstibdalAssessmentFormValues;
}

const schema = yup
  .object({
    orang_kepentingan: yup.string().required(),
    bhg_pampasan_rm: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
  })
  .required();

const FormValuation: React.FC<FormValuationProps> = ({
  action,
  wakafAssetId,
  initialData,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    initialData?.senarai_lampiran || [
      {
        id_senarai_lampiran: "",
        tab_name: "aset_wakaf_istibdal_penilaian",
        file_name: "",
        file_description: "",
      },
    ]
  );

  const history = useHistory();
  const { editWakafIstibdalAssessment, isEditing } =
    useEditWakafIstibdalAssessment();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IWakafIstibdalAssessmentFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      tarikh_borang_h: formatDate(initialData?.tarikh_borang_h),
    },
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "edit") {
      editWakafIstibdalAssessment({
        newWakafIstibdalAssessment: {
          ...data,
          id_aset_wakaf: wakafAssetId,
          no_fail_istibdal: initialData?.no_fail_istibdal,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                file_name: file?.file_name,
                file_description: file?.file_description,
                tab_name: 'aset_wakaf_istibdal_penilaian'
              };
            })
          ),
          lampiran: files,
        },
        id: initialData?.id,
      });
    }
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "luas_dikehendaki_m2") {
        const area = handleAreaConversion("m2", value.luas_dikehendaki_m2);

        setValue("luas_dikehendaki_hektar", area.hektar, {
          shouldValidate: false,
        });
        setValue("luas_dikehendaki_ektar", area.ekar, {
          shouldValidate: false,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <Flex mt="10px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={action === "create" ? "reset" : "button"}
            onClick={() => {
              if (action !== "create") {
                history.push("/admin/wakaf/aset");
              }
            }}
          >
            Batal
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            disabled={isEditing}
          >
            {action === "edit" ? "Kemaskini Maklumat" : "Simpan Maklumat"}
          </Button>
        </Flex>

        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <GridItem
            display={"grid"}
            colSpan={2}
            gap={{ sm: "6px", md: "18px" }}
          >
            <FormControl>
              <FormLabel variant="main" mb="8px">
                Luas Dikehendaki
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="luas_dikehendaki_hektar"
                  label="Hektar"
                  step="any"
                  isInvalid={!!errors.luas_dikehendaki_hektar}
                  errorMessage={errors.luas_dikehendaki_hektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_dikehendaki_hektar")}
                />

                <NumberGroupField
                  id="luas_dikehendaki_ektar"
                  label="Ekar"
                  step="any"
                  isInvalid={!!errors.luas_dikehendaki_ektar}
                  errorMessage={errors.luas_dikehendaki_ektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_dikehendaki_ektar")}
                />

                <NumberGroupField
                  id="luas_dikehendaki_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  step="any"
                  isInvalid={!!errors.luas_dikehendaki_m2}
                  errorMessage={errors.luas_dikehendaki_m2?.message}
                  readOnly={action === "show"}
                  {...register("luas_dikehendaki_m2")}
                />
              </SimpleGrid>
            </FormControl>
          </GridItem>

          <TextField
            id="orang_kepentingan"
            label="Orang-Orang Yang Berkepentingan"
            isInvalid={!!errors.orang_kepentingan}
            errorMessage={errors.orang_kepentingan?.message}
            readOnly={action === "show"}
            {...register("orang_kepentingan")}
          />

          <TextField
            id="jenis_kepentingan"
            label="Jenis Kepentingan"
            isInvalid={!!errors.jenis_kepentingan}
            errorMessage={errors.jenis_kepentingan?.message}
            readOnly={action === "show"}
            {...register("jenis_kepentingan")}
          />

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Harga Nilaian
            </FormLabel>

            <NumberGroupField
              id="bhg_pampasan_rm"
              label="RM"
              step="any"
              isInvalid={!!errors.bhg_pampasan_rm}
              errorMessage={errors.bhg_pampasan_rm?.message}
              readOnly={action === "show"}
              {...register("bhg_pampasan_rm")}
            />
          </FormControl>

          <TextField
            id="tarikh_borang_h"
            label="Tarikh Nilaian Tanah"
            type="date"
            isInvalid={!!errors.tarikh_borang_h}
            errorMessage={errors.tarikh_borang_h?.message}
            readOnly={action === "show"}
            {...register("tarikh_borang_h")}
          />
        </Grid>

        <Section size="md" title="Lampiran Penilaian Istibdal">
          <FileUploadField
            files={files}
            setFiles={setFiles}
            fileDescriptions={fileDescriptions}
            setFileDescriptions={setFileDescriptions}
            tabName="aset_wakaf_istibdal_penilaian"
            readOnly={action === "show"}
          />
        </Section>
      </form>
    </Flex>
  );
};

export default FormValuation;
