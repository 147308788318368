import { Box, Flex, Stack } from '@chakra-ui/react';

import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';

interface SidebarContentProps {
	routes: RoutesType[]
}

function SidebarContent(props: SidebarContentProps) {
	const { routes } = props;
	
	return (
		<Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
			<Brand />
			<Stack direction='column' mt='8px' mb='auto' height='100%' overflowY='auto'>
				<Box ps='20px' pe={{ lg: '16px', '2xl': '16px' }} height='100%'>
					<Links routes={routes} />
				</Box>
			</Stack>
		</Flex>
	);
}

export default SidebarContent;
