import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditWakafSubdivision } from "services/admin/wakafManagement/wakafDevelopmentService";
import { IWakafSubdivisionFormValues } from "entities/admin/wakafManagement";

export function useCreateWakafSubdivision() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createWakafSubdivision, isLoading: isCreating } = useMutation({
    mutationFn: ({newWakafSubdivision, id}: {newWakafSubdivision: IWakafSubdivisionFormValues, id: number | null}) => createEditWakafSubdivision(newWakafSubdivision, id),
    onSuccess: () => {
      toast({
        title: "New wakaf subdivision successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["wakafSubdivision"] });
      history.push("/admin/wakaf/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createWakafSubdivision };
}

