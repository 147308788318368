import React, { useState } from "react";
import {
  Box,
  Flex,
  Input,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";

interface NumberGroupFieldProps {
  id?: string;
  label: string;
  value?: string | number;
  labelWidth?: string | number;
  disabled?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  step?: string;
}

const NumberGroupField = React.forwardRef<
  HTMLInputElement,
  NumberGroupFieldProps
>(
  (
    {
      label,
      value,
      labelWidth,
      isInvalid = false,
      errorMessage = "",
      disabled = false,
      readOnly = false,
      step = "1",
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl isInvalid={isInvalid}>
        <Flex height={"55px"}>
          <Box
            height={"100%"}
            width={labelWidth}
            background={"secondaryGray.400"}
            paddingX={"12px"}
            alignContent={"center"}
            textAlign={"center"}
            borderLeftRadius={"8px"}
            fontSize={"sm"}
          >
            {label}
          </Box>

          {disabled && <Flex w="3px" bg="white" />}

          <Input
            borderLeftRadius={"2px"}
            borderRightRadius={"8px"}
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="number"
            fontWeight="500"
            size="md"
            height={"100%"}
            value={value}
            disabled={disabled}
            _disabled={{
              background: "secondaryGray.400",
            }}
            step={step}
            readOnly={readOnly}
            ref={ref}
            {...rest}
          />
        </Flex>

        {isInvalid && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </FormControl>
    );
  }
);

export default NumberGroupField;
