import axios from "utils/axios";
import { IWaqafTypeSetting } from "entities/setting/dataSettings";

export async function getWaqafTypeOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jenisWakaf",
    params
  });
  return data;
}

export async function getWaqafTypeOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/jenisWakaf/${id}`,
  });
  return data;
}


export async function createEditWaqafTypeOption(
  values: IWaqafTypeSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/jenisWakaf/${id}` : "/general/jenisWakaf";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteWaqafTypeOption(
  id: string | number | null
) {
  const apiUrl = `/general/jenisWakaf/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

