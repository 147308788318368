import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";

interface PublicRouteProps {
  path: string;
  component: React.ComponentType<any>;
  restricted?: boolean; // If true, authenticated users will be redirected
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  path,
  component: Component,
  restricted = false,
  ...rest
}) => {
  const auth = useAuth();
  const token = auth.getAuthToken();

  return (
    <Route
      {...rest}
      path={path}
      render={(props) =>
        token && restricted ? <Redirect to="/admin" /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;