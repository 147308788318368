import React from "react";
import FormDepartmentSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useDepartmentSetting } from "hooks/setting/dataSetting/departmentSetting/useDepartmentSetting";

export default function EditDepartmentSetting() {
  const { isLoading, department, error } = useDepartmentSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormDepartmentSetting action="edit" initialData={department?.data} />;
}
