import React, { useState, useEffect } from "react";
import { useHistory, useParams, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";
import CreatableSelectField from "components/fields/CreatableSelectField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";
import MapLocator from "components/mapLocator/MapLocator";
import NumberGroupField from "components/fields/NumberGroupField";
import LinkGroupField from "components/fields/LinkGroupField";
import Section from "components/section/Section";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/dataSetting/useOptions";
import useDataFile from "hooks/setting/dataSetting/useDataFile";

import { IWakafAssetFormValues } from "entities/admin/wakafManagement";
import { useCreateWakafAsset } from "hooks/admin/wakafManagement/wakafAsset/useCreateWakafAsset";
import { useEditWakafAsset } from "hooks/admin/wakafManagement/wakafAsset/useEditWakafAsset";
import { useWakafRegistration } from "hooks/admin/wakafRegistration/useWakafRegistration";
import { useTownSettings } from "hooks/setting/dataSetting/townSetting/useTownSettings";
import { useSectionSettings } from "hooks/setting/dataSetting/sectionSetting/useSectionSettings";
import { useDunSettings } from "hooks/setting/dataSetting/dunSetting/useDunSettings";

import { formatDate, handleAreaConversion } from "utils/helpers";

interface FormBasicInformationProps {
  action: "create" | "edit" | "show";
  initialData?: IWakafAssetFormValues;
}

const schema = yup
  .object({
    nama_pewakaf: yup.string().required(),
    no_fon: yup.string().required(),
    no_kp_baru: yup.string().required(),
    alamat_pewakaf: yup.string().required(),
    id_jenis_aset: yup.string().required(),
    jenis_wakaf: yup
      .array()
      .of(yup.string().required("Jenis wakaf is required"))
      .required("Field is required"),
    tujuan_sighah: yup.lazy((value) =>
      Array.isArray(value)
        ? yup.array().of(yup.string()).required()
        : yup.string().required()
    ),
    tarikh_daftar_geran: yup.date().required(),
    tarikh_wakaf: yup.date().required(),
    alamat_1: yup.string().required(),
    poskod: yup.string().required(),
    id_negeri: yup.string().required(),
    id_daerah: yup.string().required(),
    id_bandar_pekan_mukim: yup.string().required(),
    id_dun: yup.string().required(),
    id_parlimen: yup.string().required(),
    id_kariah: yup.string().required(),
    koordinat: yup.string().required(),
    luas_petak_m2: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
    jum_luas_petak_aks_m2: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
    luas_lot_ekar: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
    luas_bgnn_ekar: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
    bayaran_berimilik: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
    bayaran_cp_plan: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
    bayaran_juruukur: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
    bayaran_daftar_hakmilik: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
    cukai_tahun_semasa_daftar: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
    bayaran_premium: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
  })
  .required();

const FormBasicInformation: React.FC<FormBasicInformationProps> = ({
  action,
  initialData,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    initialData?.senarai_lampiran || [
      {
        id_senarai_lampiran: "",
        tab_name: "aset_wakaf_index",
        file_name: "",
        file_description: "",
      },
    ]
  );
  const [location, setLocation] = useState<[number, number]>([0, 0]);
  const [activeAreaField, setActiveAreaField] = useState("");
  const [bandarOptions, setBandarOptions] = useState<any>([]);
  const [seksyenOptions, setSeksyenOptions] = useState<any>([]);
  const [dunOptions, setDunOptions] = useState<any>([]);

  const history = useHistory();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { createWakafAsset, isCreating } = useCreateWakafAsset();
  const { editWakafAsset, isEditing } = useEditWakafAsset();
  const { wakafRegistration, isInitialLoading } = useWakafRegistration();
  const { sections } = useSectionSettings({ paginate: false });
  const { towns } = useTownSettings({ paginate: false });
  const { duns } = useDunSettings({ paginate: false });

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IWakafAssetFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      tarikh_daftar_geran: formatDate(initialData?.tarikh_daftar_geran),
      tarikh_wakaf: formatDate(initialData?.tarikh_wakaf),
    },
  });

  const {
    jenisAsetOps,
    tujuanSighahWakafOps,
    jenisWakafOps,
    kaedahPerolehanOps,
    noBangunanOps,
    negeriOps,
    daerahOps,
    parlimenOps,
    kariahOps,
    kategoriGunaTanahGeranOps,
    gunaTanahSemasaOps,
    statusAsetWakafOps,
  } = useOptions();
  const {
    noFailMainppOps,
    createNoFailMainppOptions,
    isCreatingNoFailMainppOptions,
  } = useDataFile();

  const watchNegeri = watch("id_negeri");
  const watchDaerah = watch("id_daerah");
  const watchBandar = watch("id_bandar_pekan_mukim");
  const watchParlimen = watch("id_parlimen");

  const handleLocationChange = (newLocation: [number, number]) => {
    setLocation(newLocation);
    if (location.length > 0) {
      setValue("koordinat", newLocation.join(","));
    }
  };

  const handleActiveField = (name: string, value: number) => {
    if (Number(value)) {
      setActiveAreaField(name);
    } else {
      setActiveAreaField("");
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createWakafAsset({
        newWakafAsset: {
          ...data,
          status: 0,
          id_mohon_wakaf: applicationId,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files,
        },
        id: null,
      });
    } else if (action === "edit") {
      editWakafAsset({
        newWakafAsset: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                tab_name: file?.tab_name,
                file_name: file?.file_name,
                file_description: file?.file_description,
              };
            })
          ),
          lampiran: files,
        },
        id: data?.id,
      });
    }
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "luas_petak_m2") {
        const area = handleAreaConversion("m2", value.luas_petak_m2);
        setValue("luas_petak_hektar", area.hektar);
        setValue("luas_petak_ekar", area.ekar);

        handleActiveField("luas_petak_m2", value.luas_petak_m2);
      } else if (name === "jum_luas_petak_aks_m2") {
        const area = handleAreaConversion("m2", value.jum_luas_petak_aks_m2);
        setValue("jum_luas_petak_aks_hektar", area.hektar);
        setValue("jum_luas_petak_aks_ektar", area.ekar);

        handleActiveField("jum_luas_petak_aks_m2", value.jum_luas_petak_aks_m2);
      } else if (name === "luas_lot_ekar") {
        const area = handleAreaConversion("ekar", value.luas_lot_ekar);
        setValue("luas_lot_hektar", area.hektar);
        setValue("luas_lot_m2", area.m2);

        handleActiveField("luas_lot_ekar", value.luas_lot_ekar);
      } else if (name === "luas_bgnn_ekar") {
        const area = handleAreaConversion("ekar", value.luas_bgnn_ekar);
        setValue("luas_bgnn_hektar", area.hektar);
        setValue("luas_bgnn_m2", area.m2);

        handleActiveField("luas_bgnn_ekar", value.luas_bgnn_ekar);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "id_daerah" && towns?.data) {
        setValue("id_bandar_pekan_mukim", null);

        const town = towns?.data
          .filter((town: any) => town.id_daerah == value.id_daerah)
          .map((town: any) => {
            return {
              label: town.nama,
              value: town.id,
            };
          });
        setBandarOptions(town);
      } else if (name === "id_bandar_pekan_mukim" && sections?.data) {
        setValue("id_seksyen", null);

        const section = sections?.data
          .filter(
            (section: any) =>
              section.id_bandar_pekan_mukim == value.id_bandar_pekan_mukim
          )
          .map((section: any) => {
            return {
              label: section.nama,
              value: section.id,
            };
          });
        setSeksyenOptions(section);
      } else if (name === "id_parlimen" && duns?.data) {
        setValue("id_dun", null);

        const dun = duns?.data
          .filter((dun: any) => dun.id_parlimen == value.id_parlimen)
          .map((dun: any) => {
            return {
              label: dun.nama,
              value: dun.id,
            };
          });
        setDunOptions(dun);
      }
    });

    return () => subscription.unsubscribe();
  }, [towns, sections, duns, watch, setValue]);

  useEffect(() => {
    if (initialData) {
      if (towns?.data) {
        const town = towns?.data
          .filter((town: any) => town.id_daerah == initialData.id_daerah)
          .map((town: any) => {
            return {
              label: town.nama,
              value: town.id,
            };
          });
        setBandarOptions(town);
      }

      if (sections?.data) {
        const section = sections?.data
          .filter(
            (section: any) =>
              section.id_bandar_pekan_mukim == initialData.id_bandar_pekan_mukim
          )
          .map((section: any) => {
            return {
              label: section.nama,
              value: section.id,
            };
          });
        setSeksyenOptions(section);
      }

      if (duns?.data) {
        const dun = duns?.data
          .filter((dun: any) => dun.id_parlimen == initialData.id_parlimen)
          .map((dun: any) => {
            return {
              label: dun.nama,
              value: dun.id,
            };
          });
        setDunOptions(dun);
      }
    }
  }, [initialData, towns, sections, duns]);

  useEffect(() => {
    if (action === "create" && wakafRegistration?.data) {
      setValue("nama_pewakaf", wakafRegistration?.data?.nama_pewakaf);
      setValue("no_fon", wakafRegistration?.data?.no_fon);
      setValue("no_kp_baru", wakafRegistration?.data?.no_kp_baru);
      setValue("no_kp_lama", wakafRegistration?.data?.no_kp_lama);
      setValue("no_kp_polis", wakafRegistration?.data?.no_kp_polis);
      setValue("no_kp_tentera", wakafRegistration?.data?.no_kp_tentera);
      setValue(
        "tujuan_sighah",
        wakafRegistration?.data?.tujuan_sighah?.map((j: any) => j.id)
      );
      setValue(
        "jenis_wakaf",
        wakafRegistration?.data?.jenis_wakaf?.map((j: any) => j.id)
      );
      setValue("penerima_manfaat", wakafRegistration?.data?.penerima_manfaat);
      setValue("no_hak_milik", wakafRegistration?.data?.no_hak_milik);
      setValue("no_lot", wakafRegistration?.data?.no_lot);
      setValue("no_pt", wakafRegistration?.data?.no_pt);
      setValue("no_petak", wakafRegistration?.data?.no_petak);
      setValue("petak_aksesori", wakafRegistration?.data?.petak_aksesori);
      setValue("unit_syer_petak", wakafRegistration?.data?.unit_syer_petak);
      setValue("no_tingkat", wakafRegistration?.data?.no_tingkat);
      setValue(
        "no_buku_daftar_strata",
        wakafRegistration?.data?.no_buku_daftar_strata
      );
      setValue(
        "tarikh_daftar_geran",
        formatDate(wakafRegistration?.data?.tarikh_daftar_geran)
      );
      setValue("alamat_1", wakafRegistration?.data?.alamat_1);
      setValue("alamat_2", wakafRegistration?.data?.alamat_2);
      setValue("alamat_3", wakafRegistration?.data?.alamat_3);
      setValue("poskod", wakafRegistration?.data?.poskod);
      setValue("koordinat", wakafRegistration?.data?.koordinat);
      setValue("luas_petak_hektar", wakafRegistration?.data?.luas_petak_hektar);
      setValue("luas_petak_ekar", wakafRegistration?.data?.luas_petak_ekar);
      setValue("luas_petak_m2", wakafRegistration?.data?.luas_petak_m2);
      setValue(
        "jum_luas_petak_aks_hektar",
        wakafRegistration?.data?.jum_luas_petak_aks_hektar
      );
      setValue(
        "jum_luas_petak_aks_ektar",
        wakafRegistration?.data?.jum_luas_petak_aks_ektar
      );
      setValue(
        "jum_luas_petak_aks_m2",
        wakafRegistration?.data?.jum_luas_petak_aks_m2
      );
      setValue("luas_lot_hektar", wakafRegistration?.data?.luas_lot_hektar);
      setValue("luas_lot_ekar", wakafRegistration?.data?.luas_lot_ekar);
      setValue("luas_lot_m2", wakafRegistration?.data?.luas_lot_m2);
      setValue("bhg_tanah_wakaf", wakafRegistration?.data?.bhg_tanah_wakaf);
      setValue(
        "sekatan_kepentingan",
        wakafRegistration?.data?.sekatan_kepentingan
      );
      setValue("syarat_nyata", wakafRegistration?.data?.syarat_nyata);
      setValue("id_jenis_aset", wakafRegistration?.data?.id_jenis_aset);
      setValue("id_no_bangunan", wakafRegistration?.data?.id_no_bangunan);
      setValue("id_negeri", wakafRegistration?.data?.id_negeri);
      setValue("id_daerah", wakafRegistration?.data?.id_daerah);
      setValue(
        "id_bandar_pekan_mukim",
        wakafRegistration?.data?.id_bandar_pekan_mukim
      );
      setValue("id_seksyen", wakafRegistration?.data?.id_seksyen);
      setValue("id_parlimen", wakafRegistration?.data?.id_parlimen);
      setValue("id_dun", wakafRegistration?.data?.id_dun);
      setValue(
        "id_kat_guna_tanah_geran",
        wakafRegistration?.data?.id_kat_guna_tanah_geran
      );
    }
  }, [action, wakafRegistration, setValue]);

  if (isInitialLoading) return <FullScreenLoader />;

  return (
    <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <Flex gap="20px" justifyContent={"flex-end"} mb="20px">
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={action === "create" ? "reset" : "button"}
            onClick={() => {
              if (action !== "create") {
                history.push("/admin/wakaf/aset");
              }
            }}
          >
            {action === "create" ? "Batal" : "Kembali"}
          </Button>

          {action !== "show" && (
            <Button
              variant="brand"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              type="submit"
              disabled={isCreating || isEditing}
            >
              {action === "edit" ? "Kemaskini Aset Wakaf" : "Daftar Aset Wakaf"}
            </Button>
          )}
        </Flex>

        <Grid templateColumns="repeat(2, 1fr)" gap={{ sm: "6px", md: "18px" }}>
          {action !== "create" && (
            <>
              <Flex flexDirection="column" gap="4px">
                <SelectField
                  label={"Status Aset Wakaf"}
                  options={statusAsetWakafOps}
                  isInvalid={!!errors.status}
                  errorMessage={errors.status?.message}
                  readOnly={action === "show"}
                  {...register("status")}
                />

                {initialData?.id_mohon_wakaf && (
                  <Flex alignItems="center" gap="8px" px="4px">
                    <Text variant="subtitle" fontSize="sm">
                      Permohonan wakaf yang berdaftar:{" "}
                    </Text>
                    <Text variant="link" fontSize="sm" fontWeight="500">
                      <NavLink
                        to={`/admin/wakaf/permohonan/show/${initialData.id_mohon_wakaf}`}
                      >
                        {initialData.id_mohon_wakaf}
                      </NavLink>
                    </Text>
                  </Flex>
                )}
              </Flex>

              <TextField
                id="status_deskripsi"
                label="Deskripsi Status"
                type="textarea"
                isInvalid={!!errors.status_deskripsi}
                errorMessage={errors.status_deskripsi?.message}
                readOnly={action === "show"}
                {...register("status_deskripsi")}
              />
            </>
          )}

          <SelectField
            label={"Kaeadah Peroleh Tanah Wakaf"}
            options={kaedahPerolehanOps}
            isInvalid={!!errors.id_kaedah_peroleh}
            errorMessage={errors.id_kaedah_peroleh?.message}
            readOnly={action === "show"}
            {...register("id_kaedah_peroleh")}
          />

          <TextField
            id="nama_pewakaf"
            label="Nama Pewakaf"
            placeholder="Ramli Saad"
            isInvalid={!!errors.nama_pewakaf}
            errorMessage={errors.nama_pewakaf?.message}
            readOnly={action === "show"}
            {...register("nama_pewakaf")}
          />

          <TextField
            id="no_fon"
            label="No. Telefon Pewakaf"
            isInvalid={!!errors.no_fon}
            errorMessage={errors.no_fon?.message}
            readOnly={action === "show"}
            {...register("no_fon")}
          />

          <TextField
            id="no_kp_baru"
            label="No. Kad Pengenalan Baru"
            maxLength={12}
            isInvalid={!!errors.no_kp_baru}
            errorMessage={errors.no_kp_baru?.message}
            readOnly={action === "show"}
            {...register("no_kp_baru")}
          />

          <TextField
            id="no_kp_lama"
            label="No. Kad Pengenalan Lama"
            isInvalid={!!errors.no_kp_lama}
            errorMessage={errors.no_kp_lama?.message}
            readOnly={action === "show"}
            {...register("no_kp_lama")}
          />

          <TextField
            id="no_kp_polis"
            label="No. Kad Pengenalan Polis"
            isInvalid={!!errors.no_kp_polis}
            errorMessage={errors.no_kp_polis?.message}
            readOnly={action === "show"}
            {...register("no_kp_polis")}
          />

          <TextField
            id="no_kp_tentera"
            label="No. Kad Pengenalan Tentera"
            isInvalid={!!errors.no_kp_tentera}
            errorMessage={errors.no_kp_tentera?.message}
            readOnly={action === "show"}
            {...register("no_kp_tentera")}
          />

          <TextField
            id="alamat_pewakaf"
            label="Alamat Pewakaf"
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="textarea"
            isInvalid={!!errors.alamat_pewakaf}
            errorMessage={errors.alamat_pewakaf?.message}
            readOnly={action === "show"}
            {...register("alamat_pewakaf")}
          />

          <SelectField
            label={"Jenis Aset"}
            options={jenisAsetOps}
            isInvalid={!!errors.id_jenis_aset}
            errorMessage={errors.id_jenis_aset?.message}
            readOnly={action === "show"}
            {...register("id_jenis_aset")}
          />

          <CreatableSelectField
            label={"Tujuan dan Sighah Wakaf"}
            defaultOptions={tujuanSighahWakafOps}
            defaultValue={
              wakafRegistration?.data?.tujuan_sighah ||
              initialData?.tujuan_sighah
            }
            isMulti
            onChange={(value) => {
              setValue("tujuan_sighah", value);
            }}
            isInvalid={!!errors.tujuan_sighah}
            errorMessage={errors.tujuan_sighah?.message}
            readOnly={action === "show"}
          />

          <CreatableSelectField
            label={"Jenis Wakaf"}
            defaultOptions={jenisWakafOps}
            defaultValue={
              wakafRegistration?.data?.jenis_wakaf || initialData?.jenis_wakaf
            }
            isMulti
            onChange={(value) => setValue("jenis_wakaf", value)}
            isInvalid={!!errors.jenis_wakaf}
            errorMessage={errors.jenis_wakaf?.message}
            readOnly={action === "show"}
          />

          <TextField
            id="penerima_manfaat"
            label="Penerima Manfaat"
            isInvalid={!!errors.penerima_manfaat}
            errorMessage={errors.penerima_manfaat?.message}
            readOnly={action === "show"}
            {...register("penerima_manfaat")}
          />

          <TextField
            id="no_hak_milik"
            label="No. Hak Milik"
            placeholder="GRN 169578"
            isInvalid={!!errors.no_hak_milik}
            errorMessage={errors.no_hak_milik?.message}
            readOnly={action === "show"}
            {...register("no_hak_milik")}
          />

          <TextField
            id="no_hak_milik"
            label="No. Hak Milik Sementara"
            placeholder="GRN 169578"
            isInvalid={!!errors.no_hak_milik_sementara}
            errorMessage={errors.no_hak_milik_sementara?.message}
            readOnly={action === "show"}
            {...register("no_hak_milik_sementara")}
          />

          <TextField
            id="no_lot"
            label="No. Lot"
            placeholder="10066 7 DIGIT NUMBER"
            isInvalid={!!errors.no_lot}
            errorMessage={errors.no_lot?.message}
            readOnly={action === "show"}
            {...register("no_lot")}
          />

          <TextField
            id="no_pt"
            label="No. PT"
            placeholder="PT 42"
            isInvalid={!!errors.no_pt}
            errorMessage={errors.no_pt?.message}
            readOnly={action === "show"}
            {...register("no_pt")}
          />

          <TextField
            id="no_petak"
            label="No. Petak"
            placeholder="3, L2, L1, L25"
            isInvalid={!!errors.no_petak}
            errorMessage={errors.no_petak?.message}
            readOnly={action === "show"}
            {...register("no_petak")}
          />

          <TextField
            id="petak_aksesori"
            label="Petak Aksesori"
            placeholder="A66,A69"
            isInvalid={!!errors.petak_aksesori}
            errorMessage={errors.petak_aksesori?.message}
            readOnly={action === "show"}
            {...register("petak_aksesori")}
          />

          <TextField
            id="unit_syer_petak"
            label="Unit Syer Petak"
            placeholder="109"
            isInvalid={!!errors.unit_syer_petak}
            errorMessage={errors.unit_syer_petak?.message}
            readOnly={action === "show"}
            {...register("unit_syer_petak")}
          />

          <TextField
            id="syer_bahagian"
            label="Syer Bahagian"
            isInvalid={!!errors.syer_bahagian}
            errorMessage={errors.syer_bahagian?.message}
            readOnly={action === "show"}
            {...register("syer_bahagian")}
          />

          <TextField
            id="no_tingkat"
            label="No. Tingkat"
            placeholder="2, 14, 79"
            isInvalid={!!errors.no_tingkat}
            errorMessage={errors.no_tingkat?.message}
            readOnly={action === "show"}
            {...register("no_tingkat")}
          />

          <SelectField
            label={"No. Bangunan"}
            options={noBangunanOps}
            isInvalid={!!errors.id_no_bangunan}
            errorMessage={errors.id_no_bangunan?.message}
            readOnly={action === "show"}
            {...register("id_no_bangunan")}
          />

          <TextField
            id="no_pelan_diperakui"
            label="No Pelan DiPerakui"
            isInvalid={!!errors.no_pelan_diperakui}
            errorMessage={errors.no_pelan_diperakui?.message}
            readOnly={action === "show"}
            {...register("no_pelan_diperakui")}
          />

          <TextField
            id="no_buku_daftar_strata"
            label="No. Buku Daftar Strata"
            placeholder="2/2020"
            isInvalid={!!errors.no_buku_daftar_strata}
            errorMessage={errors.no_buku_daftar_strata?.message}
            readOnly={action === "show"}
            {...register("no_buku_daftar_strata")}
          />

          <TextField
            id="tarikh_daftar_geran"
            label="Tarikh Daftar Geran"
            type="date"
            isInvalid={!!errors.tarikh_daftar_geran}
            errorMessage={errors.tarikh_daftar_geran?.message}
            readOnly={action === "show"}
            {...register("tarikh_daftar_geran")}
          />

          <TextField
            id="tarikh_wakaf"
            label="Tarikh Wakaf"
            type="date"
            isInvalid={!!errors.tarikh_wakaf}
            errorMessage={errors.tarikh_wakaf?.message}
            readOnly={action === "show"}
            {...register("tarikh_wakaf")}
          />

          <TextField
            id="alamat_1"
            label="Alamat 1"
            placeholder="LOT 2449,Jalan Air Itam"
            isInvalid={!!errors.alamat_1}
            errorMessage={errors.alamat_1?.message}
            readOnly={action === "show"}
            {...register("alamat_1")}
          />
          <TextField
            id="alamat_2"
            label="Alamat 2"
            placeholder="Kampung Pisang"
            isInvalid={!!errors.alamat_2}
            errorMessage={errors.alamat_2?.message}
            readOnly={action === "show"}
            {...register("alamat_2")}
          />

          <TextField
            id="alamat_3"
            label="Alamat 3"
            isInvalid={!!errors.alamat_3}
            errorMessage={errors.alamat_3?.message}
            readOnly={action === "show"}
            {...register("alamat_3")}
          />

          <TextField
            id="poskod"
            label="Poskod"
            placeholder="11500"
            isInvalid={!!errors.poskod}
            errorMessage={errors.poskod?.message}
            readOnly={action === "show"}
            {...register("poskod")}
          />

          <SelectField
            label={"Negeri"}
            options={negeriOps}
            isInvalid={!!errors.id_negeri}
            errorMessage={errors.id_negeri?.message}
            readOnly={action === "show"}
            {...register("id_negeri")}
          />

          <SelectField
            label={"Daerah"}
            options={daerahOps}
            isDisabled={!watchNegeri}
            isInvalid={!!errors.id_daerah}
            errorMessage={errors.id_daerah?.message}
            readOnly={action === "show"}
            {...register("id_daerah")}
          />

          <SelectField
            label={"Bandar/Mukim"}
            options={bandarOptions}
            isDisabled={!watchDaerah}
            isInvalid={!!errors.id_bandar_pekan_mukim}
            errorMessage={errors.id_bandar_pekan_mukim?.message}
            readOnly={action === "show"}
            {...register("id_bandar_pekan_mukim")}
          />

          <SelectField
            label={"Seksyen"}
            options={seksyenOptions}
            isDisabled={!watchBandar}
            isInvalid={!!errors.id_seksyen}
            errorMessage={errors.id_seksyen?.message}
            readOnly={action === "show"}
            {...register("id_seksyen")}
          />

          <SelectField
            label={"Parlimen"}
            options={parlimenOps}
            isInvalid={!!errors.id_parlimen}
            errorMessage={errors.id_parlimen?.message}
            readOnly={action === "show"}
            {...register("id_parlimen")}
          />

          <SelectField
            label={"Dun"}
            options={dunOptions}
            isDisabled={!watchParlimen}
            isInvalid={!!errors.id_dun}
            errorMessage={errors.id_dun?.message}
            readOnly={action === "show"}
            {...register("id_dun")}
          />

          <SelectField
            label={"Kariah"}
            options={kariahOps}
            isInvalid={!!errors.id_kariah}
            errorMessage={errors.id_kariah?.message}
            readOnly={action === "show"}
            {...register("id_kariah")}
          />

          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel variant="main" mb="8px">
                Lokasi
              </FormLabel>

              <MapLocator
                onLocationChange={handleLocationChange}
                readOnly={action === "show"}
              />
            </FormControl>
          </GridItem>

          <GridItem
            display={"grid"}
            colSpan={2}
            gap={{ sm: "6px", md: "18px" }}
          >
            <FormControl>
              <FormLabel variant="main" mb="8px">
                Luas Petak
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="luas_petak_hektar"
                  label="Hektar"
                  isInvalid={!!errors.luas_petak_hektar}
                  errorMessage={errors.luas_petak_hektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_petak_hektar")}
                />

                <NumberGroupField
                  id="luas_petak_ekar"
                  label="Ekar"
                  isInvalid={!!errors.luas_petak_ekar}
                  errorMessage={errors.luas_petak_ekar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_petak_ekar")}
                />

                <NumberGroupField
                  id="luas_petak_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  step={"any"}
                  isInvalid={!!errors.luas_petak_m2}
                  errorMessage={errors.luas_petak_m2?.message}
                  readOnly={action === "show"}
                  disabled={
                    activeAreaField && activeAreaField !== "luas_petak_m2"
                  }
                  {...register("luas_petak_m2")}
                />
              </SimpleGrid>
            </FormControl>

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Jumlah Luas Petak Aksesori
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="jum_luas_petak_aks_hektar"
                  label="Hektar"
                  isInvalid={!!errors.jum_luas_petak_aks_hektar}
                  errorMessage={errors.jum_luas_petak_aks_hektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("jum_luas_petak_aks_hektar")}
                />

                <NumberGroupField
                  id="jum_luas_petak_aks_ektar"
                  label="Ekar"
                  isInvalid={!!errors.jum_luas_petak_aks_ektar}
                  errorMessage={errors.jum_luas_petak_aks_ektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("jum_luas_petak_aks_ektar")}
                />

                <NumberGroupField
                  id="jum_luas_petak_aks_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  step={"any"}
                  isInvalid={!!errors.jum_luas_petak_aks_m2}
                  errorMessage={errors.jum_luas_petak_aks_m2?.message}
                  readOnly={action === "show"}
                  disabled={
                    activeAreaField &&
                    activeAreaField !== "jum_luas_petak_aks_m2"
                  }
                  {...register("jum_luas_petak_aks_m2")}
                />
              </SimpleGrid>
            </FormControl>

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Keluasan Lot
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="luas_lot_hektar"
                  label="Hektar"
                  isInvalid={!!errors.luas_lot_hektar}
                  errorMessage={errors.luas_lot_hektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_lot_hektar")}
                />

                <NumberGroupField
                  id="luas_lot_ekar"
                  label="Ekar"
                  step={"any"}
                  isInvalid={!!errors.luas_lot_ekar}
                  errorMessage={errors.luas_lot_ekar?.message}
                  readOnly={action === "show"}
                  disabled={
                    activeAreaField && activeAreaField !== "luas_lot_ekar"
                  }
                  {...register("luas_lot_ekar")}
                />

                <NumberGroupField
                  id="luas_lot_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  isInvalid={!!errors.luas_lot_m2}
                  errorMessage={errors.luas_lot_m2?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_lot_m2")}
                />
              </SimpleGrid>
            </FormControl>

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Keluasan Bangunan
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="luas_bgnn_hektar"
                  label="Hektar"
                  isInvalid={!!errors.luas_bgnn_hektar}
                  errorMessage={errors.luas_bgnn_hektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_bgnn_hektar")}
                />

                <NumberGroupField
                  id="luas_bgnn_ekar"
                  label="Ekar"
                  step={"any"}
                  isInvalid={!!errors.luas_bgnn_ekar}
                  errorMessage={errors.luas_bgnn_ekar?.message}
                  readOnly={action === "show"}
                  disabled={
                    activeAreaField && activeAreaField !== "luas_bgnn_ekar"
                  }
                  {...register("luas_bgnn_ekar")}
                />

                <NumberGroupField
                  id="luas_bgnn_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  isInvalid={!!errors.luas_bgnn_m2}
                  errorMessage={errors.luas_bgnn_m2?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_bgnn_m2")}
                />
              </SimpleGrid>
            </FormControl>
          </GridItem>

          <TextField
            id="bhg_tanah_wakaf"
            label="Bahagian Tanah Untuk Diwakafkan"
            placeholder="808.00 Meter Persegi ,11 ekar,11 kaki"
            isInvalid={!!errors.bhg_tanah_wakaf}
            errorMessage={errors.bhg_tanah_wakaf?.message}
            readOnly={action === "show"}
            {...register("bhg_tanah_wakaf")}
          />

          <GridItem
            display={"grid"}
            colSpan={2}
            gap={{ sm: "6px", md: "18px" }}
          >
            <FormControl>
              <FormLabel variant="main" mb="8px">
                UPI
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <LinkGroupField
                  label="UPI JUPEM LINK"
                  labelWidth={"180px"}
                  readOnly={action === "show"}
                  disabled
                  {...register("upi_jupem")}
                />

                <LinkGroupField
                  label="UPI PT LINK"
                  labelWidth={"180px"}
                  readOnly={action === "show"}
                  disabled
                  {...register("upi_pt")}
                />

                <LinkGroupField
                  label="UPI STRATA LINK"
                  labelWidth={"180px"}
                  readOnly={action === "show"}
                  disabled
                  {...register("upi_strata")}
                />
              </SimpleGrid>
            </FormControl>
          </GridItem>

          <TextField
            id="maklumat_pembentang"
            label="Maklumat Pembentang"
            type="textarea"
            isInvalid={!!errors.maklumat_pembentang}
            errorMessage={errors.maklumat_pembentang?.message}
            readOnly={action === "show"}
            {...register("maklumat_pembentang")}
          />

          <TextField
            id="rujukan_dalil"
            label="Rujukan/Hujah/Dalil Yang Dirujuk"
            type="textarea"
            isInvalid={!!errors.rujukan_dalil}
            errorMessage={errors.rujukan_dalil?.message}
            readOnly={action === "show"}
            {...register("rujukan_dalil")}
          />

          <TextField
            id="keputusan_fatwa"
            label="Maklumat Keputusan Fatwa"
            type="textarea"
            isInvalid={!!errors.keputusan_fatwa}
            errorMessage={errors.keputusan_fatwa?.message}
            readOnly={action === "show"}
            {...register("keputusan_fatwa")}
          />

          <TextField
            id="tajuk_kertas"
            label="Tajuk Kertas"
            type="textarea"
            isInvalid={!!errors.tajuk_kertas}
            errorMessage={errors.tajuk_kertas?.message}
            readOnly={action === "show"}
            {...register("tajuk_kertas")}
          />

          <TextField
            id="pajakan"
            label="Pajakan"
            type="textarea"
            isInvalid={!!errors.pajakan}
            errorMessage={errors.pajakan?.message}
            readOnly={action === "show"}
            {...register("pajakan")}
          />

          <CreatableSelectField
            label={"No. Fail MAINPP"}
            defaultOptions={noFailMainppOps}
            defaultValue={initialData?.id_no_fail_mainpp}
            onChange={(value) => setValue("id_no_fail_mainpp", value)}
            onCreateOption={(value) =>
              createNoFailMainppOptions({
                newOption: { no_fail_main_pp: value },
                id: null,
              })
            }
            isLoading={isCreatingNoFailMainppOptions}
            isInvalid={!!errors.id_no_fail_mainpp}
            errorMessage={errors.id_no_fail_mainpp?.message}
            readOnly={action === "show"}
          />

          <SelectField
            label={"Kategori Penggunaan Tanah (Geran)"}
            options={kategoriGunaTanahGeranOps}
            isInvalid={!!errors.id_kat_guna_tanah_geran}
            errorMessage={errors.id_kat_guna_tanah_geran?.message}
            readOnly={action === "show"}
            {...register("id_kat_guna_tanah_geran")}
          />

          <SelectField
            label={"Gunatanah Semasa"}
            options={gunaTanahSemasaOps}
            isInvalid={!!errors.id_guna_tanah_semasa}
            errorMessage={errors.id_guna_tanah_semasa?.message}
            readOnly={action === "show"}
            {...register("id_guna_tanah_semasa")}
          />

          <SelectField
            label="Kegunaan Tanah Wakaf Semasa"
            options={gunaTanahSemasaOps}
            isInvalid={!!errors.id_kegunaan_tanah_wakaf_semasa}
            errorMessage={errors.id_kegunaan_tanah_wakaf_semasa?.message}
            readOnly={action === "show"}
            {...register("id_kegunaan_tanah_wakaf_semasa")}
          />

          <SelectField
            label="Operasi Kegunaan Tanah Wakaf Semasa"
            options={gunaTanahSemasaOps}
            isInvalid={!!errors.id_operasi_tanah_wakaf_semasa}
            errorMessage={errors.id_operasi_tanah_wakaf_semasa?.message}
            readOnly={action === "show"}
            {...register("id_operasi_tanah_wakaf_semasa")}
          />

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Bayaran Berimilik
            </FormLabel>
            <NumberGroupField
              label="RM"
              step="any"
              isInvalid={!!errors.bayaran_berimilik}
              errorMessage={errors.bayaran_berimilik?.message}
              readOnly={action === "show"}
              {...register("bayaran_berimilik")}
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Bayaran CP Plan
            </FormLabel>

            <NumberGroupField
              label="RM"
              step="any"
              isInvalid={!!errors.bayaran_cp_plan}
              errorMessage={errors.bayaran_cp_plan?.message}
              readOnly={action === "show"}
              {...register("bayaran_cp_plan")}
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Bayaran Juruukur
            </FormLabel>

            <NumberGroupField
              label="RM"
              step="any"
              isInvalid={!!errors.bayaran_juruukur}
              errorMessage={errors.bayaran_juruukur?.message}
              readOnly={action === "show"}
              {...register("bayaran_juruukur")}
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Bayaran Pendaftaran Hakmilik
            </FormLabel>

            <NumberGroupField
              label="RM"
              step="any"
              isInvalid={!!errors.bayaran_daftar_hakmilik}
              errorMessage={errors.bayaran_daftar_hakmilik?.message}
              readOnly={action === "show"}
              {...register("bayaran_daftar_hakmilik")}
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Cukai Tahunan-Aset Masa Daftar
            </FormLabel>

            <NumberGroupField
              label="RM"
              step="any"
              isInvalid={!!errors.cukai_tahun_semasa_daftar}
              errorMessage={errors.cukai_tahun_semasa_daftar?.message}
              readOnly={action === "show"}
              {...register("cukai_tahun_semasa_daftar")}
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Bayaran Premium
            </FormLabel>

            <NumberGroupField
              label="RM"
              step="any"
              isInvalid={!!errors.bayaran_premium}
              errorMessage={errors.bayaran_premium?.message}
              readOnly={action === "show"}
              {...register("bayaran_premium")}
            />
          </FormControl>

          <GridItem
            display={"grid"}
            colSpan={2}
            gap={{ sm: "6px", md: "18px" }}
            mt={"20px"}
          >
            <Section size={"md"} title="Lampiran Aset Wakaf">
              <FileUploadField
                files={files}
                setFiles={setFiles}
                fileDescriptions={fileDescriptions}
                setFileDescriptions={setFileDescriptions}
                tabName="aset_wakaf_index"
                readOnly={action === "show"}
              />
            </Section>
          </GridItem>
        </Grid>
      </form>
    </Flex>
  );
};

export default FormBasicInformation;
