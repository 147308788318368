import React from "react";
import FormSectionSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useSectionSetting } from "hooks/setting/dataSetting/sectionSetting/useSectionSetting";

export default function EditSectionSetting() {
  const { isLoading, section, error } = useSectionSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormSectionSetting action="edit" initialData={section?.data} />;
}
