import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getNoFailMainppOption } from "services/setting/dataSetting/noFailMainppSettingService";

export function useNoFailMainppSetting() {
  const { noFailMainppId } = useParams<{ noFailMainppId: string }>();
  const toast = useToast();

  const {
    isLoading,
    data: noFailMainpp,
    error,
  } = useQuery({
    queryKey: ["noFailMainpp", noFailMainppId],
    queryFn: () => getNoFailMainppOption(noFailMainppId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: "always",
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isLoading, error, noFailMainpp };
}

