import { Icon } from "@chakra-ui/react";
import { MdHome, MdLock } from "react-icons/md";

import MainDashboard from "views/admin/dashboard";
import Profile from "views/setting/profile";

import SignInCentered from "views/auth/signIn";
import ForgotPassword from "views/auth/forgotPassword";

const mainRoutes = [
  {
    name: "Utama",
    layout: "/admin",
    path: "/dashboards",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Profil",
    layout: "/admin",
    path: "/profile",
    component: Profile,
    secondary: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
  },
];

export default mainRoutes;
