import axios from "utils/axios";
import { ISumberWakafSetting } from "entities/setting/dataSettings";

export async function getSumberWakafNameOptions() {
  const { data } = await axios.call({
    method: "get",
    url: "/general/sumberWakaf/options",
  });
  return data;
}

export async function getSumberWakafOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/sumberWakaf",
    params,
  });
  return data;
}

export async function getSumberWakafOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/sumberWakaf/${id}`,
  });
  return data;
}


export async function createEditSumberWakafOption(
  values: ISumberWakafSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/sumberWakaf/${id}` : "/general/sumberWakaf";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteSumberWakafOption(
  id: string | number | null
) {
  const apiUrl = `/general/sumberWakaf/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

