import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditOperasiTanahSemasaOption } from "services/setting/dataSetting/operasiTanahSemasaSettingService";
import { IOperasiTanahSemasaSetting } from "entities/setting/dataSettings";

export function useEditOperasiTanahSemasaSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: editOperasiTanahSemasaSetting, isLoading: isEditing } = useMutation({
    mutationFn: ({
      operasiTanahSemasaSetting,
      id,
    }: {
      operasiTanahSemasaSetting: IOperasiTanahSemasaSetting;
      id: string | null;
    }) => createEditOperasiTanahSemasaOption(operasiTanahSemasaSetting, id),
    onSuccess: () => {
      toast({
        title: "Operasi tanah semasa successfully updated",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["operasiTanahSemasa"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isEditing, editOperasiTanahSemasaSetting };
}
