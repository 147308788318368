import axios from "utils/axios";
import { INoFailPtgSetting } from "entities/setting/dataSettings";

export async function getNoFailPtgOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/noFailPTG",
    params,
  });
  return data;
}

export async function getNoFailPtgOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/noFailPTG/${id}`,
  });
  return data;
}

export async function createEditNoFailPtgOption(
  values: INoFailPtgSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/noFailPTG/${id}` : "/general/noFailPTG";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteNoFailPtgOption(
  id: string | number | null
) {
  const apiUrl = `/general/noFailPTG/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

