import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { getNoFailMainppOptions, createEditNoFailMainppOptions, getNoFailPtgOptions, createEditNoFailPtgOptions } from "services/setting/dataSetting/fileSettingService";

export default function useDataFile() {
  const queryClient = useQueryClient();

  const {
    data: noFailMainppOptions,
  } = useQuery({
    queryKey: ["noFailMainpp"],
    queryFn: getNoFailMainppOptions,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: "always",
  });

  const { mutate: createNoFailMainppOptions, isLoading: isCreatingNoFailMainppOptions } = useMutation({
    mutationFn: ({newOption, id}: {newOption: {no_fail_main_pp: string}, id: string | null}) => createEditNoFailMainppOptions(newOption, id),
    onSuccess: () => {
        queryClient.invalidateQueries(["noFailMainpp"]);
    },
    onError: (err: any) => {
      console.error("ERROR", err);
    },
  });

  const {
    data: noFailPtgOptions,
  } = useQuery({
    queryKey: ["noFailPtg"],
    queryFn: getNoFailPtgOptions,
  });

  const { mutate: createNoFailPtgOptions, isLoading: isCreatingNoFailPtgOptions } = useMutation({
    mutationFn: ({newOption, id}: {newOption: {no_fail_ptg: string}, id: string | null}) => createEditNoFailPtgOptions(newOption, id),
    onSuccess: () => {
        queryClient.invalidateQueries(["noFailPtg"]);
    },
    onError: (err: any) => {
      console.error("ERROR", err);
    },
  });

  // API
  const noFailMainppOps = noFailMainppOptions?.data || []
  const noFailPtgOps = noFailPtgOptions?.data || []

  // OUTPUT
  return {
    noFailMainppOps,
    createNoFailMainppOptions,
    isCreatingNoFailMainppOptions,
    noFailPtgOps,
    createNoFailPtgOptions,
    isCreatingNoFailPtgOptions,
  };
}
