import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { Box, Text, Link, List, Icon, Badge } from "@chakra-ui/react";
import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import Resource from "components/resource/Resource";
import Sorting from "./components/Sorting";

import { IWakafRegistrationTable } from "entities/admin/wakafRegistration";
import { useWakafRegistrations } from "hooks/admin/wakafRegistration/useWakafRegistrations";
import { useDeleteWakafRegistration } from "hooks/admin/wakafRegistration/useDeleteWakafRegistration";
import { useCurrentUser } from "hooks/setting/user/useCurrentUser";

import { formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<IWakafRegistrationTable>();

const getStatusDescription = (status: number) => {
  switch (status) {
    case -1:
      return <Badge colorScheme="red">Gagal</Badge>;
    case 1:
      return <Badge colorScheme="green">Lulus</Badge>;
    default:
      return <Badge colorScheme="yellow">Dalam Proses</Badge>;
  }
};

export default function WakafRegistration() {
  const { isLoading, error, wakafRegistrations } = useWakafRegistrations();
  const { removeWakafRegistration, isDeleting } = useDeleteWakafRegistration();
  const { currentUser } = useCurrentUser();
  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID Permohonan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("jenis_permohonan.nama", {
      header: () => <Text variant="tableHeading">Jenis Permohonan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_pewakaf", {
      header: () => <Text variant="tableHeading">Nama Pewakaf</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kp_baru", {
      header: () => <Text variant="tableHeading">No Kad Pengenalan Baru</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_lot", {
      header: () => <Text variant="tableHeading">No Lot</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_pt", {
      header: () => <Text variant="tableHeading">No PT</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("status", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info) => getStatusDescription(info.getValue()),
    }),
    columnHelper.accessor("tarikh_lulus", {
      header: () => <Text variant="tableHeading">Tarikh Lulus</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("id_aset_wakaf", {
      header: () => <Text variant="tableHeading">ID Asset Waqaf</Text>,
      cell: (info) => {
        if (
          info.row.original.status === 1 &&
          info.row.original?.id_aset_wakaf
        ) {
          return (
            <NavLink to={`/admin/wakaf/aset/show/${info.getValue()}`}>
              <Text variant="link" fontSize="sm" fontWeight="500">
                {info.getValue()}
              </Text>
            </NavLink>
          );
        }
        return <Text></Text>;
      },
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <NavLink
            to={`/admin/wakaf/permohonan/kemaskini/${info.row.original.id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink to={`/admin/wakaf/permohonan/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>

          {currentUser?.data?.role_id === 2 &&
            !info.row.original?.id_aset_wakaf && (
              <Link
                as={"button"}
                onClick={() => {
                  removeWakafRegistration(info.row.original.id);
                }}
                disabled={isDeleting}
              >
                <Icon
                  w="24px"
                  h="24px"
                  me="5px"
                  color={"red.500"}
                  as={MdDelete}
                />
              </Link>
            )}
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={wakafRegistrations}
        title={"Senarai Permohonan Wakaf"}
        createTitle="Daftar Permohonan Wakaf"
        onCreate={() => history.push("/admin/wakaf/permohonan/baru")}
        allowDownload
        onClickDownload={() => console.log}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
