import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Text, List, Icon } from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";

import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

import Resource from "components/resource/Resource";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { useWakafAssets } from "hooks/admin/wakafManagement/wakafAsset/useWakafAssets";
import { IWakafDevelopmentTable } from "entities/admin/wakafManagement";

import { fetchLabel } from "utils/helpers";
import Sorting from "./components/Sorting";

const columnHelper = createColumnHelper<IWakafDevelopmentTable>();

export default function WakafDevelopment() {
  const { jenisAsetOps, daerahOps, bandarPekanMukimOps, seksyenOps } = useOptions();
  const { isLoading, error, wakafAssets } = useWakafAssets({
    isPembangunanExist: 1,
  });

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID Aset Wakaf</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id_jenis_aset", {
      header: () => <Text variant="tableHeading">Jenis Aset</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), jenisAsetOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_daerah", {
      header: () => <Text variant="tableHeading">Daerah</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), daerahOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_bandar_pekan_mukim", {
      header: () => <Text variant="tableHeading">Bandar/Mukim</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), bandarPekanMukimOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_seksyen", {
      header: () => <Text variant="tableHeading">Seksyen</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), seksyenOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("no_hak_milik", {
      header: () => <Text variant="tableHeading">No Hakmilik</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_lot", {
      header: () => <Text variant="tableHeading">No Lot</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_pt", {
      header: () => <Text variant="tableHeading">No PT</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <NavLink
            to={{
              pathname: `/admin/wakaf/aset/kemaskini/${info.row.original.id}`,
              state: { tabIndex: 6 },
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink
            to={{
              pathname: `/admin/wakaf/aset/show/${info.row.original.id}`,
              state: { tabIndex: 6 },
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={wakafAssets}
        title={"Senarai Pembangunan Aset Wakaf"}
        allowDownload
        onClickDownload={() => console.log}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
