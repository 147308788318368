import React from "react";
import { Text, Link, List, Icon } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import Resource from "components/resource/Resource";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { useWakafHistories } from "hooks/admin/wakafManagement/wakafHistory/useWakafHistories";
import { useDeleteWakafHistory } from "hooks/admin/wakafManagement/wakafHistory/useDeleteWakafHistory";

import { IWakafHistoryTable } from "entities/admin/wakafManagement";

import { formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<IWakafHistoryTable>();

interface TableHistoryProps {
  wakafAssetId?: number;
  toggleFormHistory: (
    action: "create" | "edit" | "show",
    wakafHistoryId?: number
  ) => void;  
}

const TableHistory = ({
  wakafAssetId,
  toggleFormHistory,
}: TableHistoryProps) => {
  const { wakafHistories, isLoading } = useWakafHistories({
    idAsetWakaf: wakafAssetId,
  });
  const { removeWakafHistory, isDeleting } = useDeleteWakafHistory();

  const columns = [
    columnHelper.accessor("updated_at", {
      header: () => <Text variant="tableHeading">Tarikh Kemaskini</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_kejadian", {
      header: () => <Text variant="tableHeading">Tarikh Kejadian</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("tajuk", {
      header: () => <Text variant="tableHeading">Tajuk</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <Link
            as={"button"}
            onClick={() => {
              toggleFormHistory("edit", info.row.original.id);
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </Link>

          <Link
            as={"button"}
            onClick={() => {
              toggleFormHistory("show", info.row.original.id);
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </Link>

          <Link
            as={"button"}
            onClick={() => {
              removeWakafHistory(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Resource
      columns={columns}
      data={wakafHistories}
      title={"Senarai Rekod Sejarah"}
      createTitle="Tambah Rekod Sejarah"
      onCreate={() => toggleFormHistory("create")}
      allowDownload
      onClickDownload={() => console.log("Download")}
    />
  );
};

export default TableHistory;
