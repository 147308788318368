import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { createEditNegeriOption } from "services/setting/dataSetting/stateSettingService";
import { IStateSetting } from "entities/setting/dataSettings";

export function useCreateStateSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createStateSetting, isLoading: isCreating } = useMutation({
    mutationFn: ({stateSetting, id}: {stateSetting: IStateSetting, id?: string | null}) => createEditNegeriOption(stateSetting, id),
    onSuccess: () => {
      toast({
        title: "New state successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["state"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createStateSetting };
}