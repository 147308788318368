import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete } from "react-icons/md";

import Resource from "components/resource/Resource";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { IContractorSetting } from "entities/setting/dataSettings";
import { useContractorSettings } from "hooks/setting/dataSetting/contractorSetting/useContractorSettings";
import { useDeleteContractorSetting } from "hooks/setting/dataSetting/contractorSetting/useDeleteContractorSetting";

const columnHelper = createColumnHelper<IContractorSetting>();

const ContractorSetting = () => {
  const { contractors, isLoading } = useContractorSettings();
  const { removeContractorSetting, isDeleting } = useDeleteContractorSetting();
  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_syarikat", {
      header: () => <Text variant="tableHeading">Nama Syarikat</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_ssm", {
      header: () => <Text variant="tableHeading">No SSM</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_pegawai", {
      header: () => <Text variant="tableHeading">Nama Pegawai</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_telefon", {
      header: () => <Text variant="tableHeading">No Telefon</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("catatan", {
      header: () => <Text variant="tableHeading">Catatan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("updated_at", {
      header: () => <Text variant="tableHeading">Tarikh Kemaskini</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <NavLink to={`/admin/settings/data/kontraktor/kemaskini/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <Link
            as={"button"}
            onClick={() => {
              removeContractorSetting(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={contractors}
        title={"Senarai Kontraktor"}
        createTitle="Tambah"
        onCreate={() => history.push("/admin/settings/data/kontraktor/baru")}
        allowPagination
      />
    </Box>
  );
}

export default ContractorSetting;