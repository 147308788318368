import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Flex, SimpleGrid, Button } from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import TextField from "components/fields/TextField";
import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { useCreateSectionSetting } from "hooks/setting/dataSetting/sectionSetting/useCreateSectionSetting";
import { useEditSectionSetting } from "hooks/setting/dataSetting/sectionSetting/useEditSectionSetting";
import { ISectionSetting } from "entities/setting/dataSettings";

interface FormSectionSettingProps {
  action: "create" | "edit" | "show";
  initialData?: ISectionSetting;
}

const FormSectionSetting: React.FC<FormSectionSettingProps> = ({
  action,
  initialData,
}) => {
  const { createSectionSetting, isCreating } = useCreateSectionSetting();
  const { editSectionSetting, isEditing } = useEditSectionSetting();
  const history = useHistory();
  const { seksyenOps } = useOptions();

  const schema = yup
    .object({
      nama: yup.string().required(),
      id_bandar_pekan_mukim: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISectionSetting>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createSectionSetting({
        sectionSetting: data,
      });
    } else if (action === "edit" && !isEditing) {
      editSectionSetting({
        sectionSetting: data,
        id: `${data.id}`,
      });
    }
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Seksyen">
              <Flex gap="20px" justifyContent={"flex-end"} mb={"20px"}>
                <Button
                  variant="outline"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  colorScheme="gray"
                  type={action === "edit" ? "button" : "reset"}
                  onClick={() => {
                    if (action !== "create") {
                      history.push("/admin/settings/data");
                    }
                  }}
                >
                  {action !== "show" ? "Batal" : "Kembali"}
                </Button>

                {action !== "show" && (
                  <Button
                    variant="brand"
                    fontSize={"sm"}
                    fontWeight={"500"}
                    minW={"183px"}
                    type="submit"
                    isDisabled={isCreating || isEditing}
                  >
                    {action === "edit"
                      ? "Kemaskini Seksyen"
                      : "Daftar Seksyen Baru"}
                  </Button>
                )}
              </Flex>
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="nama"
                  label="Nama Seksyen"
                  placeholder="SEKSYEN 22"
                  isInvalid={!!errors.nama}
                  errorMessage={errors.nama?.message}
                  readOnly={action === "show"}
                  {...register("nama")}
                />

                <SelectField
                  label={"Seksyen"}
                  options={seksyenOps}
                  isInvalid={!!errors.id_bandar_pekan_mukim}
                  errorMessage={errors.id_bandar_pekan_mukim?.message}
                  readOnly={action === "show"}
                  {...register("id_bandar_pekan_mukim")}
                />
              </SimpleGrid>
            </Section>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormSectionSetting;
