import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Grid,
  Button,
  FormControl,
  FormLabel,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";

import Section from "components/section/Section";
import NumberGroupField from "components/fields/NumberGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";
import CreatableSelectField from "components/fields/CreatableSelectField";

import useDataFile from "hooks/setting/dataSetting/useDataFile";
import { IBaitulmalSubdivisionFormValues } from "entities/admin/baitulmalManagement";
import { useCreateBaitulmalSubdivision } from "hooks/admin/baitulmalManagement/baitulmalDevelopment/baitulmalSubdivision/useCreateBaitulmalSubdivision";
import { useEditBaitulmalSubdivision } from "hooks/admin/baitulmalManagement/baitulmalDevelopment/baitulmalSubdivision/useEditBaitulmalPartition";

import {
  formatDate,
  convertsSquareMeterToAcre,
  convertsSquareMeterToHectare,
} from "utils/helpers";

interface FormSubdivisionProps {
  action: "create" | "edit" | "show";
  baitulmalAssetId?: number;
  initialData?: IBaitulmalSubdivisionFormValues;
  assetArea?: {
    luas_hektar: number;
    luas_ekar: number;
    luas_m2: number;
    luas_kp2: number;
  };
}

const schema = yup
  .object({
    id_no_fail_mainpp: yup.string().required(),
    luas_lama_m2: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    luas_baru_m2: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    cukai_tahunan_rm: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
  })
  .required();

const FormSubdivision: React.FC<FormSubdivisionProps> = ({
  action,
  baitulmalAssetId,
  initialData,
  assetArea
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    initialData?.senarai_lampiran || [
      {
        id_senarai_lampiran: "",
        tab_name: "aset_baitulmal_pecah_sempadan",
        file_name: "",
        file_description: "",
      },
    ]
  );

  const history = useHistory();
  const {
    noFailMainppOps,
    createNoFailMainppOptions,
    isCreatingNoFailMainppOptions,
  } = useDataFile();
  const { createBaitulmalSubdivision, isCreating } = useCreateBaitulmalSubdivision();
  const { editBaitulmalSubdivision, isEditing } = useEditBaitulmalSubdivision();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IBaitulmalSubdivisionFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      luas_lama_hektar: assetArea?.luas_hektar,
      luas_lama_ektar: assetArea?.luas_ekar,
      luas_lama_m2: assetArea?.luas_m2,
      luas_lama_kp2: assetArea?.luas_kp2,
      tarikh_proses_permohonan: formatDate(initialData?.tarikh_proses_permohonan),
      tarikh_lulus_pecah_sempadan: formatDate(initialData?.tarikh_lulus_pecah_sempadan),
    }
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createBaitulmalSubdivision({
        newBaitulmalSubdivision: { 
          ...data, 
          id_aset_baitulmal: baitulmalAssetId,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files,
         },
      });
    } else if (action === "edit") {
      editBaitulmalSubdivision({
        newBaitulmalSubdivision: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                tab_name: file?.tab_name,
                file_name: file?.file_name,
                file_description: file?.file_description,
              };
            })
          ),
          lampiran: files,
        },
        id: initialData?.id,
      });
    }
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "luas_lama_m2") {
        const luasLamaHektar = convertsSquareMeterToHectare(value.luas_lama_m2);
        const luasLamaEkar = convertsSquareMeterToAcre(value.luas_lama_m2);

        setValue("luas_lama_hektar", luasLamaHektar, {
          shouldValidate: false,
        });
        setValue("luas_lama_ektar", luasLamaEkar, { shouldValidate: false });
      } else if (name === "luas_baru_m2") {
        const luasBaruHektar = convertsSquareMeterToHectare(value.luas_baru_m2);
        const luasBaruEkar = convertsSquareMeterToAcre(value.luas_baru_m2);

        setValue("luas_baru_hektar", luasBaruHektar, {
          shouldValidate: false,
        });
        setValue("luas_baru_ektar", luasBaruEkar, {
          shouldValidate: false,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <Flex mt="10px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={action === "create" ? "reset" : "button"}
            onClick={() => {
              if (action !== "create") {
                history.push("/admin/baitulmal/aset");
              }
            }}
          >
            {action === "create" ? "Batal" : "Kembali"}
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            disabled={isCreating || isEditing}
          >
            {action === "edit"
              ? "Kemaskini Pecah Sempadan"
              : "Daftar Pecah Sempadan"}
          </Button>
        </Flex>

        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <CreatableSelectField
            label={"No. Fail MAINPP"}
            defaultOptions={noFailMainppOps}
            defaultValue={initialData?.id_no_fail_mainpp}
            onChange={(value) => setValue("id_no_fail_mainpp", value)}
            onCreateOption={(value) =>
              createNoFailMainppOptions({
                newOption: { no_fail_main_pp: value },
                id: null,
              })
            }
            isLoading={isCreatingNoFailMainppOptions}
            isInvalid={!!errors.id_no_fail_mainpp}
            errorMessage={errors.id_no_fail_mainpp?.message}
            readOnly={action === "show"}
          />

          <TextField
            id="location"
            label="Lokasi"
            isInvalid={!!errors.lokasi}
            errorMessage={errors.lokasi?.message}
            readOnly={action === "show"}
            {...register("lokasi")}
          />

          <TextField
            id="no_lot_baru"
            label="No. Lot Baru"
            isInvalid={!!errors.no_lot_baru}
            errorMessage={errors.no_lot_baru?.message}
            readOnly={action === "show"}
            {...register("no_lot_baru")}
          />

          <TextField
            id="no_lot_lama"
            label="No. Lot Lama"
            isInvalid={!!errors.no_lot_lama}
            errorMessage={errors.no_lot_lama?.message}
            readOnly={action === "show"}
            {...register("no_lot_lama")}
          />

          <TextField
            id="no_pt"
            label="No. PT"
            isInvalid={!!errors.no_pt}
            errorMessage={errors.no_pt?.message}
            readOnly={action === "show"}
            {...register("no_pt")}
          />

          <TextField
            id="no_hakmilik"
            label="No. Hak Milik"
            isInvalid={!!errors.no_hakmilik}
            errorMessage={errors.no_hakmilik?.message}
            readOnly={action === "show"}
            {...register("no_hakmilik")}
          />

          <GridItem
            display={"grid"}
            colSpan={2}
            gap={{ sm: "6px", md: "18px" }}
          >
            <FormControl>
              <FormLabel variant="main" mb="8px">
                Keluasan Bahagian (Lama)
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="luas_lama_hektar"
                  label="Hektar"
                  isInvalid={!!errors.luas_lama_hektar}
                  errorMessage={errors.luas_lama_hektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_lama_hektar")}
                />

                <NumberGroupField
                  id="luas_lama_ektar"
                  label="Ekar"
                  isInvalid={!!errors.luas_lama_ektar}
                  errorMessage={errors.luas_lama_ektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_lama_ektar")}
                />

                <NumberGroupField
                  id="luas_lama_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  step="any"
                  isInvalid={!!errors.luas_lama_m2}
                  errorMessage={errors.luas_lama_m2?.message}
                  readOnly={action === "show"}
                  {...register("luas_lama_m2")}
                />
              </SimpleGrid>
            </FormControl>

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Keluasan Bahagian (Baru)
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="luas_baru_hektar"
                  label="Hektar"
                  isInvalid={!!errors.luas_baru_hektar}
                  errorMessage={errors.luas_baru_hektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_baru_hektar")}
                />

                <NumberGroupField
                  id="luas_baru_ektar"
                  label="Ekar"
                  isInvalid={!!errors.luas_baru_ektar}
                  errorMessage={errors.luas_baru_ektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_baru_ektar")}
                />

                <NumberGroupField
                  id="luas_baru_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  step="any"
                  isInvalid={!!errors.luas_baru_m2}
                  errorMessage={errors.luas_baru_m2?.message}
                  readOnly={action === "show"}
                  {...register("luas_baru_m2")}
                />
              </SimpleGrid>
            </FormControl>
          </GridItem>

          <TextField
            id="tarikh_proses_permohonan"
            label="Tarikh Proses Permohonan"
            type="date"
            isInvalid={!!errors.tarikh_proses_permohonan}
            errorMessage={errors.tarikh_proses_permohonan?.message}
            readOnly={action === "show"}
            {...register("tarikh_proses_permohonan")}
          />

          <TextField
            id="tarikh_lulus_pecah_sempadan"
            label="Tarikh Kelulusan Pecah Sempadan"
            type="date"
            isInvalid={!!errors.tarikh_lulus_pecah_sempadan}
            errorMessage={errors.tarikh_lulus_pecah_sempadan?.message}
            readOnly={action === "show"}
            {...register("tarikh_lulus_pecah_sempadan")}
          />

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Cukai Tahunan (RM)
            </FormLabel>
            <NumberGroupField
              id="cukai_tahunan_rm"
              label="RM"
              step="any"
              isInvalid={!!errors.cukai_tahunan_rm}
              errorMessage={errors.cukai_tahunan_rm?.message}
              readOnly={action === "show"}
              {...register("cukai_tahunan_rm")}
            />
          </FormControl>

          <TextField
            id="syer_bahagian"
            label="Syer Bahagian"
            isInvalid={!!errors.syer_bahagian}
            errorMessage={errors.syer_bahagian?.message}
            readOnly={action === "show"}
            {...register("syer_bahagian")}
          />

          <TextField
            id="tanah_potensi"
            label="Tanah Berpotensi Dibangunkan"
            isInvalid={!!errors.tanah_potensi}
            errorMessage={errors.tanah_potensi?.message}
            readOnly={action === "show"}
            {...register("tanah_potensi")}
          />

          <TextField
            id="catatan"
            label="Catatan"
            isInvalid={!!errors.catatan}
            errorMessage={errors.catatan?.message}
            readOnly={action === "show"}
            {...register("catatan")}
          />
        </Grid>

        <Section size="md" title="Lampiran Penjualan Tanah">
          <FileUploadField
            files={files}
            fileDescriptions={fileDescriptions}
            setFiles={setFiles}
            setFileDescriptions={setFileDescriptions}
            tabName="aset_baitulmal_pecah_sempadan"
            readOnly={action === "show"}
          />
        </Section>
      </form>
    </Flex>
  );
};

export default FormSubdivision;
