import axios from "utils/axios";
import { IWakafAssetFormValues } from "entities/admin/wakafManagement";

export async function getWakafAsset(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/aset-wakaf/${id}`,
  });
  return data;
}

export async function getWakafAssets(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/aset-wakaf",
    params
  });
  return data;
}

export async function createEditWakafAsset(
  values: IWakafAssetFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/aset-wakaf/${id}` : "/aset-wakaf";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteWakafAsset(
  id: string | number | null
) {
  const apiUrl = `/aset-wakaf/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}