import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { createEditTujuanSighahOption } from "services/setting/dataSetting/tujuanSighahSettingService";
import { ITujuanSighahSetting } from "entities/setting/dataSettings";

export function useCreateTujuanSighahSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createTujuanSighahSetting, isLoading: isCreating } = useMutation({
    mutationFn: ({tujuanSighahSetting, id}: {tujuanSighahSetting: ITujuanSighahSetting, id?: string | null}) => createEditTujuanSighahOption(tujuanSighahSetting, id),
    onSuccess: () => {
      toast({
        title: "New tujuan sighah successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["tujuanSighah"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createTujuanSighahSetting };
}