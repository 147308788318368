import React from 'react'
import CreateAcquisition from './Create'
import EditAcquisition from './Edit'
import ShowAcquisition from './Show'

import { IWakafAsset } from "entities/admin/wakafManagement";

interface TabAcquisitionProps {
  action: "create" | "edit" | "show";
  wakafAsset?: IWakafAsset;
}

const TabAcquisition = ({ action, wakafAsset }: TabAcquisitionProps) => {
  const renderComponent = () => {
    switch (action) {
      case "create":
        return <CreateAcquisition />;
      case "edit":
        return <EditAcquisition wakafAsset={wakafAsset} />;
      case "show":
        return <ShowAcquisition wakafAsset={wakafAsset} />;
      default:
        return <CreateAcquisition />;
    }
  };
  
  return <div>{renderComponent()}</div>;
};

export default TabAcquisition;

