import axios from "utils/axios";
import { IBaitulmalBuybackFormValues } from "entities/admin/baitulmalManagement";

export async function getBaitulmalBuybacks(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/mohon-beli-balik",
    params
  });
  return data;
}

export async function getBaitulmalBuyback(id: number) {
  const { data } = await axios.call({
    method: "get",
    url: `/mohon-beli-balik/${id}`,
  });
  return data;
}

export async function createEditBaitulmalBuyback(
  values: IBaitulmalBuybackFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/mohon-beli-balik/${id}` : "/mohon-beli-balik";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteBaitulmalBuyback(
  id: string | number | null
) {
  const apiUrl = `/mohon-beli-balik/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}