import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditWaqafTypeOption } from "services/setting/dataSetting/waqafTypeSettingService";
import { IWaqafTypeSetting } from "entities/setting/dataSettings";

export function useCreateWaqafTypeSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createWaqafTypeSetting, isLoading: isCreating } = useMutation({
    mutationFn: ({waqafTypeSetting, id}: {waqafTypeSetting: IWaqafTypeSetting, id?: string | null}) => createEditWaqafTypeOption(waqafTypeSetting, id),
    onSuccess: () => {
      toast({
        title: "New waqaf type successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["waqafType"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createWaqafTypeSetting };
}