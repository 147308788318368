import { extendTheme, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';
import { CardComponent } from 'theme/additions/card/card';
import { buttonStyles } from 'theme/components/button';
import { badgeStyles } from 'theme/components/badge';
import { inputStyles } from 'theme/components/input';
import { progressStyles } from 'theme/components/progress';
import { sliderStyles } from 'theme/components/slider';
import { textareaStyles } from 'theme/components/textarea';
import { switchStyles } from 'theme/components/switch';
import { linkStyles } from 'theme/components/link';
import { textStyles } from 'theme/components/text';
import { formLabelStyles } from 'theme/components/formLabel';
import { formErrorStyles } from 'theme/components/formError';
import { tabStyles } from 'theme/components/tab';
import { accordionStyles } from './components/accordion';
import { breakpoints } from 'theme/foundations/breakpoints';
import { globalStyles } from 'theme/styles';

export default extendTheme(
	{ breakpoints }, // Breakpoints
	globalStyles,
	badgeStyles, // badge styles
	buttonStyles, // button styles
	linkStyles, // link styles
	progressStyles, // progress styles
	sliderStyles, // slider styles
	inputStyles, // input styles
	textareaStyles, // textarea styles
	switchStyles, // switch styles
	textStyles, // text styles,
	formLabelStyles, // formLabel styles
	formErrorStyles, // formError styles,
	tabStyles, // tab styles
	CardComponent, // card component
	accordionStyles
);

export interface CustomCardProps extends HTMLChakraProps<'div'>, ThemingProps {}
