import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Flex, SimpleGrid, Button } from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import TextField from "components/fields/TextField";

import { useCreateContractorSetting } from "hooks/setting/dataSetting/contractorSetting/useCreateContractorSetting";
import { useEditContractorSetting } from "hooks/setting/dataSetting/contractorSetting/useEditContractorSetting";
import { IContractorSetting } from "entities/setting/dataSettings";

interface FormContractorSettingProps {
  action: "create" | "edit" | "show";
  initialData?: IContractorSetting;
}

const FormContractorSetting: React.FC<FormContractorSettingProps> = ({
  action,
  initialData,
}) => {
  const { createContractorSetting, isCreating } = useCreateContractorSetting();
  const { editContractorSetting, isEditing } = useEditContractorSetting();
  const history = useHistory();

  const schema = yup
    .object({
      nama_syarikat: yup.string().required(),
      no_ssm: yup.string(),
      nama_pegawai: yup.string(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IContractorSetting>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createContractorSetting({
        contractorSetting: data,
      });
    } else if (action === "edit" && !isEditing) {
      editContractorSetting({
        contractorSetting: data,
        id: `${data.id}`,
      });
    }
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Kontraktor">
              <Flex gap="20px" justifyContent={"flex-end"} mb={"20px"}>
                <Button
                  variant="outline"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  colorScheme="gray"
                  type={action === "edit" ? "button" : "reset"}
                  onClick={() => {
                    if (action !== "create") {
                      history.push("/admin/settings/data");
                    }
                  }}
                >
                  {action !== "show" ? "Batal" : "Kembali"}
                </Button>

                {action !== "show" && (
                  <Button
                    variant="brand"
                    fontSize={"sm"}
                    fontWeight={"500"}
                    minW={"183px"}
                    type="submit"
                    isDisabled={isCreating || isEditing}
                  >
                    {action === "edit"
                      ? "Kemaskini Kontraktor"
                      : "Daftar Kontraktor Baru"}
                  </Button>
                )}
              </Flex>
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="nama_syarikat"
                  label="Nama Kontraktor"
                  isInvalid={!!errors.nama_syarikat}
                  errorMessage={errors.nama_syarikat?.message}
                  readOnly={action === "show"}
                  {...register("nama_syarikat")}
                />

                <TextField
                  id="no_ssm"
                  label="No SSM"
                  isInvalid={!!errors.no_ssm}
                  errorMessage={errors.no_ssm?.message}
                  readOnly={action === "show"}
                  {...register("no_ssm")}
                />

                <TextField
                  id="nama_pegawai"
                  label="Nama Pegawai"
                  isInvalid={!!errors.nama_pegawai}
                  errorMessage={errors.nama_pegawai?.message}
                  readOnly={action === "show"}
                  {...register("nama_pegawai")}
                />

                <TextField
                  id="no_telefon"
                  label="No Telefon"
                  isInvalid={!!errors.no_telefon}
                  errorMessage={errors.no_telefon?.message}
                  readOnly={action === "show"}
                  {...register("no_telefon")}
                />

                <TextField
                  id="catatan"
                  label="Catatan"
                  isInvalid={!!errors.catatan}
                  errorMessage={errors.catatan?.message}
                  readOnly={action === "show"}
                  {...register("catatan")}
                />
              </SimpleGrid>
            </Section>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormContractorSetting;
