import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditJenisPermohonanWakafOption } from "services/setting/dataSetting/jenisPermohonanWakafSettingService";
import { IJenisPermohonanWakafSetting } from "entities/setting/dataSettings";

export function useCreateJenisPermohonanWakafSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createJenisPermohonanWakafSetting, isLoading: isCreating } = useMutation({
    mutationFn: ({jenisPermohonanWakafSetting, id}: {jenisPermohonanWakafSetting: IJenisPermohonanWakafSetting, id?: string | null}) => createEditJenisPermohonanWakafOption(jenisPermohonanWakafSetting, id),
    onSuccess: () => {
      toast({
        title: "New jenis permohonan wakaf successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["jenisPermohonanWakaf"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createJenisPermohonanWakafSetting };
}