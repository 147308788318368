import { Icon } from "@chakra-ui/react";
import { MdFolder } from "react-icons/md";

import WakafRegistration from "views/admin/wakafRegistration";
import CreateWakafRegistration from "views/admin/wakafRegistration/Create";
import EditWakafRegistration from "views/admin/wakafRegistration/Edit";
import ShowWakafRegistration from "views/admin/wakafRegistration/Show";
import WakafAsset from "views/admin/wakafAsset";
import WakafDevelopment from "views/admin/wakafDevelopement";
import WakafIstibdal from "views/admin/wakafIstibdal";
import WakafManagement from "views/admin/wakafManagement";

const wakafRoutes = [
  {
    name: "Wakaf",
    path: "/wakaf",
    icon: <Icon as={MdFolder} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Permohonan Wakaf",
        layout: "/admin",
        path: "/wakaf/permohonan",
        component: WakafRegistration,
      },
      {
        name: "Aset Wakaf",
        layout: "/admin",
        path: "/wakaf/aset",
        component: WakafAsset,
      },
      {
        name: "Istibdal",
        layout: "/admin",
        path: "/wakaf/istibdal",
        component: WakafIstibdal,
      },
      {
        name: "Pembangunan",
        layout: "/admin",
        path: "/wakaf/pembangunan",
        component: WakafDevelopment,
      },
    ],
  },
  {
    name: "Daftar Permohonan Wakaf Baru",
    layout: "/admin",
    path: "/wakaf/permohonan/baru",
    component: CreateWakafRegistration,
    secondary: true,
  },
  {
    name: "Kemaskini Permohonan Wakaf",
    layout: "/admin",
    path: "/wakaf/permohonan/kemaskini/:applicationId",
    component: EditWakafRegistration,
    secondary: true,
  },
  {
    name: "Maklumat Permohonan Wakaf",
    layout: "/admin",
    path: "/wakaf/permohonan/show/:applicationId",
    component: ShowWakafRegistration,
    secondary: true,
  },
  {
    name: "Daftar Aset Wakaf Baru",
    layout: "/admin",
    path: "/wakaf/aset/baru/:applicationId?",
    component: WakafManagement,
    secondary: true,
  },
  {
    name: "Maklumat Aset Wakaf",
    layout: "/admin",
    path: "/wakaf/aset/show/:assetId",
    component: WakafManagement,
    secondary: true,
  },
  {
    name: "Maklumat Aset Wakaf",
    layout: "/admin",
    path: "/wakaf/aset/kemaskini/:assetId",
    component: WakafManagement,
    secondary: true,
  },
];

export default wakafRoutes;
