import React from "react";
import { NavLink } from "react-router-dom";
import { Flex, Text, Button } from "@chakra-ui/react";

import { IWakafRegistrationFormValues } from "entities/admin/wakafRegistration";

interface AssetLinkProps {
  action: "create" | "edit" | "show";
  wakafRegistration: IWakafRegistrationFormValues;
}

const AssetLink = ({ action, wakafRegistration }: AssetLinkProps) => {
  const isCreatingNewAsset =
    action === "edit" &&
    wakafRegistration.status === 1 &&
    !wakafRegistration.id_aset_wakaf;

  const isShowingRegisteredAsset =
    action !== "create" &&
    wakafRegistration.status === 1 &&
    wakafRegistration.id_aset_wakaf;

  if (isCreatingNewAsset) {
    return (
      <Flex justifyContent={"flex-end"}>
        <NavLink to={`/admin/wakaf/aset/baru/${wakafRegistration.id}`}>
          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="button"
          >
            Daftar Aset Wakaf
          </Button>
        </NavLink>
      </Flex>
    );
  } else if (isShowingRegisteredAsset) {
    return (
      <Flex justifyContent={"flex-end"} alignItems={"center"} gap={"8px"}>
        <Text>Aset wakaf yang berdaftar: </Text>
        <Text variant="link" fontSize="sm" fontWeight="500">
          <NavLink
            to={`/admin/wakaf/aset/show/${wakafRegistration.id_aset_wakaf}`}
          >
            {wakafRegistration.id_aset_wakaf}
          </NavLink>
        </Text>
      </Flex>
    );
  }

  return null;
};

export default AssetLink;
