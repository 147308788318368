import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

import FormMaintenance from "./FormMaintenance";
import TableMaintenance from "./TableMaintenance";

interface TabMaintenanceProps {
  baitulmalAssetId?: number;
}

const TabMaintenance: React.FC<TabMaintenanceProps> = ({ baitulmalAssetId }) => {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const [baitulmalMaintenanceId, setBaitulmalMaintenanceId] = useState<number | null>(null);
  const [showFormAssessment, setShowFormAssessment] = useState(false);

  const toggleFormMaintenance = (action: "create" | "edit" | "show", baitulmalMaintenanceId?: number) => {
    setAction(action);
    setBaitulmalMaintenanceId(baitulmalMaintenanceId);
    setShowFormAssessment(true);
  };

  return (
    <Flex direction="column">
      <TableMaintenance
        baitulmalAssetId={baitulmalAssetId}
        toggleFormMaintenance={toggleFormMaintenance}
      />

      {showFormAssessment && (
        <FormMaintenance
          action={action}
          baitulmalAssetId={baitulmalAssetId}
          baitulmalMaintenanceId={baitulmalMaintenanceId}
        />
      )}
    </Flex>
  );
};

export default TabMaintenance;
