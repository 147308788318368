import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
} from "@chakra-ui/react";

import Section from "components/section/Section";
import NumberGroupField from "components/fields/NumberGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { IBaitulmalAssessmentFormValues } from "entities/admin/baitulmalManagement";
import { useCreateBaitulmalAssessment } from "hooks/admin/baitulmalManagement/baitulmalAssessment/useCreateBaitulmalAssessment";
import { useEditBaitulmalAssessment } from "hooks/admin/baitulmalManagement/baitulmalAssessment/useEditBaitulmalAssessment";
import { useBaitulmalAssessment } from "hooks/admin/baitulmalManagement/baitulmalAssessment/useBaitulmalAssessment";

import { formatDate } from "utils/helpers";

interface FormAssessmentProps {
  action: "create" | "edit" | "show";
  baitulmalAssetId?: number;
  baitulmalAssessmentId?: number;
}

const schema = yup
  .object({
    nilai_syer_bhg_rm: yup.number().positive().required(),
    bayaran_premium: yup.number().positive().required(),
    tarikh_nilaian: yup.date().required(),
  })
  .required();

const FormAssessment: React.FC<FormAssessmentProps> = ({
  action,
  baitulmalAssetId,
  baitulmalAssessmentId,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>([
    {
      id_senarai_lampiran: "",
      tab_name: "aset_baitulmal_penilaian",
      file_name: "",
      file_description: "",
    },
  ]);

  const history = useHistory();
  const { createBaitulmalAssessment, isCreating } =
    useCreateBaitulmalAssessment();
  const { editBaitulmalAssessment, isEditing } = useEditBaitulmalAssessment();
  const { baitulmalAssessment, isInitialLoading } = useBaitulmalAssessment(
    baitulmalAssessmentId
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IBaitulmalAssessmentFormValues>({
    resolver: yupResolver(schema) as any,
  });

  const renderTitle = (action: string) => {
    if (action === "create") {
      return "Tambah Rekod Penilaian";
    } else if (action === "edit") {
      return "Kemaskini Rekod Penilaian";
    } else if (action === "show") {
      return "Rekod Penilaian";
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createBaitulmalAssessment({
        newBaitulmalAssessment: {
          ...data,
          id_aset_baitulmal: baitulmalAssetId,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files,
        },
      });
    } else if (action === "edit") {
      editBaitulmalAssessment({
        newBaitulmalAssessment: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                tab_name: file?.tab_name,
                file_name: file?.file_name,
                file_description: file?.file_description,
              };
            })
          ),
          lampiran: files,
        },
        id: baitulmalAssessmentId,
      });
    }
  });

  useEffect(() => {
    const defaultValues: any = {
      nilai_syer_bhg_rm: "",
      bayaran_premium: "",
      tarikh_nilaian: null,
      senarai_lampiran: [],
    };

    if (action === "create") {
      setFileDescriptions([]);
      reset(defaultValues);
    } else if (baitulmalAssessment?.data) {
      console.log(baitulmalAssessment?.data);
      reset({
        ...baitulmalAssessment.data,
        tarikh_nilaian: formatDate(baitulmalAssessment.data.tarikh_nilaian),
      });
    }
  }, [action, baitulmalAssessment?.data, reset]);

  useEffect(() => {
    if (baitulmalAssessment?.data?.senarai_lampiran) {
      setFileDescriptions(
        baitulmalAssessment?.data?.senarai_lampiran.filter(
          (file: any) => file.tab_name === "aset_baitulmal_penilaian"
        )
      );
    }

    return () => {
      setFileDescriptions([]);
    };
  }, [baitulmalAssessment?.data]);

  if (isInitialLoading) return <FullScreenLoader />;

  return (
    <Flex px="25px" mt="10px" mb="1.5rem" flexDirection={"column"}>
      <Section size={"xl"} title={renderTitle(action)}>
        <form onSubmit={onSubmit}>
          <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
            <Button
              variant="outline"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              colorScheme="gray"
              type={action === "create" ? "reset" : "button"}
              onClick={() => {
                if (action !== "create") {
                  history.push("/admin/wakaf/aset");
                }
              }}
            >
              {action === "create" ? "Batal" : "Kembali"}
            </Button>

            <Button
              variant="brand"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              type="submit"
              disabled={isCreating || isEditing}
            >
              {action === "edit"
                ? "Kemaskini Rekod Penilaian"
                : "Simpan Rekod Penilaian"}
            </Button>
          </Flex>

          <SimpleGrid
            columns={{ base: 3 }}
            gap={{ sm: "6px", md: "18px" }}
            mb={10}
          >
            <FormControl>
              <FormLabel variant="main">Niaian Syer Bahagian (RM)</FormLabel>
              <NumberGroupField
                label="RM"
                step={"any"}
                isInvalid={!!errors.nilai_syer_bhg_rm}
                errorMessage={errors.nilai_syer_bhg_rm?.message}
                readOnly={action === "show"}
                {...register("nilai_syer_bhg_rm")}
              />
            </FormControl>

            <FormControl>
              <FormLabel variant="main">Bayaran Premium (RM)</FormLabel>
              <NumberGroupField
                label="RM"
                step={"any"}
                isInvalid={!!errors.bayaran_premium}
                errorMessage={errors.bayaran_premium?.message}
                readOnly={action === "show"}
                {...register("bayaran_premium")}
              />
            </FormControl>

            <TextField
              id="tarikh_nilaian"
              label="Tarikh Nilaian"
              type="date"
              isInvalid={!!errors.tarikh_nilaian}
              errorMessage={errors.tarikh_nilaian?.message}
              readOnly={action === "show"}
              {...register("tarikh_nilaian")}
            />
          </SimpleGrid>

          <Section size="md" title="Lampiran Penilaian">
            <FileUploadField
              files={files}
              setFiles={setFiles}
              fileDescriptions={fileDescriptions}
              setFileDescriptions={setFileDescriptions}
              tabName="aset_baitulmal_penilaian"
              readOnly={action === "show"}
            />
          </Section>
        </form>
      </Section>
    </Flex>
  );
};

export default FormAssessment;
