import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";

import Resource from "components/resource/Resource";
import Sorting from "./components/Sorting";
import tableData from "./variables/tableDataAuditTrail";

import { IAuditTrail } from "entities/setting/auditTrail";

const columnHelper = createColumnHelper<IAuditTrail>();

export default function AuditTrail() {
  const [data, setData] = useState(() => [...tableData]);

  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => <Text variant="tableHeading">ID Pengguna</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: () => <Text variant="tableHeading">Nama Pengguna</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("subject", {
      id: "subject",
      header: () => <Text variant="tableHeading">Subjek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => <Text variant="tableHeading">Keterangan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("date_updated", {
      id: "date_updated",
      header: () => <Text variant="tableHeading">Masa</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={data}
        title={"Log Aktiviti"}
        allowDownload
        onClickDownload={() => console.log}
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
