import axios from "utils/axios";
import { IDunSetting } from "entities/setting/dataSettings";

export async function getDunOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/dun",
    params,
  });
  return data;
}

export async function getDunOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/dun/${id}`,
  });
  return data;
}


export async function createEditDunOption(
  values: IDunSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/dun/${id}` : "/general/dun";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteDunOption(
  id: string | number | null
) {
  const apiUrl = `/general/dun/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

