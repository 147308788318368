import React from "react";
import FormNoFailPtgSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useNoFailPtgSetting } from "hooks/setting/dataSetting/noFailPtgSetting/useNoFailPtgSetting";

export default function EditNoFailPtgSetting() {
  const { isLoading, noFailPtg, error } = useNoFailPtgSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormNoFailPtgSetting action="edit" initialData={noFailPtg?.data} />;
}
