import React from "react";
import FormAcquisition from "./FormAcquisition";
import { IWakafAsset } from "entities/admin/wakafManagement";

interface EditAcquisitionProps {
  wakafAsset?: IWakafAsset;
}

export default function EditAcquisition({ wakafAsset }: EditAcquisitionProps) {
  return (
    <FormAcquisition
      action="edit"
      initialData={{
        id: wakafAsset?.id,
        tarikh_belian: wakafAsset?.tarikh_belian,
        deskripsi_belian: wakafAsset?.deskripsi_belian,
        id_sumber_wakaf: wakafAsset?.id_sumber_wakaf,
        harga_belian: wakafAsset?.harga_belian,
        harga_nilaian: wakafAsset?.harga_nilaian,
        senarai_lampiran: wakafAsset?.senarai_lampiran.filter((file: any) => file.tab_name === "aset_wakaf"),
      }}
    />
  );
}
