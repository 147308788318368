import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { deleteOrganizationOption } from "services/setting/dataSetting/organizationSettingService";

export function useDeleteOrganizationSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: removeOrganizationSetting, isLoading: isDeleting } = useMutation({
    mutationFn: (id: number) => deleteOrganizationOption(id),
    onSuccess: () => {
      toast({
        title: "Organization successfully deleted",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isDeleting, removeOrganizationSetting };
}

