import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getJenisPermohonanWakafOptions } from "services/setting/dataSetting/jenisPermohonanWakafSettingService";
import { PAGE_SIZE } from "utils/constants";

export function useJenisPermohonanWakafSettings(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("limit")
    ? PAGE_SIZE
    : Number(searchParams.get("limit"));

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["jenisPermohonanWakafs", currentPage, currentLimit],
      queryFn: () =>
        getJenisPermohonanWakafOptions({ ...params, page: currentPage, limit: currentLimit }),
    });
  }, [currentPage, currentLimit, params, queryClient]);

  const { isLoading, data: jenisPermohonanWakafs } = useQuery({
    queryKey: ["jenisPermohonanWakafs", currentPage, currentLimit],
    queryFn: () =>
      getJenisPermohonanWakafOptions({ ...params, page: currentPage, limit: currentLimit }),
  });

  // OUTPUT
  return {
    jenisPermohonanWakafs,
    isLoading,
  };
}
