import axios from "utils/axios";
import { IWakafRegistrationFormValues } from "entities/admin/wakafRegistration";

export async function getWakafRegistration(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/mohon-wakaf/${id}`,
  });
  return data;
}

export async function getWakafRegistrations(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/mohon-wakaf",
    params
  });
  return data;
}

export async function createEditWakafRegistration(
  values: IWakafRegistrationFormValues,
  id: string | null
) {
  const apiUrl = id ? `/mohon-wakaf/${id}` : "/mohon-wakaf";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteWakafRegistration(
  id: string | number | null
) {
  const apiUrl = `/mohon-wakaf/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}