import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";

import useOptions from "hooks/setting/dataSetting/useOptions";

import { IUserFormValues } from "entities/setting/user";
import { useCreateUser } from "hooks/setting/user/useCreateUser";
import { useEditUser } from "hooks/setting/user/useEditUser";

interface FormUserProps {
  action: "create" | "edit" | "show";
  initialData?: IUserFormValues;
}

const FormUser: React.FC<FormUserProps> = ({ action, initialData }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { createUser, isCreating } = useCreateUser();
  const { editUser, isEditing } = useEditUser();

  const history = useHistory();
  const { roleOps, jabatanOps, unitOps, userStatusOps } = useOptions();

  const schema = yup
    .object({
      name: yup.string().required(),
      email: yup.string().email().required(),
      password:
        action === "create"
          ? yup.string().required()
          : yup.string().notRequired(),
      role_id: yup.string().required(),
      id_jabatan: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IUserFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createUser({
        newUser: {
          ...data,
          status: 1,
        },
        id: null,
      });
    } else if (action === "edit" && !isEditing) {
      editUser({
        newUser: {
          ...data,
          email: undefined,
        },
        id: `${data.id}`,
      });
    }
  });

  useEffect(() => {
    if (initialData?.status) {
      setValue("status", initialData?.status?.value);
    }
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Flex gap="20px" justifyContent={"flex-end"} mb={"20px"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={action === "edit" ? "button" : "reset"}
                onClick={() => {
                  if (action !== "create") {
                    history.push("/admin/settings/users");
                  }
                }}
              >
                {action !== "show" ? "Batal" : "Kembali"}
              </Button>

              {action !== "show" && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit"
                >
                  {action === "edit"
                    ? "Kemaskini Pengguna"
                    : "Daftar Pengguna Baru"}
                </Button>
              )}
            </Flex>

            <Section title="Maklumat Pengguna">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                {action !== "create" && (
                  <SelectField
                    label={"Status Pengguna"}
                    options={userStatusOps}
                    isInvalid={!!errors.status}
                    errorMessage={errors.status?.message}
                    readOnly={action === "show"}
                    {...register("status")}
                  />
                )}

                <TextField
                  id="name"
                  label="Nama Pengguna"
                  placeholder="Ramli Saad"
                  isInvalid={!!errors.name}
                  errorMessage={errors.name?.message}
                  readOnly={action === "show"}
                  autoComplete="nope"
                  {...register("name")}
                />

                {action !== "edit" && (
                  <TextField
                    id="email"
                    label="Emel"
                    isInvalid={!!errors.email}
                    errorMessage={errors.email?.message}
                    readOnly={action === "show"}
                    {...register("email")}
                  />
                )}

                <SelectField
                  label={"Peranan"}
                  options={roleOps.filter((r: any) => r.label !== "Pengguna Awam")}
                  isInvalid={!!errors.role_id}
                  errorMessage={errors.role_id?.message}
                  readOnly={action === "show"}
                  {...register("role_id")}
                />

                <SelectField
                  label={"Bahagian"}
                  options={jabatanOps}
                  isInvalid={!!errors.id_jabatan}
                  errorMessage={errors.id_jabatan?.message}
                  readOnly={action === "show"}
                  {...register("id_jabatan")}
                />

                <SelectField
                  label={"Unit"}
                  options={unitOps}
                  isInvalid={!!errors.id_unit}
                  errorMessage={errors.id_unit?.message}
                  readOnly={action === "show"}
                  {...register("id_unit")}
                />
              </SimpleGrid>
            </Section>

            {action !== "show" && (
              <Section title="Maklumat Kata Laluan">
                <SimpleGrid
                  columns={{ base: 2 }}
                  gap={{ sm: "6px", md: "18px" }}
                  mb={10}
                >
                  <TextField
                    id="password"
                    label="Kata Laluan"
                    placeholder="Min. 5 characters"
                    type="password"
                    showPassword={showPassword}
                    toggleShowPassword={toggleShowPassword}
                    isInvalid={!!errors.password}
                    errorMessage={errors.password?.message}
                    autoComplete="new-password"
                    {...register("password")}
                  />

                  <FormControl>
                    <FormLabel
                      variant="main"
                      mb="8px"
                      htmlFor={"password_confirm"}
                    >
                      Pengesahan Kata Laluan
                    </FormLabel>

                    <InputGroup size="md">
                      <Input
                        id={"password_confirm"}
                        variant={"auth"}
                        fontSize={"sm"}
                        ms={{ base: "0px", md: "0px" }}
                        type={"text"}
                        placeholder={"Sahkan Kata Laluan"}
                        fontWeight={"500"}
                        size={"md"}
                      />
                    </InputGroup>

                    {false && (
                      <FormErrorMessage>
                        Kata laluan tidak sama
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </SimpleGrid>
              </Section>
            )}
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormUser;
