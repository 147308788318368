import React from "react";
import FormParliamentSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useParliamentSetting } from "hooks/setting/dataSetting/parliamentSetting/useParliamentSetting";

export default function EditParliamentSetting() {
  const { isLoading, parliament, error } = useParliamentSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormParliamentSetting action="edit" initialData={parliament?.data} />;
}
