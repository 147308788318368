import React from "react";
import FormTujuanSighahSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useTujuanSighahSetting } from "hooks/setting/dataSetting/tujuanSighahSetting/useTujuanSighahSetting";

export default function EditTujuanSighahSetting() {
  const { isLoading, tujuanSighah, error } = useTujuanSighahSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormTujuanSighahSetting action="edit" initialData={tujuanSighah?.data} />;
}
