import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditJenisPermohonanWakafOption } from "services/setting/dataSetting/jenisPermohonanWakafSettingService";
import { IJenisPermohonanWakafSetting } from "entities/setting/dataSettings";

export function useEditJenisPermohonanWakafSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: editJenisPermohonanWakafSetting, isLoading: isEditing } = useMutation({
    mutationFn: ({
      jenisPermohonanWakafSetting,
      id,
    }: {
      jenisPermohonanWakafSetting: IJenisPermohonanWakafSetting;
      id: string | null;
    }) => createEditJenisPermohonanWakafOption(jenisPermohonanWakafSetting, id),
    onSuccess: () => {
      toast({
        title: "Jenis permohonan wakaf successfully updated",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["jenisPermohonanWakaf"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isEditing, editJenisPermohonanWakafSetting };
}
