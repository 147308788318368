import axios from "utils/axios";

export async function getAttachmentNameOptions() {
  const { data } = await axios.call({
    method: "get",
    url: "/general/senaraiLampiran/options",
  });
  return data;
}

export async function getAttachmentNameListOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/senaraiLampiran",
    params,
  });
  return data;
}

export async function getAttachmentNameOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/senaraiLampiran/${id}`,
  });
  return data;
}

export async function createEditAttachmentNameOptions(
  values: {nama: string},
  id: string | null
) {
  const apiUrl = id ? `/general/senaraiLampiran/${id}` : "/general/senaraiLampiran";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}

export async function deleteAttachmentNameOption(
  id: string | number | null
) {
  const apiUrl = `/general/senaraiLampiran/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

