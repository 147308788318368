import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Flex, SimpleGrid, Button } from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import TextField from "components/fields/TextField";

import { useCreateJenisPermohonanWakafSetting } from "hooks/setting/dataSetting/jenisPermohonanWakafSetting/unitSetting/useCreateJenisPermohonanWakafSetting";
import { useEditJenisPermohonanWakafSetting } from "hooks/setting/dataSetting/jenisPermohonanWakafSetting/unitSetting/useEditJenisPermohonanWakafSetting";
import { IJenisPermohonanWakafSetting } from "entities/setting/dataSettings";

interface FormJenisPermohonanWakafSettingProps {
  action: "create" | "edit" | "show";
  initialData?: IJenisPermohonanWakafSetting;
}

const FormJenisPermohonanWakafSetting: React.FC<FormJenisPermohonanWakafSettingProps> = ({
  action,
  initialData,
}) => {
  const { createJenisPermohonanWakafSetting, isCreating } = useCreateJenisPermohonanWakafSetting();
  const { editJenisPermohonanWakafSetting, isEditing } = useEditJenisPermohonanWakafSetting();
  const history = useHistory();

  const schema = yup
    .object({
      nama: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IJenisPermohonanWakafSetting>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createJenisPermohonanWakafSetting({
        jenisPermohonanWakafSetting: data,
      });
    } else if (action === "edit" && !isEditing) {
      editJenisPermohonanWakafSetting({
        jenisPermohonanWakafSetting: data,
        id: `${data.id}`,
      });
    }
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Jenis Permohonan Wakaf">
              <Flex gap="20px" justifyContent={"flex-end"} mb={"20px"}>
                <Button
                  variant="outline"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  colorScheme="gray"
                  type={action === "edit" ? "button" : "reset"}
                  onClick={() => {
                    if (action !== "create") {
                      history.push("/admin/settings/data");
                    }
                  }}
                >
                  {action !== "show" ? "Batal" : "Kembali"}
                </Button>

                {action !== "show" && (
                  <Button
                    variant="brand"
                    fontSize={"sm"}
                    fontWeight={"500"}
                    minW={"183px"}
                    type="submit"
                    isDisabled={isCreating || isEditing}
                  >
                    {action === "edit"
                      ? "Kemaskini Jenis Permohonan Wakaf"
                      : "Daftar Jenis Permohonan Wakaf Baru"}
                  </Button>
                )}
              </Flex>
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="nama"
                  label="Jenis Permohonan Wakaf"
                  isInvalid={!!errors.nama}
                  errorMessage={errors.nama?.message}
                  readOnly={action === "show"}
                  {...register("nama")}
                />
              </SimpleGrid>
            </Section>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormJenisPermohonanWakafSetting;
