import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Flex, SimpleGrid, Button } from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import TextField from "components/fields/TextField";

import { useCreateKategoriGunatanahSetting } from "hooks/setting/dataSetting/kategoriGunatanahSetting/useCreateKategoriGunatanahSetting";
import { useEditKategoriGunatanahSetting } from "hooks/setting/dataSetting/kategoriGunatanahSetting/useEditKategoriGunatanahSetting";
import { IKategoriGunatanahSetting } from "entities/setting/dataSettings";

interface FormKategoriGunatanahSettingProps {
  action: "create" | "edit" | "show";
  initialData?: IKategoriGunatanahSetting;
}

const FormKategoriGunatanahSetting: React.FC<
  FormKategoriGunatanahSettingProps
> = ({ action, initialData }) => {
  const { createKategoriGunatanahSetting, isCreating } =
    useCreateKategoriGunatanahSetting();
  const { editKategoriGunatanahSetting, isEditing } =
    useEditKategoriGunatanahSetting();
  const history = useHistory();

  const schema = yup
    .object({
      nama: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IKategoriGunatanahSetting>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createKategoriGunatanahSetting({
        kategoriGunatanahSetting: data,
      });
    } else if (action === "edit" && !isEditing) {
      editKategoriGunatanahSetting({
        kategoriGunatanahSetting: data,
        id: `${data.id}`,
      });
    }
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Kategori Gunatanah Geran">
              <Flex gap="20px" justifyContent={"flex-end"} mb={"20px"}>
                <Button
                  variant="outline"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  colorScheme="gray"
                  type={action === "edit" ? "button" : "reset"}
                  onClick={() => {
                    if (action !== "create") {
                      history.push("/admin/settings/data");
                    }
                  }}
                >
                  {action !== "show" ? "Batal" : "Kembali"}
                </Button>

                {action !== "show" && (
                  <Button
                    variant="brand"
                    fontSize={"sm"}
                    fontWeight={"500"}
                    minW={"183px"}
                    type="submit"
                    isDisabled={isCreating || isEditing}
                  >
                    {action === "edit"
                      ? "Kemaskini Kategori Gunatanah Geran"
                      : "Daftar Kategori Gunatanah Geran Baru"}
                  </Button>
                )}
              </Flex>
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="nama"
                  label="Nama Kategori Gunatanah Geran"
                  isInvalid={!!errors.nama}
                  errorMessage={errors.nama?.message}
                  readOnly={action === "show"}
                  {...register("nama")}
                />
              </SimpleGrid>
            </Section>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormKategoriGunatanahSetting;
