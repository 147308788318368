import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

import FormTax from "./FormTax";
import TableTax from "./TableTax";

interface TabTaxProps {
  wakafAssetId?: number;
}

export default function TabTax({ wakafAssetId }: TabTaxProps) {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const [wakafTaxId, setWakafTaxId] = useState<number | null>(null);
  const [showFormTax, setShowFormTax] = useState(false);

  const toggleFormTax = (action: "create" | "edit" | "show", wakafTaxId?: number) => {
    setAction(action);
    setWakafTaxId(wakafTaxId);
    setShowFormTax(true);
  };

  const toggleCloseFormTax = () => {
    setShowFormTax(false);
  };

  return (
    <Flex direction="column">
      <TableTax
        wakafAssetId={wakafAssetId}
        toggleFormTax={toggleFormTax}
      />

      {showFormTax && (
        <FormTax
          action={action}
          wakafAssetId={wakafAssetId}
          wakafTaxId={wakafTaxId}
          toggleCloseFormTax={toggleCloseFormTax}
        />
      )}
    </Flex>
  );
}
