import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete } from "react-icons/md";

import Resource from "components/resource/Resource";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { IBuildingNoSetting } from "entities/setting/dataSettings";
import { useBuildingNoSettings } from "hooks/setting/dataSetting/buildingNoSetting/useBuildingNoSettings";
import { useDeleteBuildingNoSetting } from "hooks/setting/dataSetting/buildingNoSetting/useDeleteBuildingNoSetting";

const columnHelper = createColumnHelper<IBuildingNoSetting>();

const BuildingNoSetting = () => {
  const { buildingNos, isLoading } = useBuildingNoSettings();
  const { removeBuildingNoSetting, isDeleting } = useDeleteBuildingNoSetting();
  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama", {
      header: () => <Text variant="tableHeading">Nama</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("updated_at", {
      header: () => <Text variant="tableHeading">Tarikh Kemaskini</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <NavLink to={`/admin/settings/data/nobangunan/kemaskini/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <Link
            as={"button"}
            onClick={() => {
              removeBuildingNoSetting(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={buildingNos}
        title={"Senarai No. Bangunan"}
        createTitle="Tambah"
        onCreate={() => history.push("/admin/settings/data/nobangunan/baru")}
        allowPagination
      />
    </Box>
  );
}

export default BuildingNoSetting;