import React from "react";
import FormKariahSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useKariahSetting } from "hooks/setting/dataSetting/kariahSetting/useKariahSetting";

export default function EditKariahSetting() {
  const { isLoading, kariah, error } = useKariahSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormKariahSetting action="edit" initialData={kariah?.data} />;
}
