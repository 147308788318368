import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getWakafAsset } from "services/admin/wakafManagement/wakafAssetService";

export function useWakafAsset() {
  const { assetId } = useParams<{ assetId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: wakafAsset,
    error,
  } = useQuery({
    queryKey: ["wakafAsset", assetId],
    queryFn: () => getWakafAsset(assetId),
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: "always",
    enabled: !!assetId,
  });

  return { isLoading, isInitialLoading, error, wakafAsset };
}

