import React from "react";
import FormAttachmentSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useAttachmentSetting } from "hooks/setting/dataSetting/attachmentSetting/useAttachmentSetting";

export default function EditAttachmentSetting() {
  const { isLoading, attachment, error } = useAttachmentSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormAttachmentSetting action="edit" initialData={attachment?.data} />;
}
