import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { createEditSectionOption } from "services/setting/dataSetting/sectionSettingService";
import { ISectionSetting } from "entities/setting/dataSettings";

export function useCreateSectionSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createSectionSetting, isLoading: isCreating } = useMutation({
    mutationFn: ({sectionSetting, id}: {sectionSetting: ISectionSetting, id?: string | null}) => createEditSectionOption(sectionSetting, id),
    onSuccess: () => {
      toast({
        title: "New section successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["section"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createSectionSetting };
}