import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete } from "react-icons/md";

import Resource from "components/resource/Resource";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { IDistrictSetting } from "entities/setting/dataSettings";
import { useDistrictSettings } from "hooks/setting/dataSetting/districtSetting/useDistrictSettings";
import { useDeleteDistrictSetting } from "hooks/setting/dataSetting/districtSetting/useDeleteDistrictSetting";

import { fetchLabel } from "utils/helpers";

const columnHelper = createColumnHelper<IDistrictSetting>();

export default function DistrictSetting() {
  const { districts, isLoading } = useDistrictSettings();
  const { removeDistrictSetting, isDeleting } = useDeleteDistrictSetting();
  const history = useHistory();
  const { negeriOps } = useOptions();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID Daerah</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama", {
      header: () => <Text variant="tableHeading">Nama</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id_negeri", {
      header: () => <Text variant="tableHeading">Negeri</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), negeriOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("updated_at", {
      header: () => <Text variant="tableHeading">Tarikh Kemaskini</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <NavLink to={`/admin/settings/data/daerah/kemaskini/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <Link
            as={"button"}
            onClick={() => {
              removeDistrictSetting(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={districts}
        title={"Senarai Daerah"}
        createTitle="Tambah"
        onCreate={() => history.push("/admin/settings/data/daerah/baru")}
        allowPagination
      />
    </Box>
  );
}
