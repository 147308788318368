import axios from "utils/axios";
import { IReportSetting } from "entities/setting/dataSettings";

export async function getReportOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jenisAduan",
    params,
  });
  return data;
}

export async function getReportOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/jenisAduan/${id}`,
  });
  return data;
}


export async function createEditReportOption(
  values: IReportSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/jenisAduan/${id}` : "/general/jenisAduan";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteReportOption(
  id: string | number | null
) {
  const apiUrl = `/general/jenisAduan/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

