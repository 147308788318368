import mainRoutes from "navigation/mappings/mainRoutes";
import wakafRoutes from "navigation/mappings/wakafRoutes";
import baitulmalRoutes from "navigation/mappings/baitulmalRoutes";
import settingsRoutes from "navigation/mappings/settingsRoutes";
import reportRoutes from "navigation/mappings/reportRoutes";

const routes = [
  ...mainRoutes,
  ...wakafRoutes,
  ...baitulmalRoutes,
  ...settingsRoutes,
  // ...reportRoutes,
];

export default routes;
