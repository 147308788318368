export const formErrorStyles = {
  components: {
    FormErrorMessage: {
      baseStyle: {
				mt: 1,
        color: "red.600",
        fontSize: 'sm',
			},
    },
  },
};
