import React from "react";
import FormJenisAsetBaitulmalSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useJenisAsetBaitulmalSetting } from "hooks/setting/dataSetting/jenisAsetBaitulmalSetting/useJenisAsetBaitulmalSetting";

export default function EditJenisAsetBaitulmalSetting() {
  const { isLoading, jenisAsetBaitulmal, error } = useJenisAsetBaitulmalSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormJenisAsetBaitulmalSetting action="edit" initialData={jenisAsetBaitulmal?.data} />;
}
