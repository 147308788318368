export const tabStyles = {
  components: {
    Tabs: {
      baseStyle: {
        tab: {
          fontWeight: "normal", 
          fontSize: "sm",
          _selected: {
            fontWeight: "bold",
            bg: "none",
          },
          _focus: {
            boxShadow: 'none'
          },
          _active: {
            boxShadow: 'none'
          },
          _disabled: {
            opacity: 0.5
          }
        },
        
      },
      variants: {
        main: (props: any) => ({
          tab: {
            border: "none", 
            _selected: {
              borderBottom: "2px solid", 
              borderColor: "brand.500"
            },
          },
          tablist: {
            marginX: "30px",
            borderBottom: "none", // Light gray line below all tabs
          },
          tabpanel: {
            padding: "16px", // Assuming you want some padding in the tab panel
          },
        }),
      },
    },
  },
};
