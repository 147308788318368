import { Flex, Text, Box, Image } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import logoMajlis from 'assets/img/layout/logoMajlis.png';

export function SidebarBrand() {
  return (
    <Flex alignItems="center" flexDirection="column">
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        ml={{ base: 0, md: "-26px" }}
        my="32px"
      >
        <Image src={logoMajlis} width={"120px"} alt="Dan Abramov" />
        <Text color={"secondaryGray.900"} fontSize="xl" fontWeight="600">
          eHartanah
        </Text>
        <Text
          color={"secondaryGray.900"}
          fontSize={"sm"}
          fontWeight={"400"}
          maxW={"200px"}
          textAlign={"center"}
        >
          MAJLIS AGAMA ISLAM NEGERI PULAU PINANG
        </Text>
      </Box>

      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
