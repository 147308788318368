import React from "react";
import { Box, Text, List, Icon, Badge } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import Resource from "components/resource/Resource";
import Sorting from "./components/Sorting";

import { useUsers } from "hooks/setting/user/useUsers";
import useOptions from "hooks/setting/dataSetting/useOptions";
import { fetchLabel } from "utils/helpers";
import { IUserTable } from "entities/setting/user";

const columnHelper = createColumnHelper<IUserTable>();

const getStatusDescription = (status: number) => {
  switch (status) {
    case -1:
      return <Badge colorScheme="red">Senarai Hitam</Badge>;
    case 1:
      return <Badge colorScheme="green">Aktif</Badge>;
    case 2:
      return <Badge colorScheme="yellow">Tidak Aktif</Badge>;
    default:
      return <Badge colorScheme="green">Aktif</Badge>;
  }
};

export default function User() {
  const { isLoading, error, users } = useUsers();
  const history = useHistory();
  const { roleOps, jabatanOps, unitOps } = useOptions();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID Pengguna</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("name", {
      header: () => <Text variant="tableHeading">Nama</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("role_id", {
      header: () => <Text variant="tableHeading">Peranan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), roleOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_jabatan", {
      header: () => <Text variant="tableHeading">Jabatan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), jabatanOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_unit", {
      header: () => <Text variant="tableHeading">Unit</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), unitOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => <Text variant="tableHeading">Emel</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("status.value", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {getStatusDescription(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <NavLink
            to={`/admin/settings/users/kemaskini/${info.row.original.id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink to={`/admin/settings/users/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>

          {/* <Link>
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link> */}
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={users}
        title={"Senarai Pengguna"}
        createTitle="Daftar Pengguna"
        onCreate={() => history.push("/admin/settings/users/baru")}
        allowDownload
        onClickDownload={() => console.log}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
