import React from "react";
import FormKegunaanTanahSemasaSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useKegunaanTanahSemasaSetting } from "hooks/setting/dataSetting/kegunaanTanahSemasaSetting/useKegunaanTanahSemasaSetting";

export default function EditKegunaanTanahSemasaSetting() {
  const { isLoading, kegunaanTanahSemasa, error } = useKegunaanTanahSemasaSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormKegunaanTanahSemasaSetting action="edit" initialData={kegunaanTanahSemasa?.data} />;
}
