import React, { useState, useCallback } from "react";
import debounce from 'lodash.debounce';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  List,
  ListItem,
  Box,
} from "@chakra-ui/react";
import { useWakafAssets } from "hooks/admin/wakafManagement/wakafAsset/useWakafAssets";
import { IWakafAsset } from "entities/admin/wakafManagement";

interface SearchAssetIdFieldProps {
  defaultValue: string | number;
  onChange: (value: string) => void;
}

const SearchAssetIdField = ({ defaultValue, onChange }: SearchAssetIdFieldProps) => {
  const [searchQuery, setSearchQuery] = useState(defaultValue);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const { wakafAssets } = useWakafAssets();

  const debouncedSearch = useCallback(
    debounce((searchWord: string) => {
      if (wakafAssets?.data) {
        const results = wakafAssets.data
          .filter((asset: IWakafAsset) => asset.id.toString().includes(searchWord))
          .map((asset: IWakafAsset) => asset.id.toString());
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    }, 300),
    [wakafAssets]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
    debouncedSearch(value);
    onChange(value);
  };

  const handleResultClick = (result: string) => {
    setSearchQuery(result);
    setSearchResults([]);
    onChange(result);
  };

  return (
    <FormControl position="relative">
      <FormLabel htmlFor="assetSearch" variant="main" mb="8px">
        Paut Aset Wakaf Baru
      </FormLabel>

      <InputGroup size="md">
        <Input
          id="assetSearch"
          variant="main"
          type="text"
          placeholder="Cari paut aset wakaf baru"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </InputGroup>

      {searchResults.length > 0 && (
        <Box
          position="absolute"
          top="100%"
          left="0"
          right="0"
          bg="white"
          boxShadow="md"
          borderRadius="md"
          mt="2"
          zIndex="1"
          maxHeight="200px"
          overflowY="auto"
        >
          <List id="search-results" spacing={2} role="listbox">
            {searchResults.map((result, index) => (
              <ListItem
                key={index}
                p="2"
                fontSize="sm"
                borderBottomWidth={index !== searchResults.length - 1 ? "1px" : "0"}
                _hover={{ backgroundColor: "gray.100", cursor: "pointer" }}
                onClick={() => handleResultClick(result)}
                role="option"
                aria-selected={result === searchQuery}
              >
                {result}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </FormControl>
  );
};

export default SearchAssetIdField;