import React from "react";
import { NavLink } from "react-router-dom";
import { Flex, Text, Button } from "@chakra-ui/react";

import { IBaitulmalRegistrationFormValues } from "entities/admin/baitulmalRegistration";

interface AssetLinkProps {
  action: "create" | "edit" | "show";
  baitulmalRegistration: IBaitulmalRegistrationFormValues;
}

const AssetLink = ({ action, baitulmalRegistration }: AssetLinkProps) => {
  const isCreatingNewAsset =
    action === "edit" &&
    baitulmalRegistration.status === 1 &&
    !baitulmalRegistration.id_aset_baitulmal;

  const isShowingRegisteredAsset =
    action !== "create" &&
    baitulmalRegistration.status === 1 &&
    baitulmalRegistration.id_aset_baitulmal;

  if (isCreatingNewAsset) {
    return (
      <Flex justifyContent={"flex-end"} px="4px">
        <NavLink to={`/admin/baitulmal/aset/baru/${baitulmalRegistration.id}`}>
          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="button"
          >
            Daftar Aset Baitulmal
          </Button>
        </NavLink>
      </Flex>
    );
  } else if (isShowingRegisteredAsset) {
    return (
      <Flex
        justifyContent={"flex-end"}
        alignItems={"center"}
        gap={"8px"}
        px="4px"
      >
        <Text>Aset baitulmal yang berdaftar: </Text>
        <Text variant="link" fontSize="sm" fontWeight="500">
          <NavLink
            to={`/admin/baitulmal/aset/show/${baitulmalRegistration.id_aset_baitulmal}`}
          >
            {baitulmalRegistration.id_aset_baitulmal}
          </NavLink>
        </Text>
      </Flex>
    );
  }

  return null;
};

export default AssetLink;
