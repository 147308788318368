export const accordionStyles = {
  components: {
    Accordion: {
      baseStyle: {
        button: {
          _focus: {
            boxShadow: 'none'
          },
          _active: {
            boxShadow: 'none'
          }
        },
      },
      variants: {
        main: (props: any) => ({
          container: {
            border: "none", 
          },
          button: {
            borderTop: "none",
            borderBottom: "1px solid",
            borderColor: "gray.200",
            p: "18px 0px 18px 0px",
          },
          panel:{
            p: "18px 0px 10px 0px"
          },
          icon: {
            color: "gray.500", // Assuming you want some padding in the tab panel
          },
        }),
      },
    },
  },
};
