import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditBaitulmalPartition } from "services/admin/baitulmalManagement/baitulmalDevelopmentService";
import { IBaitulmalPartitionFormValues } from "entities/admin/baitulmalManagement";

export function useCreateBaitulmalPartition() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createBaitulmalPartition, isLoading: isCreating } = useMutation({
    mutationFn: ({newBaitulmalPartition, id}: {newBaitulmalPartition: IBaitulmalPartitionFormValues, id?: number | null}) => createEditBaitulmalPartition(newBaitulmalPartition, id),
    onSuccess: () => {
      toast({
        title: "New baitulmal partition successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["baitulmalPartition"] });
      history.push("/admin/baitulmal/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createBaitulmalPartition };
}

