import { IAuditTrail } from "entities/setting/auditTrail";

const tableDataAuditTrail: IAuditTrail[] = [
  {
    id: 2,
    name: "Hasyim bin Malik",
    subject: "Admin",
    description: "Daftar Pengguna",
    date_updated: "2024-02-01",
  },
  {
    id: 3,
    name: "Hasyim bin Malik",
    subject: "Admin",
    description: "Padam Pengguna",
    date_updated: "2024-02-01",
  },
  {
    id: 4,
    name: "Hasyim bin Malik",
    subject: "Admin",
    description: "Tukar Kata Laluan Pengguna",
    date_updated: "2024-02-01",
  },
];

export default tableDataAuditTrail;
