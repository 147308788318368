import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";

interface PrivateRouteProps {
  path: string;
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  component: Component,
  ...rest
}) => {
  const auth = useAuth();
  const token = auth.getAuthToken();

  return token ? (
    <Route {...rest} path={path} render={(props) => <Component {...props} />} />
  ) : (
    <Redirect to="/auth/sign-in" />
  );
};

export default PrivateRoute;
