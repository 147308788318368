import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

import FormHistory from "./FormHistory";
import TableHistory from "./TableHistory";

interface TabHistoryProps {
  baitulmalAssetId?: number;
}

export default function TabHistory({ baitulmalAssetId }: TabHistoryProps) {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const [baitulmalHistoryId, setBaitulmalHistoryId] = useState<number | null>(null);
  const [showFormHistory, setShowFormHistory] = useState(false);

  const toggleFormHistory = (action: "create" | "edit" | "show", baitulmalHistoryId?: number) => {
    setAction(action);
    setBaitulmalHistoryId(baitulmalHistoryId);
    setShowFormHistory(true);
  };

  return (
    <Flex direction="column">
      <TableHistory
        baitulmalAssetId={baitulmalAssetId}
        toggleFormHistory={toggleFormHistory}
      />

      {showFormHistory && (
        <FormHistory
          action={action}
          baitulmalAssetId={baitulmalAssetId}
          baitulmalHistoryId={baitulmalHistoryId}
        />
      )}
    </Flex>
  );
}
