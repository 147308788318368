import React from "react";
import {
  Button,
  FormControl,
  SimpleGrid,
} from "@chakra-ui/react";

import TextField from "components/fields/TextField";

export default function Sorting() {
  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <TextField id="name" label="Nama Pengguna" />

        <TextField id="date" label="Tarikh" type="date" />
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
      >
        Cari
      </Button>
    </FormControl>
  );
}
