import React from "react";
import FormStateSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useStateSetting } from "hooks/setting/dataSetting/stateSetting/useStateSetting";

export default function EditStateSetting() {
  const { isLoading, error, negeri } = useStateSetting();
  
  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormStateSetting action="edit" initialData={negeri?.data} />;
}
