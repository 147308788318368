import React from "react";
import { Text, Link, List, Icon } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import Resource from "components/resource/Resource";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { useWakafAssessments } from "hooks/admin/wakafManagement/wakafAssessment/useWakafAssessments";
import { useDeleteWakafAssessment } from "hooks/admin/wakafManagement/wakafAssessment/useDeleteWakafAssessment";

import { IWakafAssessmentTable } from "entities/admin/wakafManagement";

import { formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<IWakafAssessmentTable>();

interface TabAssessmentProps {
  wakafAssetId?: number;
  toggleFormAssessment: (
    action: "create" | "edit" | "show",
    wakafAssessmentId?: number
  ) => void;
}

const TableAssessment = ({
  wakafAssetId,
  toggleFormAssessment,
}: TabAssessmentProps) => {
  const { wakafAssessments, isLoading } = useWakafAssessments({
    idAsetWakaf: wakafAssetId,
  });
  const { removeWakafAssessment, isDeleting } = useDeleteWakafAssessment();

  const columns = [
    columnHelper.accessor("updated_at", {
      header: () => <Text variant="tableHeading">Tarikh Kemaskini</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("nilai_syer_bhg_rm", {
      header: () => <Text variant="tableHeading">Nilai Syer Bahagian (RM)</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_nilaian", {
      header: () => <Text variant="tableHeading">Tarikh Nilai</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <Link
            as={"button"}
            onClick={() => {
              toggleFormAssessment("edit", info.row.original.id);
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </Link>

          <Link
            as={"button"}
            onClick={() => {
              toggleFormAssessment("show", info.row.original.id);
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </Link>

          <Link
            as={"button"}
            onClick={() => {
              removeWakafAssessment(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Resource
      columns={columns}
      data={wakafAssessments}
      title="Senarai Rekod Penilaian"
      createTitle="Tambah Rekod Penilaian"
      onCreate={() => toggleFormAssessment("create")}
      allowDownload
      onClickDownload={() => console.log("Download")}
    />
  );
};

export default TableAssessment;
