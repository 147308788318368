import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { deleteSectionOption } from "services/setting/dataSetting/sectionSettingService";

export function useDeleteSectionSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: removeSectionSetting, isLoading: isDeleting } = useMutation({
    mutationFn: (id: number) => deleteSectionOption(id),
    onSuccess: () => {
      toast({
        title: "Section successfully deleted",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["sections"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isDeleting, removeSectionSetting };
}

