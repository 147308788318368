import React from "react";
import FormProfile from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useCurrentUser } from "hooks/setting/user/useCurrentUser";

export default function Profile() {
  const { currentUser, isLoading } = useCurrentUser();

  if (isLoading) {
    return <FullScreenLoader />
  }

  return <FormProfile initialData={currentUser?.data} />;
}
