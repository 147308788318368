import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getPerolehWakafOption } from "services/setting/dataSetting/perolehWakafSettingService";

export function usePerolehWakafSetting() {
  const { perolehWakafId } = useParams<{ perolehWakafId: string }>();
  const toast = useToast();

  const {
    isLoading,
    data: perolehWakaf,
    error,
  } = useQuery({
    queryKey: ["perolehWakaf", perolehWakafId],
    queryFn: () => getPerolehWakafOption(perolehWakafId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: "always",
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isLoading, error, perolehWakaf };
}

