import axios from "utils/axios";
import { IContractorSetting } from "entities/setting/dataSettings";

export async function getContractorOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/kontraktor",
    params,
  });
  return data;
}

export async function getContractorOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/kontraktor/${id}`,
  });
  return data;
}


export async function createEditContractorOption(
  values: IContractorSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/kontraktor/${id}` : "/general/kontraktor";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteContractorOption(
  id: string | number | null
) {
  const apiUrl = `/general/kontraktor/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

