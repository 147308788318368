import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, Grid, Button, FormControl, FormLabel } from "@chakra-ui/react";

import Section from "components/section/Section";
import NumberGroupField from "components/fields/NumberGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";

import { IWakafIstibdalInfoFormValues } from "entities/admin/wakafManagement";
import { useCreateWakafIstibdalInfo } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalInfo/useCreateWakafIstibdalInfo";
import { useEditWakafIstibdalInfo } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalInfo/useEditWakafIstibdalAssessment";

import { formatDate } from "utils/helpers";

interface FormAcquisitionInfoProps {
  action: "create" | "edit" | "show";
  wakafAssetId?: number;
  wakafIstibdalAssessmentId?: number;
  initialData?: IWakafIstibdalInfoFormValues;
}

const schema = yup
  .object({
    penerima_balasan: yup.string().required(),
    jum_istibdal_pembelian_rm: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
  })
  .required();

const FormAcquisitionInfo: React.FC<FormAcquisitionInfoProps> = ({
  action,
  wakafAssetId,
  wakafIstibdalAssessmentId,
  initialData,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    initialData?.senarai_lampiran || [
      {
        id_senarai_lampiran: "",
        tab_name: "aset_wakaf_maklumat_istibdal",
        file_name: "",
        file_description: "",
      },
    ]
  );

  const history = useHistory();
  const { createWakafIstibdalInfo, isCreating } = useCreateWakafIstibdalInfo();
  const { editWakafIstibdalInfo, isEditing } = useEditWakafIstibdalInfo();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IWakafIstibdalInfoFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createWakafIstibdalInfo({
        newWakafIstibdalInfo: {
          ...data,
          id_aset_wakaf_baru: wakafAssetId,
          jenis_istibdal: "2",
          status_istibdal: "0",
          id_pengambilan_penilaian_istibdal: wakafIstibdalAssessmentId,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files,
        },
      });
    } else if (action === "edit") {
      editWakafIstibdalInfo({
        newWakafIstibdalInfo: {
          ...data,
          id_aset_wakaf_baru: wakafAssetId,
          jenis_istibdal: "2",
          id_pengambilan_penilaian_istibdal: wakafIstibdalAssessmentId,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                file_name: file?.file_name,
                file_description: file?.file_description,
                tab_name: "aset_wakaf_maklumat_istibdal",
              };
            })
          ),
          lampiran: files,
        },
        id: initialData?.id,
      });
    }
  });

  useEffect(() => {
    const defaultValues: any = {
      no_resit_rasmi: "",
      terima_daripada: "",
      luas_tanah_istibdal_hektar: null,
      luas_tanah_istibdal_ektar: null,
      luas_tanah_istibdal_m2: null,
      luas_tanah_istibdal_kp2: null,
      jum_istibdal_pembelian_rm: null,
      perihal_terimaan: "",
      maklumat_pembayaran: "",
      tarikh_borang_14A: "",
      tarikh_resit_rasmi: "",
      penerima_balasan: "",
      amaun_istibdal_rm: null,
      tarikh_bayaran: "",
      perihal_balasan: "",
      senarai_lampiran: [],
    };

    if (action === "create") {
      setFileDescriptions([]);
      reset(defaultValues);
    } else {
      reset({
        ...initialData,
        tarikh_bayaran: formatDate(initialData?.tarikh_bayaran),
      });
    }
  }, [action, initialData, reset]);

  return (
    <Flex mt="30px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={action === "create" ? "reset" : "button"}
            onClick={() => {
              if (action !== "create") {
                history.push("/admin/wakaf/aset");
              }
            }}
          >
            Batal
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            disabled={isCreating || isEditing}
          >
            {action === "edit" ? "Kemaskini Istibdal" : "Daftar Istibdal"}
          </Button>
        </Flex>

        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <TextField
            id="penerima_balasan"
            label="Penerima Balasan"
            isInvalid={!!errors.penerima_balasan}
            errorMessage={errors.penerima_balasan?.message}
            readOnly={action === "show"}
            {...register("penerima_balasan")}
          />

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Amaun Istibdal (RM)
            </FormLabel>

            <NumberGroupField
              id="jum_istibdal_pembelian_rm"
              label="RM"
              step="any"
              isInvalid={!!errors.jum_istibdal_pembelian_rm}
              errorMessage={errors.jum_istibdal_pembelian_rm?.message}
              readOnly={action === "show"}
              {...register("jum_istibdal_pembelian_rm")}
            />
          </FormControl>

          <TextField
            id="tarikh_bayaran"
            label="Tarikh Bayaran"
            type="date"
            isInvalid={!!errors.tarikh_bayaran}
            errorMessage={errors.tarikh_bayaran?.message}
            readOnly={action === "show"}
            {...register("tarikh_bayaran")}
          />

          <TextField
            id="perihal_balasan"
            label="Perihal Balasan"
            type="textarea"
            isInvalid={!!errors.perihal_balasan}
            errorMessage={errors.perihal_balasan?.message}
            readOnly={action === "show"}
            {...register("perihal_balasan")}
          />
        </Grid>

        <Section size="md" title="Lampiran Istibdal Melalui Pengambilan">
          <FileUploadField
            files={files}
            setFiles={setFiles}
            fileDescriptions={fileDescriptions}
            setFileDescriptions={setFileDescriptions}
            tabName="aset_wakaf_maklumat_istibdal"
            readOnly={action === "show"}
          />
        </Section>
      </form>
    </Flex>
  );
};

export default FormAcquisitionInfo;
