import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditSumberWakafOption } from "services/setting/dataSetting/sumberWakafService";
import { ISumberWakafSetting } from "entities/setting/dataSettings";

export function useEditSumberWakafSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: editSumberWakafSetting, isLoading: isEditing } = useMutation({
    mutationFn: ({
      sumberWakafSetting,
      id,
    }: {
      sumberWakafSetting: ISumberWakafSetting;
      id: string | null;
    }) => createEditSumberWakafOption(sumberWakafSetting, id),
    onSuccess: () => {
      toast({
        title: "Sumber wakaf successfully updated",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["sumberWakaf"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isEditing, editSumberWakafSetting };
}
