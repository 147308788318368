import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { createEditContractorOption } from "services/setting/dataSetting/contractorSettingService";
import { IContractorSetting } from "entities/setting/dataSettings";

export function useCreateContractorSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createContractorSetting, isLoading: isCreating } = useMutation({
    mutationFn: ({contractorSetting, id}: {contractorSetting: IContractorSetting, id?: string | null}) => createEditContractorOption(contractorSetting, id),
    onSuccess: () => {
      toast({
        title: "New contractor successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["contractor"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createContractorSetting };
}