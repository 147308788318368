import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditWakafAssessment } from "services/admin/wakafManagement/wakafAssessmentService";
import { IWakafAssessmentFormValues } from "entities/admin/wakafManagement";

export function useCreateWakafAssessment() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createWakafAssessment, isLoading: isCreating } = useMutation({
    mutationFn: ({newWakafAssessment, id}: {newWakafAssessment: IWakafAssessmentFormValues, id: string | null}) => createEditWakafAssessment(newWakafAssessment, id),
    onSuccess: () => {
      toast({
        title: "New wakaf assessment successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["wakafAssessment"] });
      history.push("/admin/wakaf/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createWakafAssessment };
}

