import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Flex, SimpleGrid, Button } from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import TextField from "components/fields/TextField";

import { useCreateStateSetting } from "hooks/setting/dataSetting/stateSetting/useCreateStateSetting";
import { useEditStateSetting } from "hooks/setting/dataSetting/stateSetting/useEditStateSetting";
import { IStateSetting } from "entities/setting/dataSettings";

interface FormStateSettingProps {
  action: "create" | "edit" | "show";
  initialData?: IStateSetting;
}

const FormStateSetting: React.FC<FormStateSettingProps> = ({
  action,
  initialData,
}) => {
  const { createStateSetting, isCreating } = useCreateStateSetting();
  const { editStateSetting, isEditing } = useEditStateSetting();

  const history = useHistory();

  const schema = yup
    .object({
      nama: yup.string().required(),
      kod_upi: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IStateSetting>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createStateSetting({
        stateSetting: data,
      });
    } else if (action === "edit" && !isEditing) {
      editStateSetting({
        stateSetting: data,
        id: `${data.id}`,
      });
    }
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Negeri">
              <Flex gap="20px" justifyContent={"flex-end"} mb={"20px"}>
                <Button
                  variant="outline"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  colorScheme="gray"
                  type={action === "edit" ? "button" : "reset"}
                  onClick={() => {
                    if (action !== "create") {
                      history.push("/admin/settings/data");
                    }
                  }}
                >
                  {action !== "show" ? "Batal" : "Kembali"}
                </Button>

                {action !== "show" && (
                  <Button
                    variant="brand"
                    fontSize={"sm"}
                    fontWeight={"500"}
                    minW={"183px"}
                    type="submit"
                    isDisabled={isCreating || isEditing}
                  >
                    {action === "edit"
                      ? "Kemaskini Negeri"
                      : "Daftar Negeri Baru"}
                  </Button>
                )}
              </Flex>
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="nama"
                  label="Nama Negeri"
                  placeholder="Pulau Pinang"
                  isInvalid={!!errors.nama}
                  errorMessage={errors.nama?.message}
                  readOnly={action === "show"}
                  autoComplete="nope"
                  {...register("nama")}
                />

                <TextField
                  id="kod_upi"
                  label="Kod UPI"
                  placeholder="10"
                  isInvalid={!!errors.kod_upi}
                  errorMessage={errors.kod_upi?.message}
                  readOnly={action === "show"}
                  {...register("kod_upi")}
                />
              </SimpleGrid>
            </Section>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormStateSetting;
