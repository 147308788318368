import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
} from "@chakra-ui/react";

import Section from "components/section/Section";
import NumberGroupField from "components/fields/NumberGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";
import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/dataSetting/useOptions";

import { IWakafTaxFormValues } from "entities/admin/wakafManagement";
import { useCreateWakafTax } from "hooks/admin/wakafManagement/wakafTax/useCreateWakafTax";
import { useEditWakafTax } from "hooks/admin/wakafManagement/wakafTax/useEditWakafTax";
import { useWakafTax } from "hooks/admin/wakafManagement/wakafTax/useWakafTax";

import { formatDate } from "utils/helpers";

interface FormTaxProps {
  action: "create" | "edit" | "show";
  wakafAssetId?: number;
  wakafTaxId?: number;
  toggleCloseFormTax?: () => void;
}

const schema = yup
  .object({
    id_jenis_cukai: yup.string().required(),
    jum_bayar_1jun_ikut_bil: yup.number().required(),
    jum_bayar_1jun_ikut_bhg_aset: yup.number().required(),
    auid: yup.string().when('id_jenis_cukai', ([id_jenis_cukai], schema) => {
      return id_jenis_cukai == '3' ? schema.required() : schema.notRequired();
    }), 
    nama_pembayar: yup.string().required(),
    no_akaun: yup.string().required(),
    no_resit_cukai: yup.string().required(),
    tarikh_penyata: yup.date().required(),
    rayuan_cukai: yup.string().required(),
    deskripsi_cukai: yup.string().required(),
  })
  .required();

const FormTax: React.FC<FormTaxProps> = ({
  action,
  wakafAssetId,
  wakafTaxId,
  toggleCloseFormTax,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    [
      {
        id_senarai_lampiran: "",
        tab_name: "aset_wakaf_cukai",
        file_name: "",
        file_description: "",
      },
    ]
  );

  const history = useHistory();
  const { createWakafTax, isCreating } = useCreateWakafTax();
  const { editWakafTax, isEditing } = useEditWakafTax();
  const { wakafTax, isInitialLoading, error } = useWakafTax(wakafTaxId);
  const { jenisCukaiOps, rayuanCukaiOps } = useOptions();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IWakafTaxFormValues>({
    resolver: yupResolver(schema) as any,
  });

  const renderTitle = (action: string) => {
    if (action === "create") {
      return "Tambah Rekod Cukai";
    } else if (action === "edit") {
      return "Kemaskini Rekod Cukai";
    } else if (action === "show") {
      return "Rekod Cukai";
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createWakafTax({
        newWakafTax: { 
          ...data, 
          id_aset_wakaf: wakafAssetId,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files
         },
        id: null,
      });
      // reset();
      // toggleCloseFormTax();
    } else if (action === "edit") {
      editWakafTax({
        newWakafTax: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                tab_name: file?.tab_name,
                file_name: file?.file_name,
                file_description: file?.file_description,
              };
            })
          ),
          lampiran: files,
        },
        id: wakafTaxId,
      });
      // reset();
      // toggleCloseFormTax();
    }
  });

  useEffect(() => {
    let defaultValues: any = {
      id_jenis_cukai: "",
      jum_bayar_1jun_ikut_bil: "",
      jum_bayar_1jun_ikut_bhg_aset: "",
      auid: "",
      nama_pembayar: "",
      no_akaun: "",
      no_resit_cukai: "",
      tarikh_penyata: "",
      rayuan_cukai: "",
      deskripsi_cukai: "",
      senarai_lampiran: [],
    };

    if (action === "create") {
      setFileDescriptions([]);
      reset(defaultValues);
    } else if (wakafTax?.data) {
      defaultValues = {
        ...wakafTax.data,
        tarikh_penyata: formatDate(wakafTax.data.tarikh_penyata),
      };
      reset(defaultValues);
    }
  }, [action, wakafTax?.data, reset]);

  useEffect(() => {
    if(wakafTax?.data?.senarai_lampiran){
      setFileDescriptions(wakafTax?.data?.senarai_lampiran.filter(
        (file: any) => file.tab_name === "aset_wakaf_cukai"
      ));
    }

    return () => {
      setFileDescriptions([]);
    };
  }, [wakafTax?.data]);

  if (isInitialLoading) return <FullScreenLoader />;

  return (
    <Flex px="25px" mt="10px" mb="1.5rem" flexDirection={"column"}>
      <Section size={"xl"} title={renderTitle(action)}>
        <form onSubmit={onSubmit}>
          <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
            <Button
              variant="outline"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              colorScheme="gray"
              type={action === "create" ? "reset" : "button"}
              onClick={() => {
                if (action !== "create") {
                  history.push("/admin/wakaf/aset");
                }
              }}
            >
              {action === "create" ? "Batal" : "Kembali"}
            </Button>

            <Button
              variant="brand"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              type="submit"
              disabled={isCreating || isEditing}
            >
              {action === "edit"
                ? "Kemaskini Rekod Cukai"
                : "Simpan Rekod Cukai"}
            </Button>
          </Flex>

          <SimpleGrid
            columns={{ base: 3 }}
            gap={{ sm: "6px", md: "18px" }}
            mb={10}
          >
            <SelectField
              label={"Jenis Cukai"}
              options={jenisCukaiOps}
              isInvalid={!!errors.id_jenis_cukai}
              errorMessage={errors.id_jenis_cukai?.message}
              readOnly={action === "show"}
              {...register("id_jenis_cukai")}
            />

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Jumlah Perlu Dibayar Sebelum 1 Jun (RM) Mengikut Bil
              </FormLabel>

              <NumberGroupField
                label="RM"
                step="any"
                isInvalid={!!errors.jum_bayar_1jun_ikut_bil}
                errorMessage={errors.jum_bayar_1jun_ikut_bil?.message}
                readOnly={action === "show"}
                {...register("jum_bayar_1jun_ikut_bil")}
              />
            </FormControl>

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Jumlah Perlu Bayar Sebelum 1 Jun Mengikut Bahagian Aset
              </FormLabel>

              <NumberGroupField
                label="RM"
                step="any"
                isInvalid={!!errors.jum_bayar_1jun_ikut_bhg_aset}
                errorMessage={errors.jum_bayar_1jun_ikut_bhg_aset?.message}
                readOnly={action === "show"}
                {...register("jum_bayar_1jun_ikut_bhg_aset")}
              />
            </FormControl>

            <TextField
              id="auid"
              label="AUID"
              isInvalid={!!errors.auid}
              errorMessage={errors.auid?.message}
              readOnly={action === "show"}
              {...register("auid")}
            />

            <TextField
              id="nama_pembayar"
              label="Nama Pembayar"
              isInvalid={!!errors.nama_pembayar}
              errorMessage={errors.nama_pembayar?.message}
              readOnly={action === "show"}
              {...register("nama_pembayar")}
            />

            <TextField
              id="no_akaun"
              label="No. Akaun"
              isInvalid={!!errors.no_akaun}
              errorMessage={errors.no_akaun?.message}
              readOnly={action === "show"}
              {...register("no_akaun")}
            />

            <TextField
              id="no_resit_cukai"
              label="No. Resit Cukai"
              isInvalid={!!errors.no_resit_cukai}
              errorMessage={errors.no_resit_cukai?.message}
              readOnly={action === "show"}
              {...register("no_resit_cukai")}
            />

            <TextField
              id="tarikh_penyata"
              label="Tarikh Penyata"
              type="date"
              isInvalid={!!errors.tarikh_penyata}
              errorMessage={errors.tarikh_penyata?.message}
              readOnly={action === "show"}
              {...register("tarikh_penyata")}
            />

            <SelectField
              label={"Rayuan Cukai"}
              options={rayuanCukaiOps}
              isInvalid={!!errors.rayuan_cukai}
              errorMessage={errors.rayuan_cukai?.message}
              readOnly={action === "show"}
              {...register("rayuan_cukai")}
            />

            <TextField
              id="deskripsi_cukai"
              label="Deskripsi Cukai"
              type="textarea"
              isInvalid={!!errors.deskripsi_cukai}
              errorMessage={errors.deskripsi_cukai?.message}
              readOnly={action === "show"}
              {...register("deskripsi_cukai")}
            />
          </SimpleGrid>

          <Section size="md" title="Lampiran Cukai">
            <FileUploadField
              files={files}
              setFiles={setFiles}
              fileDescriptions={fileDescriptions}
              setFileDescriptions={setFileDescriptions}
              tabName="aset_wakaf_cukai"
              readOnly={action === "show"}
            />
          </Section>
        </form>
      </Section>
    </Flex>
  );
};

export default FormTax;
