import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditWakafRegistration } from "services/admin/wakafRegistration/wakafRegistrationService";
import { IWakafRegistrationFormValues } from "entities/admin/wakafRegistration";

export function useEditWakafRegistration() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: editWakafRegistration, isLoading: isEditing } = useMutation({
    mutationFn: ({
      newWakafRegistration,
      id,
    }: {
      newWakafRegistration: IWakafRegistrationFormValues;
      id: string | null;
    }) => createEditWakafRegistration(newWakafRegistration, id),
    onSuccess: () => {
      toast({
        title: "Wakaf registration successfully updated",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["wakafRegistration"] });
      history.push("/admin/wakaf/permohonan");
    },
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isEditing, editWakafRegistration };
}
