import { useQuery } from "@tanstack/react-query";
import { getWakafAssessment } from "services/admin/wakafManagement/wakafAssessmentService";
import { useToast } from "@chakra-ui/react";

export function useWakafAssessment(wakafAssessmentId: number | null) {
  const toast = useToast();

  const {
    isLoading,
    isInitialLoading,
    data: wakafAssessment,
    error,
  } = useQuery({
    queryKey: ["wakafAssessment", wakafAssessmentId],
    queryFn: () => getWakafAssessment(wakafAssessmentId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: "always",
    enabled: !!wakafAssessmentId,
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isInitialLoading, isLoading, error, wakafAssessment };
}

