import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getContractorOption } from "services/setting/dataSetting/contractorSettingService";

export function useContractorSetting() {
  const { contractorId } = useParams<{ contractorId: string }>();
  const toast = useToast();

  const {
    isLoading,
    data: contractor,
    error,
  } = useQuery({
    queryKey: ["contractor", contractorId],
    queryFn: () => getContractorOption(contractorId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: "always",
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isLoading, error, contractor };
}

