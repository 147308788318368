import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

import Section from "components/section/Section";
import TextField from "components/fields/TextField";

import { useResetPassword } from "hooks/setting/password/useResetPassword";

interface UserCredentialsProps {
  initialData?: any;
}

const UserCredentials: React.FC<UserCredentialsProps> = ({ initialData }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isMatch, setIsMatch] = useState(false);
  const { resetCurrentPassword, isResetting } = useResetPassword();

  const schema = yup
    .object({
      new_password: yup.string().required(),
    })
    .required();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const password = watch("new_password");

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);

  const onSubmit = handleSubmit((data) => {
    if(isMatch){
      resetCurrentPassword({
        newPassword: {
          ...data,
          new_password_confirmation: data.new_password
        },
      });
    }
  });

  useEffect(() => {
    if (passwordConfirmation && passwordConfirmation !== password) {
      setIsMatch(false);
    } else {
      setIsMatch(true);
    }
  }, [passwordConfirmation, password]);

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Section title="Kata Laluan Baru">
        <SimpleGrid
          columns={{ base: 2 }}
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <TextField
            id="current_password"
            label="Kata Laluan Semasa"
            placeholder="Min. 5 characters"
            type="password"
            showPassword={showCurrentPassword}
            toggleShowPassword={toggleShowCurrentPassword}
            isInvalid={!!errors.current_password}
            errorMessage={errors.current_password?.message}
            autoComplete="current-password"
            {...register("current_password")}
          />

          <TextField
            id="new_password"
            label="Kata Laluan Baru"
            placeholder="Min. 5 characters"
            type="password"
            showPassword={showPassword}
            toggleShowPassword={toggleShowPassword}
            isInvalid={!!errors.new_password}
            errorMessage={errors.new_password?.message}
            autoComplete="new-password"
            {...register("new_password")}
          />

          <FormControl isInvalid={!isMatch}>
            <FormLabel variant="main" mb="8px" htmlFor={"password_confirm"}>
              Pengesahan Kata Laluan Baru
            </FormLabel>

            <InputGroup size="md">
              <Input
                id={"password_confirm"}
                variant={"auth"}
                fontSize={"sm"}
                ms={{ base: "0px", md: "0px" }}
                type={"password"}
                placeholder={"Sahkan Kata Laluan"}
                fontWeight={"500"}
                size={"md"}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </InputGroup>

            {!isMatch && (
              <FormErrorMessage>Kata laluan tidak sama</FormErrorMessage>
            )}
          </FormControl>
        </SimpleGrid>
      </Section>

      <Flex gap="20px" justifyContent={"flex-end"}>
        <Button
          variant="brand"
          fontSize={"sm"}
          fontWeight={"500"}
          minW={"183px"}
          type="submit"
          isLoading={isResetting}
        >
          Kemaskini Kata Laluan
        </Button>
      </Flex>
    </form>
  );
};

export default UserCredentials;
