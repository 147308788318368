import React from 'react'
import CreateBasicInformation from './Create'
import EditBasicInformation from './Edit'
import ShowBasicInformation from './Show'

interface TabBasicInformationProps {
  action: "create" | "edit" | "show";
}

const TabBasicInformation = ({ action }: TabBasicInformationProps) => {  
  const renderComponent = () => {
    switch (action) {
      case "create":
        return <CreateBasicInformation />;
      case "edit":
        return <EditBasicInformation />;
      case "show":
        return <ShowBasicInformation />;
      default:
        return <CreateBasicInformation />;
    }
  };
  
  return <div>{renderComponent()}</div>;
};

export default TabBasicInformation;

