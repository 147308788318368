import React, { useState, useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import FormPartition from "./FormPartition";
import FormSubdivision from "./FormSubdivision";
import FormAmalgamation from "./FormAmalgamation";

import {
  IBaitulmalPartitionFormValues,
  IBaitulmalSubdivisionFormValues,
  IBaitulmalAmalgamationFormValues,
} from "entities/admin/baitulmalManagement";

interface TabDevelopmentProps {
  baitulmalAssetId?: number;
  pecahBahagian?: IBaitulmalPartitionFormValues;
  pecahSempadan?: IBaitulmalSubdivisionFormValues;
  pencantuman?: IBaitulmalAmalgamationFormValues;
  assetArea?: {
    luas_hektar: number;
    luas_ekar: number;
    luas_m2: number;
    luas_kp2: number;
  };
}

const TabDevelopment = ({
  baitulmalAssetId,
  pecahBahagian,
  pecahSempadan,
  pencantuman,
  assetArea,
}: TabDevelopmentProps) => {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("show")) {
      setAction("show");
    } else if (pecahBahagian || pecahSempadan || pencantuman) {
      setAction("edit");
    } else {
      setAction("create");
    }
  }, [pecahBahagian, pecahSempadan, pencantuman, location.pathname]);
  
  return (
    <Box px="25px">
      <Accordion variant="main" allowToggle>
        <AccordionItem isDisabled={action !== "create" && !pecahBahagian}>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={"semibold"}>
                Maklumat Pecah Bahagian
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <FormPartition
              action={action}
              baitulmalAssetId={baitulmalAssetId}
              initialData={pecahBahagian}
              assetArea={assetArea}
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem isDisabled={action !== "create" && !pecahSempadan}>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={"semibold"}>
                Maklumat Pecah Sempadan
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <FormSubdivision
              action={action}
              baitulmalAssetId={baitulmalAssetId}
              initialData={pecahSempadan}
              assetArea={assetArea}
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem isDisabled={action !== "create" && !pencantuman}>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={"semibold"}>
                Maklumat Pencantuman
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <FormAmalgamation
              action={action}
              baitulmalAssetId={baitulmalAssetId}
              initialData={pencantuman}
              assetArea={assetArea}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default TabDevelopment;
