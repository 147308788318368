import axios from "utils/axios";
import { IDepartmentSetting } from "entities/setting/dataSettings";

export async function getDepartmentOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jabatan",
    params,
  });
  return data;
}

export async function getDepartmentOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/jabatan/${id}`,
  });
  return data;
}

export async function createEditDepartmentOption(
  values: IDepartmentSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/jabatan/${id}` : "/general/jabatan";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteDepartmentOption(
  id: string | number | null
) {
  const apiUrl = `/general/jabatan/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

