import React from "react";
import { useForm } from "react-hook-form";
import { Flex, HStack, Text, Button } from "@chakra-ui/react";
import SelectField from "components/fields/SelectField";
import useOptions from "hooks/setting/dataSetting/useOptions";
import { useEditWakafIstibdalAssessment } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalAssessment/useEditWakafIstibdalAssessment";

interface IstibdalInfoSummaryProps {
  istibdalSummary?: {
    jumlah_pengambilan: number;
    jumlah_istibdal_terkumpul: number;
    baki_belum_diganti: number;
  };
  istibdalStatus: number;
  wakafAssetId: number;
  wakafIstibdalAssessmentId: number;
}

const IstibdalInfoSummary = ({
  istibdalSummary,
  istibdalStatus,
  wakafAssetId,
  wakafIstibdalAssessmentId,
}: IstibdalInfoSummaryProps) => {
  const { istibdalStatusOps } = useOptions();
  const { editWakafIstibdalAssessment, isEditing } =
    useEditWakafIstibdalAssessment();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ status_istibdal: number }>({
    defaultValues: {
      status_istibdal: istibdalStatus,
    },
  });

  const onSubmit = handleSubmit((data) => {
    editWakafIstibdalAssessment({
      newWakafIstibdalAssessment: {
        id_aset_wakaf: wakafAssetId,
        status_istibdal: data.status_istibdal,
      },
      id: wakafIstibdalAssessmentId,
    });
  });

  return (
    <Flex
      width={{ base: "100%", md: "400px" }}
      flexDirection="column"
      alignItems="flex-start"
      px="1.5rem"
      gap={2}
      mb="1.5rem"
    >
      <Text mb="8px" variant="secondary">
        Ringkasan Maklumat Istibdal
      </Text>

      <HStack fontSize="sm">
        <Text variant="main">Jumlah Pengambilan: </Text>
        <Text variant="secondary">
          RM {istibdalSummary?.jumlah_pengambilan || 0}
        </Text>
      </HStack>

      <HStack fontSize="sm">
        <Text variant="main">Jumlah Istibdal Setakat Ini: </Text>
        <Text variant="secondary">
          RM {istibdalSummary?.jumlah_istibdal_terkumpul || 0}
        </Text>
      </HStack>

      <HStack fontSize="sm" mb={5}>
        <Text variant="main">Baki Belum Diganti: </Text>
        <Text variant="secondary">
          RM {istibdalSummary?.baki_belum_diganti || 0}
        </Text>
      </HStack>

      <Text mb="8px" variant="secondary">
        Kemaskini Status Istibdal
      </Text>

      <form onSubmit={onSubmit} style={{ width: "100%" }}>
        <SelectField
          label="Status Istibdal"
          options={istibdalStatusOps}
          isInvalid={!!errors.status_istibdal}
          errorMessage={errors.status_istibdal?.message}
          // readOnly={action === "show"}
          {...register("status_istibdal")}
        />

        <Button
          variant="brand"
          fontSize={"sm"}
          fontWeight={"500"}
          w={"max-content"}
          mt="14px"
          type="submit"
          disabled={isEditing}
        >
          Kemaskini
        </Button>
      </form>
    </Flex>
  );
};

export default IstibdalInfoSummary;
