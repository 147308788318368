import React from "react";
import { Box, Text, Link, List, Icon, Badge } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import Resource from "components/resource/Resource";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { useWakafAssets } from "hooks/admin/wakafManagement/wakafAsset/useWakafAssets";
import { useDeleteWakafAsset } from "hooks/admin/wakafManagement/wakafAsset/useDeleteWakafAsset";

import { IWakafAsset } from "entities/admin/wakafManagement";
import { fetchLabel } from "utils/helpers";
import Sorting from "./components/Sorting";

const columnHelper = createColumnHelper<IWakafAsset>();

export default function WakafAsset() {
  const history = useHistory();
  const { jenisAsetOps, daerahOps, gunaTanahSemasaOps, kegunaanTanahWakafSemasaOps } = useOptions();
  const { isLoading, error, wakafAssets } = useWakafAssets();
  const { removeWakafAsset, isDeleting } = useDeleteWakafAsset();

  const getStatusDescription = (status: number) => {
    switch (status) {
      case -1:
        return <Badge colorScheme="red">Lupus</Badge>;
      case 1:
        return <Badge colorScheme="green">Aktif</Badge>;
      case 0:
        return <Badge colorScheme="yellow">Tidak Aktif</Badge>;
      default:
        return <Badge colorScheme="yellow">Tidak Aktif</Badge>;
    }
  };

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID Aset Wakaf</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id_jenis_aset", {
      header: () => <Text variant="tableHeading">Jenis Aset</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), jenisAsetOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_daerah", {
      header: () => <Text variant="tableHeading">Daerah</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), daerahOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("no_hak_milik", {
      header: () => <Text variant="tableHeading">No Hakmilik</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_lot", {
      header: () => <Text variant="tableHeading">No Lot</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_pt", {
      header: () => <Text variant="tableHeading">No PT</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id_guna_tanah_semasa", {
      header: () => <Text variant="tableHeading">Gunatanah Semasa</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), gunaTanahSemasaOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_kegunaan_tanah_wakaf_semasa", {
      header: () => <Text variant="tableHeading">Gunatanah Wakaf Semasa</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), kegunaanTanahWakafSemasaOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("status", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info) => getStatusDescription(info.getValue()),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <NavLink to={`/admin/wakaf/aset/kemaskini/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink to={`/admin/wakaf/aset/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>

          <Link
            as={"button"}
            onClick={() => {
              removeWakafAsset(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={wakafAssets}
        title={"Senarai Aset Wakaf"}
        createTitle="Daftar Aset Wakaf"
        onCreate={() => history.push("/admin/wakaf/aset/baru")}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
