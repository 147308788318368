import { useQuery } from "@tanstack/react-query";
import { getJenisAsetBaitulmalNameOptions } from "services/setting/dataSetting/jenisAsetBaitulmalSettingService";

export default function useJenisAsetBaitulmalOptionSettings() {
  const { isLoading, data: jenisAsetBaitulmalNameOptions } = useQuery({
    queryKey: ["jenisAsetBaitulmalNameOptions"],
    queryFn: () => getJenisAsetBaitulmalNameOptions(),
  });

  const jenisAsetBaitulmalNameOps = jenisAsetBaitulmalNameOptions?.data || [];

  // OUTPUT
  return {
    jenisAsetBaitulmalNameOps,
  };
}
