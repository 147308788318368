import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditBaitulmalHistory } from "services/admin/baitulmalManagement/baitulmalHistoryService";
import { IBaitulmalHistoryFormValues } from "entities/admin/baitulmalManagement";

export function useEditBaitulmalHistory() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: editBaitulmalHistory, isLoading: isEditing } = useMutation({
    mutationFn: ({
      newBaitulmalHistory,
      id,
    }: {
      newBaitulmalHistory: IBaitulmalHistoryFormValues;
      id: string | number | null;
    }) => createEditBaitulmalHistory(newBaitulmalHistory, id),
    onSuccess: () => {
      toast({
        title: "Baitulmal history successfully updated",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["baitulmalHistory", "baitulmalHistories"] });
      history.push("/admin/baitulmal/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isEditing, editBaitulmalHistory };
}
