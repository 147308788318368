import axios from "utils/axios";
import { INoFailMainppSetting } from "entities/setting/dataSettings";

export async function getNoFailMainppOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/noFailMainPP/${id}`,
  });
  return data;
}

export async function getNoFailMainppOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/noFailMainPP",
    params,
  });
  return data;
}

export async function createEditNoFailMainppOption(
  values: INoFailMainppSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/noFailMainPP/${id}` : "/general/noFailMainPP";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}

export async function deleteNoFailMainppOption(
  id: string | number | null
) {
  const apiUrl = `/general/noFailMainPP/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

