import axios from "utils/axios";
import { IPerolehWakafSetting } from "entities/setting/dataSettings";

export async function getPerolehWakafOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/kaedahPerolehTanahWakaf",
    params,
  });
  return data;
}

export async function getPerolehWakafOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/kaedahPerolehTanahWakaf/${id}`,
  });
  return data;
}


export async function createEditPerolehWakafOption(
  values: IPerolehWakafSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/kaedahPerolehTanahWakaf/${id}` : "/general/kaedahPerolehTanahWakaf";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deletePerolehWakafOption(
  id: string | number | null
) {
  const apiUrl = `/general/kaedahPerolehTanahWakaf/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

