import React from "react";
import {
  Box,
  Flex,
} from "@chakra-ui/react";

import Card from "components/card/Card";

import UserDetails from "./UserDetails";
import UserCredentials from "./UserCredentials";

interface FormProfileProps {
  initialData?: any;
}

const FormProfile: React.FC<FormProfileProps> = ({ initialData }) => {

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"} gap={"20px"}>
          <UserDetails initialData={initialData} />
          <UserCredentials initialData={initialData} />
        </Flex>
      </Card>
    </Box>
  );
};

export default FormProfile;
