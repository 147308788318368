import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

import FormHistory from "./FormHistory";
import TableHistory from "./TableHistory";

interface TabHistoryProps {
  wakafAssetId?: number;
}

export default function TabHistory({ wakafAssetId }: TabHistoryProps) {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const [wakafHistoryId, setWakafHistoryId] = useState<number | null>(null);
  const [showFormHistory, setShowFormHistory] = useState(false);

  const toggleFormHistory = (action: "create" | "edit" | "show", wakafHistoryId?: number) => {
    setAction(action);
    setWakafHistoryId(wakafHistoryId);
    setShowFormHistory(true);
  };

  return (
    <Flex direction="column">
      <TableHistory
        wakafAssetId={wakafAssetId}
        toggleFormHistory={toggleFormHistory}
      />

      {showFormHistory && (
        <FormHistory
          action={action}
          wakafAssetId={wakafAssetId}
          wakafHistoryId={wakafHistoryId}
        />
      )}
    </Flex>
  );
}
