import { useQuery } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { getBaitulmalTax } from "services/admin/baitulmalManagement/baitulmalTaxService";

export function useBaitulmalTax(baitulmalTaxId: number | null) {
  const toast = useToast();

  const {
    isLoading,
    isInitialLoading,
    data: baitulmalTax,
    error,
  } = useQuery({
    queryKey: ["baitulmalTax", baitulmalTaxId],
    queryFn: () => getBaitulmalTax(baitulmalTaxId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: "always",
    enabled: !!baitulmalTaxId,
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isInitialLoading, isLoading, error, baitulmalTax };
}

