import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Avatar, Flex, SimpleGrid, Button } from "@chakra-ui/react";

import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";


import { IUserFormValues } from "entities/setting/user";
import { useEditCurrentUser } from "hooks/setting/user/useEditCurrentUser";

import sampleAvatar from "assets/img/avatars/avatarSimmmple.png";
import useOptions from "hooks/setting/dataSetting/useOptions";

interface UserDetailsProps {
  initialData?: any;
}

const UserDetails: React.FC<UserDetailsProps> = ({ initialData }) => {
  const { editCurrentUser, isEditing } = useEditCurrentUser();

  const { roleOps } = useOptions();

  const schema = yup
    .object({
      nickname: yup.string().notRequired(),
      phone_no: yup.string().notRequired(),
      position: yup.string().notRequired(),
      department_id: yup.string().notRequired(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const onSubmit = handleSubmit((data) => {
    editCurrentUser({
      newUser: data,
      id: `${data.id}`,
    });
  });

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Flex
        alignItems={"center"}
        height={"86px"}
        width={"max-content"}
        mb={"10px"}
      >
        <Avatar
          mx="auto"
          src={initialData?.photo_url || sampleAvatar}
          h="87px"
          w="87px"
          mt="-43px"
          border="4px solid"
          borderColor={"#c1e9d5 !important"}
        />
      </Flex>

      <Section title="Maklumat Pengguna">
        <SimpleGrid
          columns={{ base: 2 }}
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <TextField
            id="name"
            label="Nama Pengguna"
            placeholder="Ramli Saad"
            isInvalid={!!errors.name}
            errorMessage={errors.name?.message}
            autoComplete="nope"
            readOnly
            {...register("name")}
          />

          <TextField
            id="email"
            label="Emel"
            isInvalid={!!errors.email}
            errorMessage={errors.email?.message}
            readOnly
            {...register("email")}
          />

          <TextField
            id="id_jabatan"
            label="Bahagian/ Jabatan"
            isInvalid={!!errors.id_jabatan}
            errorMessage={errors.id_jabatan?.message}
            {...register("id_jabatan")}
          />

          <TextField
            id="id_unit"
            label="Unit"
            isInvalid={!!errors.id_unit}
            errorMessage={errors.id_unit?.message}
            {...register("id_unit")}
          />

          <SelectField
            label={"Peranan"}
            options={roleOps}
            isInvalid={!!errors.role_id}
            errorMessage={errors.role_id?.message}
            isDisabled
            {...register("role_id")}
          />
        </SimpleGrid>
      </Section>

      <Flex gap="20px" justifyContent={"flex-end"}>
        <Button
          variant="brand"
          fontSize={"sm"}
          fontWeight={"500"}
          minW={"183px"}
          isDisabled={isEditing}
          type="submit"
        >
          Kemaskini Maklumat Pengguna
        </Button>
      </Flex>
    </form>
  );
};

export default UserDetails;
