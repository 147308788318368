import axios from "utils/axios";
import { ITujuanPengambilanSetting } from "entities/setting/dataSettings";

export async function getTujuanPengambilanOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/tujuanPengambilan",
    params,
  });
  return data;
}

export async function getTujuanPengambilanOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/tujuanPengambilan/${id}`,
  });
  return data;
}


export async function createEditTujuanPengambilanOption(
  values: ITujuanPengambilanSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/tujuanPengambilan/${id}` : "/general/tujuanPengambilan";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteTujuanPengambilanOption(
  id: string | number | null
) {
  const apiUrl = `/general/tujuanPengambilan/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

