import axios from "utils/axios";
import { IWakafMaintenanceFormValues } from "entities/admin/wakafManagement";

export async function getWakafMaintenances(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/penyelenggaraan-aset-wakaf",
    params
  });
  return data;
}

export async function getWakafMaintenance(id: number) {
  const { data } = await axios.call({
    method: "get",
    url: `/penyelenggaraan-aset-wakaf/${id}`,
  });
  return data;
}

export async function createEditWakafMaintenance(
  values: IWakafMaintenanceFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/penyelenggaraan-aset-wakaf/${id}` : "/penyelenggaraan-aset-wakaf";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteWakafMaintenance(
  id: string | number | null
) {
  const apiUrl = `/penyelenggaraan-aset-wakaf/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}