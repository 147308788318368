import axios from "utils/axios";

export async function resetPassword(
  values: any,
) {
  const apiUrl = "/user/update/password";
  const apiMethod = "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}