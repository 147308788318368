import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";
import NumberGroupField from "components/fields/NumberGroupField";
import Section from "components/section/Section";
import { useEditBaitulmalAcquisition } from "hooks/admin/baitulmalManagement/baitulmalAcquisition/useEditBaitulmalAcquisition";

import { IWakafAcquisitionFormValues } from "entities/admin/wakafManagement";

import { formatDate } from "utils/helpers";

interface FormAcquisitionProps {
  action: "create" | "edit" | "show";
  initialData?: IWakafAcquisitionFormValues;
}

const schema = yup.object({
  tarikh_belian: yup.string().nullable(),
  harga_belian: yup
  .number()
  .positive()
  .transform((value, originalValue) =>
    originalValue === "" ? null : value
  )
  .optional()
  .nullable(),
  harga_nilaian: yup
  .number()
  .positive()
  .transform((value, originalValue) =>
    originalValue === "" ? null : value
  )
  .optional()
  .nullable(),
});

const FormAcquisition: React.FC<FormAcquisitionProps> = ({
  action,
  initialData,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    initialData?.senarai_lampiran || [
      {
        id_senarai_lampiran: "",
        tab_name: "aset_baitulmal_penilaian",
        file_name: "",
        file_description: "",
      },
    ]
  );

  const history = useHistory();
  const { editBaitulmalAcquisition, isEditing } = useEditBaitulmalAcquisition();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<IWakafAcquisitionFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      tarikh_belian: formatDate(initialData?.tarikh_belian),
    },
  });

  const watchTarikhBelian = watch("tarikh_belian");

  const onSubmit = handleSubmit((data) => {
    if (action === "edit") {
      editBaitulmalAcquisition({
        newBaitulmalAcquisition: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                tab_name: file?.tab_name,
                file_name: file?.file_name,
                file_description: file?.file_description,
              };
            })
          ),
          lampiran: files,
        },
        id: initialData?.id,
      });
    }
  });

  return (
    <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <Flex gap="20px" justifyContent={"flex-end"} mb={"20px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={action === "create" ? "reset" : "button"}
            onClick={() => {
              if (action !== "create") {
                history.push("/admin/wakaf/aset");
              }
            }}
          >
            {action === "create" ? "Batal" : "Kembali"}
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            disabled={isEditing}
          >
            {action === "edit" ? "Kemaskini Maklumat" : "Simpan Maklumat"}
          </Button>
        </Flex>

        <Grid templateColumns="repeat(2, 1fr)" gap={{ sm: "6px", md: "18px" }}>
          <TextField
            id="tarikh_belian"
            label="Tarikh Lulus"
            type="date"
            isInvalid={!!errors.tarikh_belian}
            errorMessage={errors.tarikh_belian?.message}
            readOnly={action === "show"}
            {...register("tarikh_belian")}
          />

          <TextField
            id="deskripsi_belian"
            label="Deskripsi Belian"
            isInvalid={!!errors.deskripsi_belian}
            errorMessage={errors.deskripsi_belian?.message}
            readOnly={action === "show"}
            {...register("deskripsi_belian")}
          />

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Harga Belian (RM)
            </FormLabel>
            <NumberGroupField
              label="RM"
              step={"any"}
              isInvalid={!!errors.harga_belian}
              errorMessage={errors.harga_belian?.message}
              readOnly={action === "show"}
              required={!!watchTarikhBelian}
              {...register("harga_belian")}
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Harga Nilaian (RM)
            </FormLabel>
            <NumberGroupField
              label="RM"
              step={"any"}
              isInvalid={!!errors.harga_nilaian}
              errorMessage={errors.harga_nilaian?.message}
              readOnly={action === "show"}
              required={!!watchTarikhBelian}
              {...register("harga_nilaian")}
            />
          </FormControl>

          <GridItem
            display={"grid"}
            colSpan={2}
            gap={{ sm: "6px", md: "18px" }}
            mt={"20px"}
          >
            <Section size={"md"} title="Lampiran Pembelian Aset Wakaf">
              <FileUploadField
                files={files}
                fileDescriptions={fileDescriptions}
                setFiles={setFiles}
                setFileDescriptions={setFileDescriptions}
                tabName="aset_baitulmal_penilaian"
                readOnly={action === "show"}
              />
            </Section>
          </GridItem>
        </Grid>
      </form>
    </Flex>
  );
};

export default FormAcquisition;
