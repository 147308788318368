import React from "react";
import { Text, Link, List, Icon } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import Resource from "components/resource/Resource";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { useBaitulmalMaintenances } from "hooks/admin/baitulmalManagement/baitulmalMaintenance/useBaitulmalMaintenances";
import { useDeleteBaitulmalMaintenance } from "hooks/admin/baitulmalManagement/baitulmalMaintenance/useDeleteBaitulmalMaintenance";
import useOptions from "hooks/setting/dataSetting/useOptions";

import { IBaitulmalMaintenanceTable } from "entities/admin/baitulmalManagement";

import { fetchLabel, formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<IBaitulmalMaintenanceTable>();

interface TabMaintenanceProps {
  baitulmalAssetId?: number;
  toggleFormMaintenance: (
    action: "create" | "edit" | "show",
    baitulmalMaintenanceId?: number
  ) => void;
}

const TableMaintenance = ({
  baitulmalAssetId,
  toggleFormMaintenance,
}: TabMaintenanceProps) => {
  const { baitulmalMaintenances, isLoading } = useBaitulmalMaintenances({
    idAsetBaitulmal: baitulmalAssetId,
  });
  const { removeBaitulmalMaintenance, isDeleting } = useDeleteBaitulmalMaintenance();
  const { jenisAduanOps, kontraktorOps, kaedahPerolehanOps } = useOptions();

  const columns = [
    columnHelper.accessor("updated_at", {
      header: () => <Text variant="tableHeading">Tarikh Kemaskini</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("id_jenis_aduan", {
      header: () => <Text variant="tableHeading">Jenis Aduan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), jenisAduanOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_pengadu", {
      header: () => <Text variant="tableHeading">Nama Pengadu</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("rujukan", {
      header: () => <Text variant="tableHeading">Rujukan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id_kontraktor", {
      header: () => <Text variant="tableHeading">Kontraktor</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), kontraktorOps)}
        </Text>
      ),
    }),
    columnHelper.accessor("id_kaedah_peroleh", {
      header: () => <Text variant="tableHeading">Kaedah Perolehan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), kaedahPerolehanOps)}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <Link
            as={"button"}
            onClick={() => {
              toggleFormMaintenance("edit", info.row.original.id);
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </Link>

          <Link
            as={"button"}
            onClick={() => {
              toggleFormMaintenance("show", info.row.original.id);
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </Link>

          <Link
            as={"button"}
            onClick={() => {
              removeBaitulmalMaintenance(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Resource
      columns={columns}
      data={baitulmalMaintenances}
      title="Senarai Rekod Penyelenggaraan"
      createTitle="Tambah Rekod Penyelenggaraan"
      onCreate={() => toggleFormMaintenance("create")}
      allowDownload
      onClickDownload={() => console.log("Download")}
    />
  );
};

export default TableMaintenance;
