import axios from "utils/axios";
import { IUnitSetting } from "entities/setting/dataSettings";

export async function getUnitOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/unit",
    params,
  });
  return data;
}

export async function getUnitOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/unit/${id}`,
  });
  return data;
}

export async function createEditUnitOption(
  values: IUnitSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/unit/${id}` : "/general/unit";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteUnitOption(
  id: string | number | null
) {
  const apiUrl = `/general/unit/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

