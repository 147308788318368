import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, Button, SimpleGrid } from "@chakra-ui/react";

import Section from "components/section/Section";
import TextField from "components/fields/TextField";
import FileUploadField from "components/fields/FileUploadField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { IWakafHistoryFormValues } from "entities/admin/wakafManagement";
import { useCreateWakafHistory } from "hooks/admin/wakafManagement/wakafHistory/useCreateWakafHistory";
import { useEditWakafHistory } from "hooks/admin/wakafManagement/wakafHistory/useEditWakafHistory";
import { useWakafHistory } from "hooks/admin/wakafManagement/wakafHistory/useWakafHistory";

import { formatDate } from "utils/helpers";

interface FormHistoryProps {
  action: "create" | "edit" | "show";
  wakafAssetId?: number;
  wakafHistoryId?: number;
}

const schema = yup
  .object({
    tarikh_kejadian: yup.date().required(),
    tajuk: yup.string().required(),
  })
  .required();

const FormHistory: React.FC<FormHistoryProps> = ({
  action,
  wakafAssetId,
  wakafHistoryId,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>([
    {
      id_senarai_lampiran: "",
      tab_name: "aset_wakaf_sejarah",
      file_name: "",
      file_description: "",
    },
  ]);

  const history = useHistory();
  const { createWakafHistory, isCreating } = useCreateWakafHistory();
  const { editWakafHistory, isEditing } = useEditWakafHistory();
  const { wakafHistory, isInitialLoading } = useWakafHistory(wakafHistoryId);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IWakafHistoryFormValues>({
    resolver: yupResolver(schema) as any,
  });

  const renderTitle = (action: string) => {
    if (action === "create") {
      return "Tambah Rekod Sejarah";
    } else if (action === "edit") {
      return "Kemaskini Rekod Sejarah";
    } else if (action === "show") {
      return "Rekod Sejarah";
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createWakafHistory({
        newWakafHistory: {
          ...data,
          id_aset_wakaf: wakafAssetId,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files,
        },
        id: null,
      });
    } else if (action === "edit") {
      editWakafHistory({
        newWakafHistory: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                tab_name: file?.tab_name,
                file_name: file?.file_name,
                file_description: file?.file_description,
              };
            })
          ),
          lampiran: files,
        },
        id: wakafHistoryId,
      });
    }
  });

  useEffect(() => {
    let defaultValues: any = {
      tarikh_kejadian: "",
      tajuk: "",
      keterangan: "",
      senarai_lampiran: [],
    };

    if (action === "create") {
      setFileDescriptions([]);
      reset(defaultValues);
    } else if (wakafHistory?.data) {
      defaultValues = {
        ...wakafHistory.data,
        tarikh_kejadian: formatDate(wakafHistory.data.tarikh_kejadian),
      };
      reset(defaultValues);
    }
  }, [action, wakafHistory?.data, reset]);

  useEffect(() => {
    if (wakafHistory?.data?.senarai_lampiran) {
      setFileDescriptions(wakafHistory?.data?.senarai_lampiran.filter(
        (file: any) => file.tab_name === "aset_wakaf_sejarah"
      )
      );
    }

    return () => {
      setFileDescriptions([]);
    };
  }, [wakafHistory?.data]);

  if (isInitialLoading) return <FullScreenLoader />;

  return (
    <Flex
      key={wakafHistoryId}
      px="25px"
      mt="10px"
      mb="1.5rem"
      flexDirection={"column"}
    >
      <Section size={"xl"} title={renderTitle(action)}>
        <form onSubmit={onSubmit}>
          <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
            <Button
              variant="outline"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              colorScheme="gray"
              type={action === "create" ? "reset" : "button"}
              onClick={() => {
                if (action !== "create") {
                  history.push("/admin/wakaf/aset");
                }
              }}
            >
              {action === "create" ? "Batal" : "Kembali"}
            </Button>

            <Button
              variant="brand"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              type="submit"
              disabled={isCreating || isEditing}
            >
              {action === "edit"
                ? "Kemaskini Rekod Sejarah"
                : "Simpan Rekod Sejarah"}
            </Button>
          </Flex>

          <SimpleGrid
            columns={{ base: 3 }}
            gap={{ sm: "6px", md: "18px" }}
            mb={10}
          >
            <TextField
              id="date"
              label="Tarikh Kejadian"
              type="date"
              isInvalid={!!errors.tarikh_kejadian}
              errorMessage={errors.tarikh_kejadian?.message}
              readOnly={action === "show"}
              {...register("tarikh_kejadian")}
            />

            <TextField
              id="tajuk"
              label="Tajuk"
              isInvalid={!!errors.tajuk}
              errorMessage={errors.tajuk?.message}
              readOnly={action === "show"}
              {...register("tajuk")}
            />

            <TextField
              id="keterangan"
              label="Keterangan"
              type="textarea"
              isInvalid={!!errors.keterangan}
              errorMessage={errors.keterangan?.message}
              readOnly={action === "show"}
              {...register("keterangan")}
            />
          </SimpleGrid>

          <Section size="md" title="Lampiran sejarah">
            <FileUploadField
              files={files}
              setFiles={setFiles}
              fileDescriptions={fileDescriptions}
              setFileDescriptions={setFileDescriptions}
              tabName="aset_wakaf_sejarah"
              readOnly={action === "show"}
            />
          </Section>
        </form>
      </Section>
    </Flex>
  );
};

export default FormHistory;
