import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getBaitulmalRegistrations } from "services/admin/baitulmalRegistration/baitulmalRegistrationService";
import { PAGE_SIZE } from "utils/constants";

export function useBaitulmalRegistrations(params?: any) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let currentSearchParams = {};

  for (const [key, value] of searchParams) {
    currentSearchParams = {
      [key]: value,
    };
  }

  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("limit")
    ? PAGE_SIZE
    : Number(searchParams.get("limit"));

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: [
        "baitulmalRegistrations",
        currentPage,
        currentLimit,
        currentSearchParams,
      ],
      queryFn: () =>
        getBaitulmalRegistrations({
          ...params,
          ...currentSearchParams,
          page: currentPage,
          limit: currentLimit,
        }),
    });
  }, [currentPage, currentLimit, params, queryClient]);

  const {
    isLoading,
    data: baitulmalRegistrations,
    error,
  } = useQuery({
    queryKey: [
      "baitulmalRegistrations",
      currentPage,
      currentLimit,
      currentSearchParams,
    ],
    queryFn: () =>
      getBaitulmalRegistrations({
        ...params,
        ...currentSearchParams,
        page: currentPage,
        limit: currentLimit,
      }),
    retry: false,
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isLoading, error, baitulmalRegistrations };
}
