import React from "react";
import FormOperasiTanahSemasaSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useOperasiTanahSemasaSetting } from "hooks/setting/dataSetting/operasiTanahSemasaSetting/useOperasiTanahSemasaSetting";

export default function EditOperasiTanahSemasaSetting() {
  const { isLoading, operasiTanahSemasa, error } = useOperasiTanahSemasaSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormOperasiTanahSemasaSetting action="edit" initialData={operasiTanahSemasa?.data} />;
}
