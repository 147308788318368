import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  Grid,
  SimpleGrid,
  GridItem,
  Button,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import CreatableSelectField from "components/fields/CreatableSelectField";
import NumberGroupField from "components/fields/NumberGroupField";
import MapLocator from "components/mapLocator/MapLocator";
import LinkGroupField from "components/fields/LinkGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";
import AssetLink from "./AssetLink";

import useOptions from "hooks/setting/dataSetting/useOptions";
import useDataFile from "hooks/setting/dataSetting/useDataFile";

import { IBaitulmalRegistrationFormValues } from "entities/admin/baitulmalRegistration";
import { useCreateBaitulmalRegistration } from "hooks/admin/baitulmalRegistration/useCreateBaitulmalRegistration";
import { useEditBaitulmalRegistration } from "hooks/admin/baitulmalRegistration/useEditBaitulmalRegistration";
import { useTownSettings } from "hooks/setting/dataSetting/townSetting/useTownSettings";
import { useSectionSettings } from "hooks/setting/dataSetting/sectionSetting/useSectionSettings";
import { useDunSettings } from "hooks/setting/dataSetting/dunSetting/useDunSettings";

import { handleAreaConversion } from "utils/helpers";

interface FormBaitulmalRegistrationProps {
  action: "create" | "edit" | "show";
  initialData?: IBaitulmalRegistrationFormValues;
}

const schema = yup
  .object({
    nama_pencadang: yup.string().required(),
    no_fon: yup.number().required(),
    no_kp_baru: yup.string().required(),
    alamat_1: yup.string().required(),
    poskod: yup.string().required(),
    id_negeri: yup.string().required(),
    id_daerah: yup.string().required(),
    id_bandar_pekan_mukim: yup.string().required(),
    id_parlimen: yup.string().required(),
    koordinat: yup.string().required(),
    id_jenis_aset: yup.string().required(),
    luas_petak_m2: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    jum_luas_petak_aks_m2: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    luas_lot_ekar: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    bayaran_jurukur: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    bayaran_daftar_hakmilik: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    cukai_tahunan_rm: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    bayaran_premium: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
  })
  .required();

const FormBaitulmalRegistration: React.FC<FormBaitulmalRegistrationProps> = ({
  action,
  initialData,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    initialData?.senarai_lampiran || [
      {
        id_senarai_lampiran: "",
        tab_name: "cadangan_beli_aset",
        file_name: "",
        file_description: "",
      },
    ]
  );
  const [location, setLocation] = useState<[number, number]>([
    5.3488, 100.4701,
  ]);
  const [bandarOptions, setBandarOptions] = useState<any>([]);
  const [seksyenOptions, setSeksyenOptions] = useState<any>([]);
  const [dunOptions, setDunOptions] = useState<any>([]);

  const { createBaitulmalRegistration, isCreating } =
    useCreateBaitulmalRegistration();
  const { editBaitulmalRegistration, isEditing } =
    useEditBaitulmalRegistration();
  const { sections } = useSectionSettings({ paginate: false });
  const { towns } = useTownSettings({ paginate: false });
  const { duns } = useDunSettings({ paginate: false });

  const history = useHistory();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IBaitulmalRegistrationFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const {
    jenisAsetOps,
    negeriOps,
    daerahOps,
    parlimenOps,
    cadanganBeliAsetStatusOps
  } = useOptions();

  const {
    noFailMainppOps,
    createNoFailMainppOptions,
    isCreatingNoFailMainppOptions,
  } = useDataFile();

  const handleLocationChange = (newLocation: [number, number]) => {
    setLocation(newLocation);
    if (location.length > 0) {
      setValue("koordinat", newLocation.join(","));
    }
  };

  const watchNegeri = watch("id_negeri");
  const watchDaerah = watch("id_daerah");
  const watchBandar = watch("id_bandar_pekan_mukim");
  const watchParlimen = watch("id_parlimen");

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createBaitulmalRegistration({
        newBaitulmalRegistration: {
          ...data,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files,
        },
      });
    } else if (action === "edit") {
      editBaitulmalRegistration({
        newBaitulmalRegistration: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                tab_name: file?.tab_name,
                file_name: file?.file_name,
                file_description: file?.file_description,
              };
            })
          ),
          lampiran: files,
        },
        id: data?.id,
      });
    }
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "luas_petak_m2") {
        const area = handleAreaConversion("m2", value.luas_petak_m2);
        setValue("luas_petak_hektar", area.hektar);
        setValue("luas_petak_ekar", area.ekar);
      } else if (name === "jum_luas_petak_aks_m2") {
        const area = handleAreaConversion("m2", value.jum_luas_petak_aks_m2);
        setValue("jum_luas_petak_aks_hektar", area.hektar);
        setValue("jum_luas_petak_aks_ektar", area.ekar);
      } else if (name === "luas_lot_ekar") {
        const area = handleAreaConversion("ekar", value.luas_lot_ekar);
        setValue("luas_lot_hektar", area.hektar);
        setValue("luas_lot_m2", area.m2);
      } else if (name === "luas_bgnn_ekar") {
        const area = handleAreaConversion("ekar", value.luas_bgnn_ekar);
        setValue("luas_bgnn_hektar", area.hektar);
        setValue("luas_bgnn_m2", area.m2);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "id_daerah" && towns?.data) {
        setValue("id_bandar_pekan_mukim", null);

        const town = towns?.data.filter((town: any) => town.id_daerah == value.id_daerah).map((town: any) => {
          return {
            label: town.nama,
            value: town.id,
          }
        });
        setBandarOptions(town);
      } else if (name === "id_bandar_pekan_mukim" && sections?.data) {
        setValue("id_seksyen", null);

        const section = sections?.data.filter((section: any) => section.id_bandar_pekan_mukim == value.id_bandar_pekan_mukim).map((section: any) => {
          return {
            label: section.nama,
            value: section.id,
          }
        });
        setSeksyenOptions(section);
      } else if (name === "id_parlimen" && duns?.data) {
        setValue("id_dun", null);

        const dun = duns?.data.filter((dun: any) => dun.id_parlimen == value.id_parlimen).map((dun: any) => {
          return {
            label: dun.nama,
            value: dun.id,
          }
        });
        setDunOptions(dun);
      }
    });

    return () => subscription.unsubscribe();
  }, [towns, sections, duns, watch, setValue])

  useEffect(() => {
    if (initialData) {
      if (towns?.data) {
        const town = towns?.data.filter((town: any) => town.id_daerah == initialData.id_daerah).map((town: any) => {
          return {
            label: town.nama,
            value: town.id,
          }
        });
        setBandarOptions(town);
      }

      if (sections?.data) {
        const section = sections?.data.filter((section: any) => section.id_bandar_pekan_mukim == initialData.id_bandar_pekan_mukim).map((section: any) => {
          return {
            label: section.nama,
            value: section.id,
          }
        });
        setSeksyenOptions(section);
      }

      if (duns?.data) {
        const dun = duns?.data.filter((dun: any) => dun.id_parlimen == initialData.id_parlimen).map((dun: any) => {
          return {
            label: dun.nama,
            value: dun.id,
          }
        });
        setDunOptions(dun);
      }
    }
  }, [initialData, towns, sections, duns])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit}>
            <Flex gap="20px" justifyContent={"flex-end"} mb="20px">
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={action === "edit" ? "button" : "reset"}
                onClick={() => {
                  if (action !== "create") {
                    history.push("/admin/baitulmal/permohonan");
                  }
                }}
              >
                {action !== "show" ? "Batal" : "Kembali"}
              </Button>

              {action !== "show" && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit"
                  disabled={isCreating || isEditing}
                >
                  {action === "edit"
                    ? "Kemaskini Cadangan Beli Baitulmal"
                    : "Daftar Cadangan Beli Baitulmal"}
                </Button>
              )}
            </Flex>

            <AssetLink action={action} baitulmalRegistration={initialData} />

            <Section title="Maklumat Permohonan">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                {action !== "create" && (
                  <SelectField
                    label={"Status Permohonan"}
                    options={cadanganBeliAsetStatusOps}
                    isInvalid={!!errors.status}
                    errorMessage={errors.status?.message}
                    readOnly={action === "show"}
                    {...register("status")}
                  />
                )}

                <TextField
                  id="nama_pencadang"
                  label="Nama Individu/Syarikat"
                  placeholder="Ramli Saad"
                  isInvalid={!!errors.nama_pencadang}
                  errorMessage={errors.nama_pencadang?.message}
                  readOnly={action === "show"}
                  {...register("nama_pencadang")}
                />

                <TextField
                  id="no_fon"
                  label="No. Telefon Individu/Syarikat"
                  isInvalid={!!errors.no_fon}
                  errorMessage={errors.no_fon?.message}
                  readOnly={action === "show"}
                  {...register("no_fon")}
                />

                <TextField
                  id="no_kp_baru"
                  label="No. Kad Pengenalan Baru"
                  maxLength={12}
                  isInvalid={!!errors.no_kp_baru}
                  errorMessage={errors.no_kp_baru?.message}
                  readOnly={action === "show"}
                  {...register("no_kp_baru")}
                />

                <TextField
                  id="no_kp_lama"
                  label="No. Kad Pengenalan Lama"
                  isInvalid={!!errors.no_kp_lama}
                  errorMessage={errors.no_kp_lama?.message}
                  readOnly={action === "show"}
                  {...register("no_kp_lama")}
                />

                <TextField
                  id="no_kp_polis"
                  label="No. Kad Pengenalan Polis"
                  isInvalid={!!errors.no_kp_polis}
                  errorMessage={errors.no_kp_polis?.message}
                  readOnly={action === "show"}
                  {...register("no_kp_polis")}
                />

                <TextField
                  id="no_kp_tentera"
                  label="No. Kad Pengenalan Tentera"
                  isInvalid={!!errors.no_kp_tentera}
                  errorMessage={errors.no_kp_tentera?.message}
                  readOnly={action === "show"}
                  {...register("no_kp_tentera")}
                />

                <TextField
                  id="jawatan_pencadang"
                  label="Jawatan Individu/Syarikat"
                  type="textarea"
                  isInvalid={!!errors.jawatan_pencadang}
                  errorMessage={errors.jawatan_pencadang?.message}
                  readOnly={action === "show"}
                  {...register("jawatan_pencadang")}
                />

                <TextField
                  id="unit_jabatan_pencadang"
                  label="Unit Jabatan Individu/Syarikat"
                  type="textarea"
                  isInvalid={!!errors.unit_jabatan_pencadang}
                  errorMessage={errors.unit_jabatan_pencadang?.message}
                  readOnly={action === "show"}
                  {...register("unit_jabatan_pencadang")}
                />

                <CreatableSelectField
                  label={"No. Fail MAINPP"}
                  defaultOptions={noFailMainppOps}
                  defaultValue={initialData?.id_no_fail_mainpp}
                  onChange={(value) => setValue("id_no_fail_mainpp", value)}
                  onCreateOption={(value) =>
                    createNoFailMainppOptions({
                      newOption: { no_fail_main_pp: value },
                      id: null,
                    })
                  }
                  isLoading={isCreatingNoFailMainppOptions}
                  isInvalid={!!errors.id_no_fail_mainpp}
                  errorMessage={errors.id_no_fail_mainpp?.message}
                  readOnly={action === "show"}
                />
              </SimpleGrid>
            </Section>

            <Section title="Maklumat Aset">
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <SelectField
                  label={"Jenis Aset"}
                  options={jenisAsetOps}
                  isInvalid={!!errors.id_jenis_aset}
                  errorMessage={errors.id_jenis_aset?.message}
                  readOnly={action === "show"}
                  {...register("id_jenis_aset")}
                />

                <TextField
                  id="tujuan_cadangan"
                  label="Tujuan Cadangan"
                  isInvalid={!!errors.tujuan_cadangan}
                  errorMessage={errors.tujuan_cadangan?.message}
                  readOnly={action === "show"}
                  {...register("tujuan_cadangan")}
                />

                <TextField
                  id="no_hak_milik"
                  label="No. Hak Milik"
                  placeholder="GRN 169578"
                  isInvalid={!!errors.no_hak_milik}
                  errorMessage={errors.no_hak_milik?.message}
                  readOnly={action === "show"}
                  {...register("no_hak_milik")}
                />

                <TextField
                  id="no_hak_milik_sementara"
                  label="No. Hakmilik Sementara"
                  placeholder="HSM 771 Mukim 01"
                  isInvalid={!!errors.no_hak_milik_sementara}
                  errorMessage={errors.no_hak_milik_sementara?.message}
                  readOnly={action === "show"}
                  {...register("no_hak_milik_sementara")}
                />

                <TextField
                  id="no_lot"
                  label="No. Lot"
                  placeholder="10066 7 DIGIT NUMBER"
                  isInvalid={!!errors.no_lot}
                  errorMessage={errors.no_lot?.message}
                  readOnly={action === "show"}
                  {...register("no_lot")}
                />

                <TextField
                  id="no_pt"
                  label="No. PT"
                  placeholder="PT 42"
                  isInvalid={!!errors.no_pt}
                  errorMessage={errors.no_pt?.message}
                  readOnly={action === "show"}
                  {...register("no_pt")}
                />

                <TextField
                  id="no_petak"
                  label="No. Petak"
                  placeholder="3, L2, L1, L25"
                  isInvalid={!!errors.no_petak}
                  errorMessage={errors.no_petak?.message}
                  readOnly={action === "show"}
                  {...register("no_petak")}
                />

                <TextField
                  id="petak_aksesori"
                  label="Petak Aksesori"
                  placeholder="A66,A69"
                  isInvalid={!!errors.petak_aksesori}
                  errorMessage={errors.petak_aksesori?.message}
                  readOnly={action === "show"}
                  {...register("petak_aksesori")}
                />

                <TextField
                  id="unit_syer_petak"
                  label="Unit Syer Petak"
                  placeholder="109"
                  isInvalid={!!errors.unit_syer_petak}
                  errorMessage={errors.unit_syer_petak?.message}
                  readOnly={action === "show"}
                  {...register("unit_syer_petak")}
                />

                <TextField
                  id="no_tingkat"
                  label="No. Tingkat"
                  placeholder="2, 14, 79"
                  isInvalid={!!errors.no_tingkat}
                  errorMessage={errors.no_tingkat?.message}
                  readOnly={action === "show"}
                  {...register("no_tingkat")}
                />

                <TextField
                  id="no_pelan_diperakui"
                  label="No. Pelan Diperakui"
                  placeholder="I-D-IV"
                  isInvalid={!!errors.no_pelan_diperakui}
                  errorMessage={errors.no_pelan_diperakui?.message}
                  readOnly={action === "show"}
                  {...register("no_pelan_diperakui")}
                />

                <TextField
                  id="no_buku_daftar_strata"
                  label="No. Buku Daftar Strata"
                  placeholder="2/2020"
                  isInvalid={!!errors.no_buku_daftar_strata}
                  errorMessage={errors.no_buku_daftar_strata?.message}
                  readOnly={action === "show"}
                  {...register("no_buku_daftar_strata")}
                />

                <TextField
                  id="tarikh_daftar_geran"
                  label="Tarikh Daftar Geran"
                  type="date"
                  isInvalid={!!errors.tarikh_daftar_geran}
                  errorMessage={errors.tarikh_daftar_geran?.message}
                  readOnly={action === "show"}
                  {...register("tarikh_daftar_geran")}
                />

                <TextField
                  id="alamat_1"
                  label="Alamat 1"
                  placeholder="LOT 2449,Jalan Air Itam"
                  isInvalid={!!errors.alamat_1}
                  errorMessage={errors.alamat_1?.message}
                  readOnly={action === "show"}
                  {...register("alamat_1")}
                />

                <TextField
                  id="alamat_2"
                  label="Alamat 2"
                  placeholder="Kampung Pisang"
                  isInvalid={!!errors.alamat_2}
                  errorMessage={errors.alamat_2?.message}
                  readOnly={action === "show"}
                  {...register("alamat_2")}
                />

                <TextField
                  id="alamat_3"
                  label="Alamat 3"
                  isInvalid={!!errors.alamat_3}
                  errorMessage={errors.alamat_3?.message}
                  readOnly={action === "show"}
                  {...register("alamat_3")}
                />

                <TextField
                  id="poskod"
                  label="Poskod"
                  placeholder="11500"
                  isInvalid={!!errors.poskod}
                  errorMessage={errors.poskod?.message}
                  readOnly={action === "show"}
                  {...register("poskod")}
                />

                <SelectField
                  label={"Negeri"}
                  options={negeriOps}
                  isInvalid={!!errors.id_negeri}
                  errorMessage={errors.id_negeri?.message}
                  readOnly={action === "show"}
                  {...register("id_negeri")}
                />

                <SelectField
                  label={"Daerah"}
                  options={daerahOps}
                  isDisabled={!watchNegeri}
                  isInvalid={!!errors.id_daerah}
                  errorMessage={errors.id_daerah?.message}
                  readOnly={action === "show"}
                  {...register("id_daerah")}
                />

                <SelectField
                  label={"Bandar/Mukim"}
                  options={bandarOptions}
                  isDisabled={!watchDaerah}
                  isInvalid={!!errors.id_bandar_pekan_mukim}
                  errorMessage={errors.id_bandar_pekan_mukim?.message}
                  readOnly={action === "show"}
                  {...register("id_bandar_pekan_mukim")}
                />

                <SelectField
                  label={"Seksyen"}
                  options={seksyenOptions}
                  isDisabled={!watchBandar}
                  isInvalid={!!errors.id_seksyen}
                  errorMessage={errors.id_seksyen?.message}
                  readOnly={action === "show"}
                  {...register("id_seksyen")}
                />

                <SelectField
                  label={"Parlimen"}
                  options={parlimenOps}
                  isInvalid={!!errors.id_parlimen}
                  errorMessage={errors.id_parlimen?.message}
                  readOnly={action === "show"}
                  {...register("id_parlimen")}
                />

                <SelectField
                  label={"Dun"}
                  options={dunOptions}
                  isDisabled={!watchParlimen}
                  isInvalid={!!errors.id_dun}
                  errorMessage={errors.id_dun?.message}
                  readOnly={action === "show"}
                  {...register("id_dun")}
                />

                <GridItem
                  colSpan={2}
                >
                  <FormControl>
                    <FormLabel variant="main" mb="8px">
                      Lokasi
                    </FormLabel>

                    <MapLocator
                      onLocationChange={handleLocationChange}
                      readOnly={action === "show"}
                    />
                  </FormControl>
                </GridItem>

                <GridItem
                  display={"grid"}
                  colSpan={2}
                  gap={{ sm: "6px", md: "18px" }}
                >
                  <FormControl>
                    <FormLabel variant="main" mb="8px">
                      Luas Petak
                    </FormLabel>

                    <SimpleGrid
                      columns={{ base: 3 }}
                      gap={{ sm: "6px", md: "18px" }}
                    >
                      <NumberGroupField
                        id="luas_petak_hektar"
                        label="Hektar"
                        step="any"
                        isInvalid={!!errors.luas_petak_hektar}
                        errorMessage={errors.luas_petak_hektar?.message}
                        readOnly={action === "show"}
                        disabled
                        {...register("luas_petak_hektar")}
                      />

                      <NumberGroupField
                        id="luas_petak_ekar"
                        label="Ekar"
                        step="any"
                        isInvalid={!!errors.luas_petak_ekar}
                        errorMessage={errors.luas_petak_ekar?.message}
                        readOnly={action === "show"}
                        disabled
                        {...register("luas_petak_ekar")}
                      />

                      <NumberGroupField
                        id="luas_petak_m2"
                        label="Meter Persegi"
                        labelWidth={"160px"}
                        step="any"
                        isInvalid={!!errors.luas_petak_m2}
                        errorMessage={errors.luas_petak_m2?.message}
                        readOnly={action === "show"}
                        {...register("luas_petak_m2")}
                      />
                    </SimpleGrid>
                  </FormControl>

                  <FormControl>
                    <FormLabel variant="main" mb="8px">
                      Jumlah Luas Petak Aksesori
                    </FormLabel>

                    <SimpleGrid
                      columns={{ base: 3 }}
                      gap={{ sm: "6px", md: "18px" }}
                    >
                      <NumberGroupField
                        id="jum_luas_petak_aks_hektar"
                        label="Hektar"
                        step="any"
                        isInvalid={!!errors.jum_luas_petak_aks_hektar}
                        errorMessage={errors.jum_luas_petak_aks_hektar?.message}
                        readOnly={action === "show"}
                        disabled
                        {...register("jum_luas_petak_aks_hektar")}
                      />

                      <NumberGroupField
                        id="jum_luas_petak_aks_ektar"
                        label="Ekar"
                        step="any"
                        isInvalid={!!errors.jum_luas_petak_aks_ektar}
                        errorMessage={errors.jum_luas_petak_aks_ektar?.message}
                        readOnly={action === "show"}
                        disabled
                        {...register("jum_luas_petak_aks_ektar")}
                      />

                      <NumberGroupField
                        id="jum_luas_petak_aks_m2"
                        label="Meter Persegi"
                        labelWidth={"160px"}
                        step="any"
                        isInvalid={!!errors.jum_luas_petak_aks_m2}
                        errorMessage={errors.jum_luas_petak_aks_m2?.message}
                        readOnly={action === "show"}
                        {...register("jum_luas_petak_aks_m2")}
                      />
                    </SimpleGrid>
                  </FormControl>

                  <FormControl>
                    <FormLabel variant="main" mb="8px">
                      Keluasan Lot
                    </FormLabel>

                    <SimpleGrid
                      columns={{ base: 3 }}
                      gap={{ sm: "6px", md: "18px" }}
                    >
                      <NumberGroupField
                        id="luas_lot_hektar"
                        label="Hektar"
                        step="any"
                        isInvalid={!!errors.luas_lot_hektar}
                        errorMessage={errors.luas_lot_hektar?.message}
                        readOnly={action === "show"}
                        disabled
                        {...register("luas_lot_hektar")}
                      />

                      <NumberGroupField
                        id="luas_lot_ekar"
                        label="Ekar"
                        step="any"
                        isInvalid={!!errors.luas_lot_ekar}
                        errorMessage={errors.luas_lot_ekar?.message}
                        readOnly={action === "show"}
                        {...register("luas_lot_ekar")}
                      />

                      <NumberGroupField
                        id="luas_lot_m2"
                        label="Meter Persegi"
                        labelWidth={"160px"}
                        step="any"
                        isInvalid={!!errors.luas_lot_m2}
                        errorMessage={errors.luas_lot_m2?.message}
                        readOnly={action === "show"}
                        disabled
                        {...register("luas_lot_m2")}
                      />
                    </SimpleGrid>
                  </FormControl>

                  <FormControl>
                    <FormLabel variant="main" mb="8px">
                      Luas Bangunan
                    </FormLabel>

                    <SimpleGrid
                      columns={{ base: 3 }}
                      gap={{ sm: "6px", md: "18px" }}
                    >
                      <NumberGroupField
                        id="luas_bgnn_hektar"
                        label="Hektar"
                        step="any"
                        isInvalid={!!errors.luas_bgnn_hektar}
                        errorMessage={errors.luas_bgnn_hektar?.message}
                        readOnly={action === "show"}
                        disabled
                        {...register("luas_bgnn_hektar")}
                      />

                      <NumberGroupField
                        id="luas_bgnn_ekar"
                        label="Ekar"
                        step="any"
                        isInvalid={!!errors.luas_bgnn_ekar}
                        errorMessage={errors.luas_bgnn_ekar?.message}
                        readOnly={action === "show"}
                        {...register("luas_bgnn_ekar")}
                      />

                      <NumberGroupField
                        id="luas_bgnn_m2"
                        label="Meter Persegi"
                        labelWidth={"160px"}
                        step="any"
                        isInvalid={!!errors.luas_bgnn_m2}
                        errorMessage={errors.luas_bgnn_m2?.message}
                        readOnly={action === "show"}
                        disabled
                        {...register("luas_bgnn_m2")}
                      />
                    </SimpleGrid>
                  </FormControl>
                </GridItem>

                <TextField
                  id="maklumat_cadangan"
                  label="Maklumat Cadangan"
                  placeholder="808.00 Meter Persegi ,11 ekar,11 kaki"
                  isInvalid={!!errors.maklumat_cadangan}
                  errorMessage={errors.maklumat_cadangan?.message}
                  readOnly={action === "show"}
                  {...register("maklumat_cadangan")}
                />

                <TextField
                  id="maklumat_pajakan"
                  label="Maklumat Pajakan"
                  placeholder="1/4"
                  isInvalid={!!errors.maklumat_pajakan}
                  errorMessage={errors.maklumat_pajakan?.message}
                  readOnly={action === "show"}
                  {...register("maklumat_pajakan")}
                />

                <TextField
                  id="sekatan_kepentingan"
                  label="Sekatan - sekatan Kepentingan"
                  type="textarea"
                  isInvalid={!!errors.sekatan_kepentingan}
                  errorMessage={errors.sekatan_kepentingan?.message}
                  readOnly={action === "show"}
                  {...register("sekatan_kepentingan")}
                />

                <TextField
                  id="syarat_nyata"
                  label="Syarat-Syarat Nyata Tanah"
                  type="textarea"
                  isInvalid={!!errors.syarat_nyata}
                  errorMessage={errors.syarat_nyata?.message}
                  readOnly={action === "show"}
                  {...register("syarat_nyata")}
                />

                <FormControl>
                  <FormLabel variant="main" mb="8px">
                    Bayaran Berimilik
                  </FormLabel>

                  <NumberGroupField
                    label="RM"
                    step="any"
                    isInvalid={!!errors.bayaran_berimilik}
                    errorMessage={errors.bayaran_berimilik?.message}
                    readOnly={action === "show"}
                    {...register("bayaran_berimilik")}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel variant="main" mb="8px">
                    Bayaran CP Pelan
                  </FormLabel>

                  <NumberGroupField
                    label="RM"
                    step="any"
                    isInvalid={!!errors.bayaran_cp_plan}
                    errorMessage={errors.bayaran_cp_plan?.message}
                    readOnly={action === "show"}
                    {...register("bayaran_cp_plan")}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel variant="main" mb="8px">
                    Bayaran Jurukur
                  </FormLabel>

                  <NumberGroupField
                    label="RM"
                    step="any"
                    isInvalid={!!errors.bayaran_jurukur}
                    errorMessage={errors.bayaran_jurukur?.message}
                    readOnly={action === "show"}
                    {...register("bayaran_jurukur")}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel variant="main" mb="8px">
                    Bayaran Daftar Hakmilik
                  </FormLabel>

                  <NumberGroupField
                    label="RM"
                    step="any"
                    isInvalid={!!errors.bayaran_daftar_hakmilik}
                    errorMessage={errors.bayaran_daftar_hakmilik?.message}
                    readOnly={action === "show"}
                    {...register("bayaran_daftar_hakmilik")}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel variant="main" mb="8px">
                    Cukai Tahunan
                  </FormLabel>

                  <NumberGroupField
                    label="RM"
                    step="any"
                    isInvalid={!!errors.cukai_tahun_semasa_daftar}
                    errorMessage={errors.cukai_tahun_semasa_daftar?.message}
                    readOnly={action === "show"}
                    {...register("cukai_tahun_semasa_daftar")}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel variant="main" mb="8px">
                    Bayaran Premium
                  </FormLabel>

                  <NumberGroupField
                    label="RM"
                    step="any"
                    isInvalid={!!errors.bayaran_premium}
                    errorMessage={errors.bayaran_premium?.message}
                    readOnly={action === "show"}
                    {...register("bayaran_premium")}
                  />
                </FormControl>

                <GridItem
                  display={"grid"}
                  colSpan={2}
                  gap={{ sm: "6px", md: "18px" }}
                >
                  <FormControl>
                    <FormLabel variant="main" mb="8px">
                      UPI
                    </FormLabel>

                    <SimpleGrid
                      columns={{ base: 3 }}
                      gap={{ sm: "6px", md: "18px" }}
                    >
                      <LinkGroupField
                        label="UPI JUPEM LINK"
                        labelWidth={"180px"}
                        readOnly={action === "show"}
                        disabled
                        {...register("upi_jupem")}
                      />

                      <LinkGroupField
                        label="UPI PT LINK"
                        labelWidth={"180px"}
                        readOnly={action === "show"}
                        disabled
                        {...register("upi_pt")}
                      />

                      <LinkGroupField
                        label="UPI STRATA LINK"
                        labelWidth={"180px"}
                        readOnly={action === "show"}
                        disabled
                        {...register("upi_strata")}
                      />
                    </SimpleGrid>
                  </FormControl>
                </GridItem>
              </Grid>
            </Section>

            <Section title="Lampiran Permohonan">
              <FileUploadField
                files={files}
                fileDescriptions={fileDescriptions}
                setFiles={setFiles}
                setFileDescriptions={setFileDescriptions}
                tabName="cadangan_beli_aset"
                readOnly={action === "show"}
              />
            </Section>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormBaitulmalRegistration;
