import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Grid,
  SimpleGrid,
  GridItem,
  Button,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import Section from "components/section/Section";
import NumberGroupField from "components/fields/NumberGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";
import SearchAssetIdField from "./SearchAssetIdField";

import { IWakafIstibdalInfoFormValues } from "entities/admin/wakafManagement";
import { useCreateWakafIstibdalInfo } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalInfo/useCreateWakafIstibdalInfo";
import { useEditWakafIstibdalInfo } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalInfo/useEditWakafIstibdalAssessment";

import { formatDate, handleAreaConversion } from "utils/helpers";
import SelectField from "components/fields/SelectField";

interface FormPurchaseInfoProps {
  action: "create" | "edit" | "show";
  wakafAssetId?: number;
  wakafIstibdalAssessmentId?: number;
  initialData?: IWakafIstibdalInfoFormValues;
}

const schema = yup
  .object({
    no_resit_rasmi: yup.string().required(),
    luas_tanah_istibdal_m2: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
    jum_istibdal_pembelian_rm: yup
      .number()
      .positive()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .optional()
      .nullable(),
  })
  .required();

const FormPurchaseInfo: React.FC<FormPurchaseInfoProps> = ({
  action,
  wakafAssetId,
  wakafIstibdalAssessmentId,
  initialData,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    initialData?.senarai_lampiran || [
      {
        id_senarai_lampiran: "",
        tab_name: "aset_wakaf_maklumat_istibdal",
        file_name: "",
        file_description: "",
      },
    ]
  );

  const history = useHistory();
  const { createWakafIstibdalInfo, isCreating } = useCreateWakafIstibdalInfo();
  const { editWakafIstibdalInfo, isEditing } = useEditWakafIstibdalInfo();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<IWakafIstibdalInfoFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createWakafIstibdalInfo({
        newWakafIstibdalInfo: {
          ...data,
          jenis_istibdal: "1",
          status_istibdal: "0",
          id_pengambilan_penilaian_istibdal: wakafIstibdalAssessmentId,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files,
        },
      });
    } else if (action === "edit") {
      editWakafIstibdalInfo({
        newWakafIstibdalInfo: {
          ...data,
          jenis_istibdal: "1",
          id_pengambilan_penilaian_istibdal: wakafIstibdalAssessmentId,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                file_name: file?.file_name,
                file_description: file?.file_description,
                tab_name: "aset_wakaf_maklumat_istibdal",
              };
            })
          ),
          lampiran: files,
        },
        id: initialData?.id,
      });
    }
  });

  useEffect(() => {
    const defaultValues: any = {
      no_resit_rasmi: "",
      terima_daripada: "",
      luas_tanah_istibdal_hektar: null,
      luas_tanah_istibdal_ektar: null,
      luas_tanah_istibdal_m2: null,
      luas_tanah_istibdal_kp2: null,
      jum_istibdal_pembelian_rm: null,
      perihal_terimaan: "",
      maklumat_pembayaran: "",
      tarikh_borang_14A: "",
      tarikh_resit_rasmi: "",
      penerima_balasan: "",
      amaun_istibdal_rm: null,
      tarikh_bayaran: "",
      perihal_balasan: "",
      senarai_lampiran: [],
    };

    if (action === "create") {
      setFileDescriptions([]);
      reset(defaultValues);
    } else {
      reset({
        ...initialData,
        tarikh_borang_14A: formatDate(initialData?.tarikh_borang_14A),
        tarikh_resit_rasmi: formatDate(initialData?.tarikh_resit_rasmi),
      });
    }
  }, [action, initialData, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "luas_tanah_istibdal_m2") {
        const area = handleAreaConversion("m2", value.luas_tanah_istibdal_m2);

        setValue("luas_tanah_istibdal_hektar", area.hektar, {
          shouldValidate: false,
        });
        setValue("luas_tanah_istibdal_ektar", area.ekar, {
          shouldValidate: false,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <Flex mt="30px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={action === "create" ? "reset" : "button"}
            onClick={() => {
              if (action !== "create") {
                history.push("/admin/wakaf/aset");
              }
            }}
          >
            Batal
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            disabled={isCreating || isEditing}
          >
            {action === "edit" ? "Kemaskini Istibdal" : "Daftar Istibdal"}
          </Button>
        </Flex>

        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <TextField
            id="no_resit_rasmi"
            label="No. Resit Rasmi"
            isInvalid={!!errors.no_resit_rasmi}
            errorMessage={errors.no_resit_rasmi?.message}
            readOnly={action === "show"}
            {...register("no_resit_rasmi")}
          />

          <TextField
            id="terima_daripada"
            label="Terima Daripada"
            isInvalid={!!errors.terima_daripada}
            errorMessage={errors.terima_daripada?.message}
            readOnly={action === "show"}
            {...register("terima_daripada")}
          />

          <GridItem
            display={"grid"}
            colSpan={2}
            gap={{ sm: "6px", md: "18px" }}
          >
            <FormControl>
              <FormLabel variant="main" mb="8px">
                Keluasan Tanah Istibdal
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="luas_tanah_istibdal_hektar"
                  label="Hektar"
                  step="any"
                  isInvalid={!!errors.luas_tanah_istibdal_hektar}
                  errorMessage={errors.luas_tanah_istibdal_hektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_tanah_istibdal_hektar")}
                />

                <NumberGroupField
                  id="luas_tanah_istibdal_ektar"
                  label="Ekar"
                  step="any"
                  isInvalid={!!errors.luas_tanah_istibdal_ektar}
                  errorMessage={errors.luas_tanah_istibdal_ektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_tanah_istibdal_ektar")}
                />

                <NumberGroupField
                  id="luas_tanah_istibdal_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  step="any"
                  isInvalid={!!errors.luas_tanah_istibdal_m2}
                  errorMessage={errors.luas_tanah_istibdal_m2?.message}
                  readOnly={action === "show"}
                  {...register("luas_tanah_istibdal_m2")}
                />
              </SimpleGrid>
            </FormControl>
          </GridItem>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Jumlah (RM) Istibdal Melalui Pembelian
            </FormLabel>

            <NumberGroupField
              id="jum_istibdal_pembelian_rm"
              label="RM"
              step="any"
              isInvalid={!!errors.jum_istibdal_pembelian_rm}
              errorMessage={errors.jum_istibdal_pembelian_rm?.message}
              readOnly={action === "show"}
              {...register("jum_istibdal_pembelian_rm")}
            />
          </FormControl>

          <TextField
            id="perihal_terimaan"
            label="Perihal Bayaran"
            type="textarea"
            isInvalid={!!errors.perihal_terimaan}
            errorMessage={errors.perihal_terimaan?.message}
            readOnly={action === "show"}
            {...register("perihal_terimaan")}
          />

          <SelectField
            label={"Maklumat Pembayaran"}
            options={[
              {
                label: "Tunai",
                value: "Tunai",
              },
              {
                label: "EFT",
                value: "EFT",
              },
              {
                label: "FPX",
                value: "FPX",
              },
              {
                label: "Cek",
                value: "Cek",
              },
            ]}
            id="maklumat_pembayaran"
            isInvalid={!!errors.maklumat_pembayaran}
            errorMessage={errors.maklumat_pembayaran?.message}
            readOnly={action === "show"}
            {...register("maklumat_pembayaran")}
          />

          <TextField
            id="tarikh_borang_14A"
            label="Tarikh Borang 14A"
            type="date"
            isInvalid={!!errors.tarikh_borang_14A}
            errorMessage={errors.tarikh_borang_14A?.message}
            readOnly={action === "show"}
            {...register("tarikh_borang_14A")}
          />

          <TextField
            id="tarikh_resit_rasmi"
            label="Tarikh Resit Rasmi"
            type="date"
            isInvalid={!!errors.tarikh_resit_rasmi}
            errorMessage={errors.tarikh_resit_rasmi?.message}
            readOnly={action === "show"}
            {...register("tarikh_resit_rasmi")}
          />

          <SearchAssetIdField
            defaultValue={initialData?.id_aset_wakaf_baru}
            onChange={(value) => setValue("id_aset_wakaf_baru", value)}
          />
        </Grid>

        <Section size="md" title="Lampiran Istibdal Melalui Pembelian">
          <FileUploadField
            files={files}
            setFiles={setFiles}
            fileDescriptions={fileDescriptions}
            setFileDescriptions={setFileDescriptions}
            tabName="aset_wakaf_maklumat_istibdal"
            readOnly={action === "show"}
          />
        </Section>
      </form>
    </Flex>
  );
};

export default FormPurchaseInfo;
