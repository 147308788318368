import React from "react";
import FormOrganizationSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useOrganizationSetting } from "hooks/setting/dataSetting/organizationSetting/useOrganizationSetting";

export default function EditDepartmentSetting() {
  const { isLoading, organization, error } = useOrganizationSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormOrganizationSetting action="edit" initialData={organization?.data} />;
}
