import React from "react";
import FormUser from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useUser } from "hooks/setting/user/useUser";

export default function ShowUser() {
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <FullScreenLoader />
  }

  return <FormUser action="show" initialData={user?.data} />;
}
