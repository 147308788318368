import React, { useState, useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import FormAcquisitionLand from "./Form/FormAcquisitionLand";
import FormCancellingLand from "./Form/FormCancellingLand";
import FormValuation from "./Form/FormValuation";
import IstibdalInfoSummary from "./IstibdalInfoSummary";
import TableIstibdalInfo from "./TableIstibdal";

import { IWakafIstibdalAssessmentFormValues } from "entities/admin/wakafManagement";

interface TabIstibdalProps {
  wakafAssetId?: number;
  wakafIstibdalAssessment?: IWakafIstibdalAssessmentFormValues;
  assetArea?: {
    luas_hektar: number;
    luas_ekar: number;
    luas_m2: number;
    luas_kp2: number;
  };
  istibdalSummary?: {
    jumlah_pengambilan: number;
    jumlah_istibdal_terkumpul: number;
    baki_belum_diganti: number;
  };
}

const TabIstibdal = ({
  wakafAssetId,
  wakafIstibdalAssessment,
  assetArea,
  istibdalSummary,
}: TabIstibdalProps) => {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const [acquisitionType, setAcquisitionType] = useState("1");

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("show")) {
      setAction("show");
    } else if (wakafIstibdalAssessment) {
      setAction("edit");
    } else {
      setAction("create");
    }
  }, [wakafIstibdalAssessment, location.pathname]);

  useEffect(() => {
    if (wakafIstibdalAssessment?.jenis_pengambilan == "1") {
      setAcquisitionType("1");
    } else if (wakafIstibdalAssessment?.jenis_pengambilan == "2") {
      setAcquisitionType("2");
    }
  }, [wakafIstibdalAssessment?.jenis_pengambilan]);

  return (
    <Box px="25px">
      <Accordion variant="main" allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={"semibold"}>
                Maklumat Pengambilan Istibdal
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <FormControl display={"flex"} flexDirection={"row"}>
              <FormLabel mr={"80px"} as="legend">
                Jenis Pengambilan
              </FormLabel>

              <RadioGroup
                onChange={(value) => setAcquisitionType(value)}
                value={acquisitionType}
                isDisabled={!!wakafIstibdalAssessment}
              >
                <Stack direction={"row"} spacing={"18px"}>
                  <Radio value={"1"}>Pengambilan Balik Tanah</Radio>
                  <Radio value={"2"}>Harus Penjualan Tanah</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            {acquisitionType === "1" ? (
              <FormAcquisitionLand
                action={action}
                wakafAssetId={wakafAssetId}
                acquisitionType={acquisitionType}
                initialData={{
                  ...wakafIstibdalAssessment,
                  senarai_lampiran: wakafIstibdalAssessment?.senarai_lampiran.filter(
                    (file: any) => file.tab_name === "aset_wakaf_istibdal_pengambilan"
                  ),
                }}
                assetArea={assetArea}
              />
            ) : (
              <FormCancellingLand
                action={action}
                wakafAssetId={wakafAssetId}
                acquisitionType={acquisitionType}
                initialData={{
                  ...wakafIstibdalAssessment,
                  senarai_lampiran: wakafIstibdalAssessment?.senarai_lampiran.filter(
                    (file: any) => file.tab_name === "aset_wakaf_istibdal_pengambilan"
                  ),
                }}
              />
            )}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem isDisabled={!wakafIstibdalAssessment}>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={"semibold"}>
                Maklumat Penilaian Istibdal
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <FormValuation
              action="edit"
              wakafAssetId={wakafAssetId}
              initialData={{
                ...wakafIstibdalAssessment,
                senarai_lampiran: wakafIstibdalAssessment?.senarai_lampiran.filter(
                  (file: any) => file.tab_name === "aset_wakaf_istibdal_penilaian"
                ),
              }}
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem
          isDisabled={!wakafIstibdalAssessment?.user_created_penilaian}
        >
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={"semibold"}>
                Maklumat Istibdal
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <IstibdalInfoSummary
              istibdalSummary={istibdalSummary}
              istibdalStatus={wakafIstibdalAssessment?.status_istibdal}
              wakafAssetId={wakafAssetId}
              wakafIstibdalAssessmentId={wakafIstibdalAssessment?.id}
            />
            
            <TableIstibdalInfo
              wakafAssetId={wakafAssetId}
              wakafIstibdalAssessmentId={wakafIstibdalAssessment?.id}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default TabIstibdal;
