import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
} from "@chakra-ui/react";

import Section from "components/section/Section";
import NumberGroupField from "components/fields/NumberGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";
import CreatableSelectField from "components/fields/CreatableSelectField";
import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/dataSetting/useOptions";
import useDataFile from "hooks/setting/dataSetting/useDataFile";

import { IBaitulmalBuybackFormValues } from "entities/admin/baitulmalManagement";
import { useCreateBaitulmalBuyback } from "hooks/admin/baitulmalManagement/baitulmalBuyback/useCreateBaitulmalBuyback";
import { useEditBaitulmalBuyback } from "hooks/admin/baitulmalManagement/baitulmalBuyback/useEditBaitulmalBuyback";
import { useBaitulmalBuyback } from "hooks/admin/baitulmalManagement/baitulmalBuyback/useBaitulmalBuyback";

interface FormBuybackProps {
  action: "create" | "edit" | "show";
  baitulmalAssetId?: number;
  baitulmalBuybackId?: number;
}

const schema = yup
  .object({
    nama_pemohon: yup.string().required(),
    pendapatan: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    harga_nilaian: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    harga_jualan: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
    jumlah_bayaran: yup
    .number()
    .positive()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .optional()
    .nullable(),
  })
  .required();

const FormBuyback: React.FC<FormBuybackProps> = ({
  action,
  baitulmalAssetId,
  baitulmalBuybackId,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>([
    {
      id_senarai_lampiran: "",
      tab_name: "aset_baitulmal_mohon_beli_balik",
      file_name: "",
      file_description: "",
    },
  ]);

  const history = useHistory();
  const { createBaitulmalBuyback, isCreating } = useCreateBaitulmalBuyback();
  const { editBaitulmalBuyback, isEditing } = useEditBaitulmalBuyback();
  const { baitulmalBuyback, isInitialLoading } =
    useBaitulmalBuyback(baitulmalBuybackId);

  const { statusBayaranOps } = useOptions();

  const {
    noFailMainppOps,
    createNoFailMainppOptions,
    isCreatingNoFailMainppOptions,
  } = useDataFile();

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IBaitulmalBuybackFormValues>({
    resolver: yupResolver(schema) as any,
  });

  const renderTitle = (action: string) => {
    if (action === "create") {
      return "Tambah Mohon Beli Balik";
    } else if (action === "edit") {
      return "Kemaskini Mohon Beli Balik";
    } else if (action === "show") {
      return "Rekod Mohon Beli Balik";
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createBaitulmalBuyback({
        newBaitulmalBuyback: {
          ...data,
          id_aset_baitulmal: baitulmalAssetId,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files,
        },
      });
    } else if (action === "edit") {
      editBaitulmalBuyback({
        newBaitulmalBuyback: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                tab_name: file?.tab_name,
                file_name: file?.file_name,
                file_description: file?.file_description,
              };
            })
          ),
          lampiran: files,
        },
        id: baitulmalBuybackId,
      });
    }
  });

  useEffect(() => {
    const defaultValues: any = {
      nama_pemohon: "",
      senarai_lampiran: [],
    };

    if (action === "create") {
      setFileDescriptions([]);
      reset(defaultValues);
    } else if (baitulmalBuyback?.data) {
      reset(baitulmalBuyback.data);
    }
  }, [action, baitulmalBuyback?.data, reset]);

  useEffect(() => {
    if (baitulmalBuyback?.data?.senarai_lampiran) {
      setFileDescriptions(baitulmalBuyback?.data?.senarai_lampiran.filter(
        (file: any) => file.tab_name === "aset_baitulmal_mohon_beli_balik"
      ));
    }

    return () => {
      setFileDescriptions([]);
    };
  }, [baitulmalBuyback?.data]);

  if (isInitialLoading) return <FullScreenLoader />;

  return (
    <Flex
      key={baitulmalBuybackId}
      px="25px"
      mt="10px"
      mb="1.5rem"
      flexDirection={"column"}
    >
      <Section size={"xl"} title={renderTitle(action)}>
        <form onSubmit={onSubmit}>
          <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
            <Button
              variant="outline"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              colorScheme="gray"
              type={action === "create" ? "reset" : "button"}
              onClick={() => {
                if (action !== "create") {
                  history.push("/admin/baitulmal/aset");
                }
              }}
            >
              {action === "create" ? "Batal" : "Kembali"}
            </Button>

            <Button
              variant="brand"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              type="submit"
              disabled={isCreating || isEditing}
            >
              {action === "edit"
                ? "Kemaskini Mohon Beli Balik"
                : "Simpan Mohon Beli Balik"}
            </Button>
          </Flex>

          <SimpleGrid
            columns={{ base: 3 }}
            gap={{ sm: "6px", md: "18px" }}
            mb={10}
          >
            <CreatableSelectField
              label={"No. Fail MAINPP"}
              defaultOptions={noFailMainppOps}
              defaultValue={baitulmalBuyback?.data?.id_no_fail_mainpp}
              onChange={(value) => setValue("id_no_fail_mainpp", value)}
              onCreateOption={(value) =>
                createNoFailMainppOptions({
                  newOption: { no_fail_main_pp: value },
                  id: null,
                })
              }
              isLoading={isCreatingNoFailMainppOptions}
              isInvalid={!!errors.id_no_fail_mainpp}
              errorMessage={errors.id_no_fail_mainpp?.message}
              readOnly={action === "show"}
            />

            <TextField
              id="tujuan_permohonan"
              label="Tujuan Permohonan"
              isInvalid={!!errors.tujuan_permohonan}
              errorMessage={errors.tujuan_permohonan?.message}
              readOnly={action === "show"}
              {...register("tujuan_permohonan")}
            />

            <TextField
              id="deskripsi_belibalik"
              label="Deskripsi Beli Balik"
              type="textarea"
              isInvalid={!!errors.deskripsi_belibalik}
              errorMessage={errors.deskripsi_belibalik?.message}
              readOnly={action === "show"}
              {...register("deskripsi_belibalik")}
            />

            <TextField
              id="nama_pemohon"
              label="Nama Pemohon"
              isInvalid={!!errors.nama_pemohon}
              errorMessage={errors.nama_pemohon?.message}
              readOnly={action === "show"}
              {...register("nama_pemohon")}
            />

            <TextField
              id="no_kp"
              label="No. K/P"
              isInvalid={!!errors.no_kp}
              errorMessage={errors.no_kp?.message}
              readOnly={action === "show"}
              {...register("no_kp")}
            />

            <TextField
              id="no_fon"
              label="No. Tel"
              isInvalid={!!errors.no_fon}
              errorMessage={errors.no_fon?.message}
              readOnly={action === "show"}
              {...register("no_fon")}
            />

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Pendapatan
              </FormLabel>

              <NumberGroupField
                label="RM"
                step="any"
                isInvalid={!!errors.pendapatan}
                errorMessage={errors.pendapatan?.message}
                readOnly={action === "show"}
                {...register("pendapatan")}
              />
            </FormControl>

            <TextField
              id="syer_bahagian"
              label="Syer Bahagian"
              isInvalid={!!errors.syer_bahagian}
              errorMessage={errors.syer_bahagian?.message}
              readOnly={action === "show"}
              {...register("syer_bahagian")}
            />

            <TextField
              id="tarikh_pindah_milik"
              label="Tarikh Pindah Milik"
              type="date"
              isInvalid={!!errors.tarikh_pindah_milik}
              errorMessage={errors.tarikh_pindah_milik?.message}
              readOnly={action === "show"}
              {...register("tarikh_pindah_milik")}
            />

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Nilaian
              </FormLabel>

              <NumberGroupField
                label="RM"
                step="any"
                isInvalid={!!errors.harga_nilaian}
                errorMessage={errors.harga_nilaian?.message}
                readOnly={action === "show"}
                {...register("harga_nilaian")}
              />
            </FormControl>

            <TextField
              id="no_akaun_baitulmal_mainpp"
              label="No. Akaun Baitulmal MAINPP"
              isInvalid={!!errors.no_akaun_baitulmal_mainpp}
              errorMessage={errors.no_akaun_baitulmal_mainpp?.message}
              readOnly={action === "show"}
              {...register("no_akaun_baitulmal_mainpp")}
            />

            <TextField
              id="tarikh_penilaian"
              label="Tarikh Penilaian"
              type="date"
              isInvalid={!!errors.tarikh_penilaian}
              errorMessage={errors.tarikh_penilaian?.message}
              readOnly={action === "show"}
              {...register("tarikh_penilaian")}
            />

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Harga Jualan
              </FormLabel>

              <NumberGroupField
                label="RM"
                step="any"
                isInvalid={!!errors.harga_jualan}
                errorMessage={errors.harga_jualan?.message}
                readOnly={action === "show"}
                {...register("harga_jualan")}
              />
            </FormControl>

            <TextField
              id="tempoh_bayaran"
              label="Tempoh Bayaran"
              isInvalid={!!errors.tempoh_bayaran}
              errorMessage={errors.tempoh_bayaran?.message}
              readOnly={action === "show"}
              {...register("tempoh_bayaran")}
            />

            <TextField
              id="tarikh_bayaran"
              label="Tarikh Bayaran"
              type="date"
              isInvalid={!!errors.tarikh_bayaran}
              errorMessage={errors.tarikh_bayaran?.message}
              readOnly={action === "show"}
              {...register("tarikh_bayaran")}
            />

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Jumlah Bayaran
              </FormLabel>

              <NumberGroupField
                label="RM"
                step="any"
                isInvalid={!!errors.jumlah_bayaran}
                errorMessage={errors.jumlah_bayaran?.message}
                readOnly={action === "show"}
                {...register("jumlah_bayaran")}
              />
            </FormControl>

            <TextField
              id="no_resit"
              label="No. Resit"
              isInvalid={!!errors.no_resit}
              errorMessage={errors.no_resit?.message}
              readOnly={action === "show"}
              {...register("no_resit")}
            />

            <SelectField
              label={"Status Bayaran"}
              options={statusBayaranOps}
              isInvalid={!!errors.status_bayaran}
              errorMessage={errors.status_bayaran?.message}
              readOnly={action === "show"}
              {...register("status_bayaran")}
            />
          </SimpleGrid>

          <Section size="md" title="Lampiran Mohon Beli Balik">
            <FileUploadField
              files={files}
              setFiles={setFiles}
              fileDescriptions={fileDescriptions}
              setFileDescriptions={setFileDescriptions}
              tabName="aset_baitulmal_mohon_beli_balik"
              readOnly={action === "show"}
            />
          </Section>
        </form>
      </Section>
    </Flex>
  );
};

export default FormBuyback;
