import { useQuery } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { getBaitulmalBuyback } from "services/admin/baitulmalManagement/baitulmalBuybackService";

export function useBaitulmalBuyback(baitulmalBuybackId: number | null) {
  const toast = useToast();

  const {
    isLoading,
    isInitialLoading,
    data: baitulmalBuyback,
    error,
  } = useQuery({
    queryKey: ["baitulmalBuyback", baitulmalBuybackId],
    queryFn: () => getBaitulmalBuyback(baitulmalBuybackId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: "always",
    enabled: !!baitulmalBuybackId,
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isInitialLoading, isLoading, error, baitulmalBuyback };
}