import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditWakafMaintenance } from "services/admin/wakafManagement/wakafMaintenanceService";
import { IWakafMaintenanceFormValues } from "entities/admin/wakafManagement";

export function useCreateWakafMaintenance() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createWakafMaintenance, isLoading: isCreating } = useMutation({
    mutationFn: ({newWakafMaintenance, id}: {newWakafMaintenance: IWakafMaintenanceFormValues, id: number | null}) => createEditWakafMaintenance(newWakafMaintenance, id),
    onSuccess: () => {
      toast({
        title: "New wakaf maintenance successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["wakafMaintenance"] });
      history.push("/admin/wakaf/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createWakafMaintenance };
}

