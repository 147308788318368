import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import SettingList from "./components/SettingList";

export default function DataSetting() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <SettingList
          title="Tetapan Alamat"
          settings={[
            {
              text: "Negeri",
              link: "/admin/settings/data/negeri",
            },
            {
              text: "Daerah",
              link: "/admin/settings/data/daerah",
            },
            {
              text: "Mukim",
              link: "/admin/settings/data/mukim",
            },
            {
              text: "Seksyen",
              link: "/admin/settings/data/seksyen",
            },
            {
              text: "Kariah",
              link: "/admin/settings/data/kariah",
            },
            {
              text: "Parlimen",
              link: "/admin/settings/data/parliament",
            },
            {
              text: "Dun",
              link: "/admin/settings/data/dun",
            },
          ]}
        />

        <SettingList
          title="Tetapan Organisasi"
          settings={[
            {
              text: "Jabatan",
              link: "/admin/settings/data/jabatan",
            },
            {
              text: "Unit",
              link: "/admin/settings/data/unit",
            },
          ]}
        />

        <SettingList
          title="Tetapan Lampiran"
          settings={[
            {
              text: "Lampiran",
              link: "/admin/settings/data/lampiran",
            },
          ]}
        />

        <SettingList
          title="Lain - Lain"
          settings={[
            {
              text: "Jenis Wakaf",
              link: "/admin/settings/data/jeniswakaf",
            },
            {
              text: "Jenis Permohonan Wakaf",
              link: "/admin/settings/data/jenispermohonanwakaf",
            },
            {
              text: "No. Bangunan",
              link: "/admin/settings/data/nobangunan",
            },
            {
              text: "Tujuan Sighah",
              link: "/admin/settings/data/tujuansighah",
            },
            {
              text: "Kaedah Peroleh Tanah Wakaf",
              link: "/admin/settings/data/perolehwakaf",
            },
            {
              text: "Maklumat Kontraktor",
              link: "/admin/settings/data/kontraktor",
            },
            {
              text: "Pertubuhan",
              link: "/admin/settings/data/pertubuhan",
            },
            {
              text: "Jenis Aduan",
              link: "/admin/settings/data/jenisaduan",
            },
            {
              text: "Operasi Tanah Wakaf Semasa",
              link: "/admin/settings/data/operasitanahsemasa",
            },
            {
              text: "Kegunaan Tanah Wakaf Semasa",
              link: "/admin/settings/data/kegunaantanahsemasa",
            },
            {
              text: "No. Fail Mainpp",
              link: "/admin/settings/data/nofailmainpp",
            },
            {
              text: "No. Fail Ptg",
              link: "/admin/settings/data/nofailptg",
            },
            {
              text: "Kategori Gunatanah",
              link: "/admin/settings/data/kategorigunatanah",
            },
            {
              text: "Gunatanah Semasa",
              link: "/admin/settings/data/gunatanahsemasa",
            },
            {
              text: "Kaedah Perolehan",
              link: "/admin/settings/data/kaedahperolehan",
            },
            {
              text: "Tujuan Pengambilan",
              link: "/admin/settings/data/tujuanpengambilan",
            },
            {
              text: "Jenis Aset Baitulmal",
              link: "/admin/settings/data/jenisasetbaitulmal",
            },
            {
              text: "Sumber Wakaf",
              link: "/admin/settings/data/sumberwakaf",
            },
          ]}
        />
        {/* <DepartmentSetting />
				<UnitSetting />
				<WaqafTypeSetting />
				<JenisPermohonanWakafSetting />
				<BuildingNoSetting />
				<TujuanSighahSetting />
				<PerolehWakafSetting />
				<ContractorSetting />
				<OrganizationSetting />
				<ReportSetting />
				<OperasiTanahSemasaSetting />
				<KegunaanTanahSemasaSetting />
				<NoFailMainppSetting />
				<NoFailPtgSetting />
				<AttachmentSetting />
				<KategoriGunatanahSetting />
				<GunatanahSemasaSetting />
				<KaedahPerolehanSetting />
				<TujuanPengambilanSetting />
				<JenisAsetBaitulmalSetting /> */}
      </SimpleGrid>
    </Box>
  );
}
