import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdHouse,
  MdPeople,
  MdVerifiedUser,
} from "react-icons/md";
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import ComplexTable from "views/admin/dashboard/components/ComplexTable";
import DailyTraffic from "views/admin/dashboard/components/DailyTraffic";
import PieCard from "views/admin/dashboard/components/PieCard";
import WakafPieCard from "views/admin/dashboard/components/WakafPieCard";
import Tasks from "views/admin/dashboard/components/Tasks";
import TotalSpent from "views/admin/dashboard/components/TotalSpent";
import WeeklyRevenue from "views/admin/dashboard/components/WeeklyRevenue";
import tableDataComplex from "views/admin/dashboard/variables/tableDataComplex";
import Usa from "assets/img/dashboards/usa.png";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useDashboard } from "hooks/admin/dashboard/useDashboard";
import { FaBuilding, FaList, FaMoneyBill } from "react-icons/fa";

export default function UserReports() {
  const { isLoading, error, dashboard } = useDashboard();

  if (isLoading) return <FullScreenLoader />;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 2, md: 3, lg: 4, "2xl": 5 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={"secondaryGray.300"}
              icon={
                <Icon w="32px" h="32px" as={MdPeople} color={"brand.500"} />
              }
            />
          }
          name="Jumlah Pengguna"
          value={dashboard.total_users}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={"secondaryGray.300"}
              icon={<Icon w="32px" h="32px" as={MdHouse} color={"brand.500"} />}
            />
          }
          name="Jumlah Aset Wakaf"
          value={dashboard.total_aset_wakaf}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={"secondaryGray.300"}
              icon={
                <Icon w="32px" h="32px" as={FaBuilding} color={"brand.500"} />
              }
            />
          }
          name="Jumlah Aset Baitulmal"
          value={dashboard.total_aset_baitulmal}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={"secondaryGray.300"}
              icon={<Icon w="32px" h="32px" as={FaList} color={"brand.500"} />}
            />
          }
          name="Jumlah Permohonan Wakaf"
          value={dashboard.total_permohonan_wakaf}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={"secondaryGray.300"}
              icon={
                <Icon w="32px" h="32px" as={FaMoneyBill} color={"brand.500"} />
              }
            />
          }
          name="Jumlah Cadangan Beli Aset"
          value={dashboard.total_cadangan_beli_aset}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <WakafPieCard data={dashboard.aset_wakaf}/>
        <PieCard data={dashboard.aset_baitulmal}/>
      </SimpleGrid>
    </Box>
  );
}
