import axios from "utils/axios";
import { IJenisAsetBaitulmalSetting } from "entities/setting/dataSettings";

export async function getJenisAsetBaitulmalNameOptions() {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jenisDaftarAsetBaitulmal/options",
  });
  return data;
}

export async function getJenisAsetBaitulmalOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jenisDaftarAsetBaitulmal",
    params,
  });
  return data;
}

export async function getJenisAsetBaitulmalOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/jenisDaftarAsetBaitulmal/${id}`,
  });
  return data;
}


export async function createEditJenisAsetBaitulmalOption(
  values: IJenisAsetBaitulmalSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/jenisDaftarAsetBaitulmal/${id}` : "/general/jenisDaftarAsetBaitulmal";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteJenisAsetBaitulmalOption(
  id: string | number | null
) {
  const apiUrl = `/general/jenisDaftarAsetBaitulmal/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

