import axios from "utils/axios";

export async function getNoFailMainppOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/noFailMainPP/options",
    params,
  });
  return data;
}

export async function createEditNoFailMainppOptions(
  values: {no_fail_main_pp: string},
  id: string | null
) {
  const apiUrl = id ? `/general/noFailMainPP/${id}` : "/general/noFailMainPP";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}

export async function getNoFailPtgOptions() {
  const { data } = await axios.call({
    method: "get",
    url: "/general/noFailPTG/options",
  });
  return data;
}

export async function createEditNoFailPtgOptions(
  values: {no_fail_ptg: string},
  id: string | null
) {
  const apiUrl = id ? `/general/noFailPTG/${id}` : "/general/noFailPTG";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}
