import React from "react";
import FormTujuanPengambilanSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useTujuanPengambilanSetting } from "hooks/setting/dataSetting/tujuanPengambilanSetting/useTujuanPengambilanSetting";

export default function EditTujuanPengambilanSetting() {
  const { isLoading, tujuanPengambilan, error } = useTujuanPengambilanSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormTujuanPengambilanSetting action="edit" initialData={tujuanPengambilan?.data} />;
}
