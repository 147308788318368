import axios from "utils/axios";
import { IBaitulmalPartitionFormValues } from "entities/admin/baitulmalManagement";
import { IBaitulmalSubdivisionFormValues } from "entities/admin/baitulmalManagement";
import { IBaitulmalAmalgamationFormValues } from "entities/admin/baitulmalManagement";

// Partition
export async function getBaitulmalPartitions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/pecah-bahagian-baitulmal",
    params
  });
  return data;
}

export async function getBaitulmalPartition(id: number) {
  const { data } = await axios.call({
    method: "get",
    url: `/pecah-bahagian-baitulmal/${id}`,
  });
  return data;
}

export async function createEditBaitulmalPartition(
  values: IBaitulmalPartitionFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/pecah-bahagian-baitulmal/${id}` : "/pecah-bahagian-baitulmal";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteBaitulmalPartition(
  id: string | number | null
) {
  const apiUrl = `/pecah-bahagian-baitulmal/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

// Subdivision
export async function getBaitulmalSubdivisions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/pecah-sempadan-baitulmal",
    params
  });
  return data;
}

export async function getBaitulmalSubdivision(id: number) {
  const { data } = await axios.call({
    method: "get",
    url: `/pecah-sempadan-baitulmal/${id}`,
  });
  return data;
}

export async function createEditBaitulmalSubdivision(
  values: IBaitulmalSubdivisionFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/pecah-sempadan-baitulmal/${id}` : "/pecah-sempadan-baitulmal";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteBaitulmalSubdivision(
  id: string | number | null
) {
  const apiUrl = `/pecah-sempadan-baitulmal/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

// Amalgamation
export async function getBaitulmalAmalgamations(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/pencantuman-baitulmal",
    params
  });
  return data;
}

export async function getBaitulmalAmalgamation(id: number) {
  const { data } = await axios.call({
    method: "get",
    url: `/pencantuman-baitulmal/${id}`,
  });
  return data;
}

export async function createEditBaitulmalAmalgamation(
  values: IBaitulmalAmalgamationFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/pencantuman-baitulmal/${id}` : "/pencantuman-baitulmal";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteBaitulmalAmalgamation(
  id: string | number | null
) {
  const apiUrl = `/pencantuman-baitulmal/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}