import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
} from "@chakra-ui/react";

import Section from "components/section/Section";
import NumberGroupField from "components/fields/NumberGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";
import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { IWakafMaintenanceFormValues } from "entities/admin/wakafManagement";
import { useCreateWakafMaintenance } from "hooks/admin/wakafManagement/wakafMaintenance/useCreateWakafMaintenance";
import { useEditWakafMaintenance } from "hooks/admin/wakafManagement/wakafMaintenance/useEditWakafMaintenance";
import { useWakafMaintenance } from "hooks/admin/wakafManagement/wakafMaintenance/useWakafMaintenance";

interface FormMaintenanceProps {
  action: "create" | "edit" | "show";
  wakafAssetId?: number;
  wakafMaintenanceId?: number;
}

const schema = yup
  .object({
    id_jenis_aduan: yup.string().required(),
    id_kaedah_peroleh: yup.string().required(),
    id_kontraktor: yup.string().required(),
    nama_pengadu: yup.string().required(),
    no_tel: yup.string().required(),
    no_kp: yup.string().required(),
    nama_pegawai: yup.string().required(),
    rujukan: yup.string().required(),
    kos_selenggara: yup.number().positive().required(),
    catatan_selenggara: yup.string().required(),
    deskripsi: yup.string().required(),
  })
  .required();

const FormMaintenance: React.FC<FormMaintenanceProps> = ({
  action,
  wakafAssetId,
  wakafMaintenanceId
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    [
      {
        id_senarai_lampiran: "",
        tab_name: "aset_wakaf_penyelenggaraan",
        file_name: "",
        file_description: "",
      },
    ]
  );

  const history = useHistory();
  const { createWakafMaintenance, isCreating } = useCreateWakafMaintenance();
  const { editWakafMaintenance, isEditing } = useEditWakafMaintenance();
  const { wakafMaintenance, isInitialLoading } = useWakafMaintenance(wakafMaintenanceId);

  const { jenisAduanOps, kaedahPerolehanOps, kontraktorOps } = useOptions();

  const { register, reset, handleSubmit, formState: { errors } } = useForm<IWakafMaintenanceFormValues>({
    resolver: yupResolver(schema) as any,
  });

  const renderTitle = (action: string) => {
    if (action === "create") {
      return "Tambah Rekod Penyelenggaraan";
    } else if (action === "edit") {
      return "Kemaskini Rekod Penyelenggaraan";
    } else if (action === "show") {
      return "Rekod Penyelenggaraan";
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createWakafMaintenance({
        newWakafMaintenance: {
          ...data,
          id_aset_wakaf: wakafAssetId,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files
        },
        id: null,
      });
    } else if (action === "edit") {
      editWakafMaintenance({
        newWakafMaintenance: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                tab_name: file?.tab_name,
                file_name: file?.file_name,
                file_description: file?.file_description,
              };
            })
          ),
          lampiran: files,
        },
        id: wakafMaintenanceId,
      });
    }
  });

  useEffect(() => {
    const defaultValues: any = {
      id_jenis_aduan: null,
      id_kaedah_peroleh: null,
      id_kontraktor: null,
      nama_pengadu: '',
      no_tel: '',
      no_kp: '',
      nama_pegawai: '',
      rujukan: '',
      kos_selenggara: null,
      catatan_selenggara: '',
      deskripsi: '',
      senarai_lampiran: [],
    };

    if (action === "create") {
      setFileDescriptions([]);
      reset(defaultValues);
    } else if (wakafMaintenance?.data) {
      reset(wakafMaintenance.data);
    }
  }, [action, wakafMaintenance?.data, reset]);

  useEffect(() => {
    if (wakafMaintenance?.data?.senarai_lampiran) {
      setFileDescriptions(wakafMaintenance?.data?.senarai_lampiran.filter(
        (file: any) => file.tab_name === "aset_wakaf_penyelenggaraan"
      ));
    }

    return () => {
      setFileDescriptions([]);
    };
  }, [wakafMaintenance?.data]);

  if (isInitialLoading) return <FullScreenLoader />;
  
  return (
    <Flex key={wakafMaintenanceId} px="25px" mt="10px" mb="1.5rem" flexDirection={"column"}>
      <Section size={"xl"} title={renderTitle(action)}>
        <form onSubmit={onSubmit}>
          <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
            <Button
              variant="outline"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              colorScheme="gray"
              type={action === "create" ? "reset" : "button"}
              onClick={() => {
                if (action !== "create") {
                  history.push("/admin/wakaf/aset");
                }
              }}
            >
              {action === "create" ? "Batal" : "Kembali"}
            </Button>

            <Button
              variant="brand"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              type="submit"
              disabled={isCreating || isEditing}
            >
              {action === "edit"
                ? "Kemaskini Rekod Penyelenggaraan"
                : "Simpan Rekod Penyelenggaraan"}
            </Button>
          </Flex>

          <SimpleGrid
            columns={{ base: 3 }}
            gap={{ sm: "6px", md: "18px" }}
            mb={10}
          >
            <SelectField
              label={"Jenis Aduan"}
              options={jenisAduanOps}
              isInvalid={!!errors.id_jenis_aduan}
              errorMessage={errors.id_jenis_aduan?.message}
              readOnly={action === "show"}
              {...register("id_jenis_aduan")}
            />

            <SelectField
              label={"Kaedah Perolehan"}
              options={kaedahPerolehanOps}
              isInvalid={!!errors.id_kaedah_peroleh}
              errorMessage={errors.id_kaedah_peroleh?.message}
              readOnly={action === "show"}
              {...register("id_kaedah_peroleh")}
            />

            <SelectField
              label={"Kontraktor"}
              options={kontraktorOps}
              isInvalid={!!errors.id_kontraktor}
              errorMessage={errors.id_kontraktor?.message}
              readOnly={action === "show"}
              {...register("id_kontraktor")}
            />

            <TextField
              id="nama_pengadu"
              label="Nama Pengadu"
              isInvalid={!!errors.nama_pengadu}
              errorMessage={errors.nama_pengadu?.message}
              readOnly={action === "show"}
              {...register("nama_pengadu")}
            />

            <TextField
              id="no_tel"
              label="No. Tel"
              isInvalid={!!errors.no_tel}
              errorMessage={errors.no_tel?.message}
              readOnly={action === "show"}
              {...register("no_tel")}
            />

            <TextField
              id="no_kp"
              label="No. Kad Pengenalan"
              isInvalid={!!errors.no_kp}
              errorMessage={errors.no_kp?.message}
              readOnly={action === "show"}
              {...register("no_kp")}
            />

            <TextField
              id="nama_pegawai"
              label="Nama Pegawai Penerima"
              isInvalid={!!errors.nama_pegawai}
              errorMessage={errors.nama_pegawai?.message}
              readOnly={action === "show"}
              {...register("nama_pegawai")}
            />

            <TextField
              id="rujukan"
              label="Rujukan"
              isInvalid={!!errors.rujukan}
              errorMessage={errors.rujukan?.message}
              readOnly={action === "show"}
              {...register("rujukan")}
            />

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Kos Selenggara
              </FormLabel>

              <NumberGroupField
                label="RM"
                step="any"
                isInvalid={!!errors.kos_selenggara}
                errorMessage={errors.kos_selenggara?.message}
                readOnly={action === "show"}
                {...register("kos_selenggara")}
              />
            </FormControl>

            <TextField
              id="catatan_selenggara"
              label="Catatan Penyelenggaraan"
              type="textarea"
              isInvalid={!!errors.catatan_selenggara}
              errorMessage={errors.catatan_selenggara?.message}
              readOnly={action === "show"}
              {...register("catatan_selenggara")}
            />

            <TextField
              id="description"
              label="Butir - Butir Aduan (Deskripsi)"
              type="textarea"
              isInvalid={!!errors.deskripsi}
              errorMessage={errors.deskripsi?.message}
              readOnly={action === "show"}
              {...register("deskripsi")}
            />
          </SimpleGrid>

          <Section size="md" title="Lampiran Penyelenggaraan">
            <FileUploadField
              files={files}
              setFiles={setFiles}
              fileDescriptions={fileDescriptions}
              setFileDescriptions={setFileDescriptions}
              tabName="aset_wakaf_penyelenggaraan"
              readOnly={action === "show"}
            />
          </Section>
        </form>
      </Section>
    </Flex>
  );
};

export default FormMaintenance;
