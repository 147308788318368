import React from "react";
import FormDunSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useDunSetting } from "hooks/setting/dataSetting/dunSetting/useDunSetting";

export default function EditDunSetting() {
  const { isLoading, dun, error } = useDunSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormDunSetting action="edit" initialData={dun?.data} />;
}
