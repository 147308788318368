import axios from "utils/axios";
import { IWakafPartitionFormValues } from "entities/admin/wakafManagement";
import { IWakafSubdivisionFormValues } from "entities/admin/wakafManagement";
import { IWakafAmalgamationFormValues } from "entities/admin/wakafManagement";

// Partition
export async function getWakafPartitions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/pecah-bahagian-aset-wakaf",
    params
  });
  return data;
}

export async function getWakafPartition(id: number) {
  const { data } = await axios.call({
    method: "get",
    url: `/pecah-bahagian-aset-wakaf/${id}`,
  });
  return data;
}

export async function createEditWakafPartition(
  values: IWakafPartitionFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/pecah-bahagian-aset-wakaf/${id}` : "/pecah-bahagian-aset-wakaf";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteWakafPartition(
  id: string | number | null
) {
  const apiUrl = `/pecah-bahagian-aset-wakaf/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

// Subdivision
export async function getWakafSubdivisions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/pecah-sempadan-aset-wakaf",
    params
  });
  return data;
}

export async function getWakafSubdivision(id: number) {
  const { data } = await axios.call({
    method: "get",
    url: `/pecah-sempadan-aset-wakaf/${id}`,
  });
  return data;
}

export async function createEditWakafSubdivision(
  values: IWakafSubdivisionFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/pecah-sempadan-aset-wakaf/${id}` : "/pecah-sempadan-aset-wakaf";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteWakafSubdivision(
  id: string | number | null
) {
  const apiUrl = `/pecah-sempadan-aset-wakaf/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

// Amalgamation
export async function getWakafAmalgamations(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/pencantuman-aset-wakaf",
    params
  });
  return data;
}

export async function getWakafAmalgamation(id: number) {
  const { data } = await axios.call({
    method: "get",
    url: `/pencantuman-aset-wakaf/${id}`,
  });
  return data;
}

export async function createEditWakafAmalgamation(
  values: IWakafAmalgamationFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/pencantuman-aset-wakaf/${id}` : "/pencantuman-aset-wakaf";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteWakafAmalgamation(
  id: string | number | null
) {
  const apiUrl = `/pencantuman-aset-wakaf/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}