import React from "react";
import { Text, Link, List, Icon } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import Resource from "components/resource/Resource";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { useBaitulmalBuybacks } from "hooks/admin/baitulmalManagement/baitulmalBuyback/useBaitulmalBuybacks";
import { useDeleteBaitulmalBuyback } from "hooks/admin/baitulmalManagement/baitulmalBuyback/useDeleteBaitulmalBuyback";
import { IBaitulmalBuybackTable } from "entities/admin/baitulmalManagement";

import { formatDate, fetchLabel } from "utils/helpers";

const columnHelper = createColumnHelper<IBaitulmalBuybackTable>();

interface TableBuybackProps {
  baitulmalAssetId?: number;
  toggleFormBuyback: (
    action: "create" | "edit" | "show",
    baitulmalBuybackId?: number
  ) => void;
}

const TableBuyback = ({
  baitulmalAssetId,
  toggleFormBuyback,
}: TableBuybackProps) => {
  const { statusBayaranOps } = useOptions();
  const { baitulmalBuybacks, isLoading } = useBaitulmalBuybacks({
    idAsetBaitulmal: baitulmalAssetId,
  });
  const { removeBaitulmalBuyback, isDeleting } = useDeleteBaitulmalBuyback();

  const columns = [
    columnHelper.accessor("updated_at", {
      header: () => <Text variant="tableHeading">Tarikh Kemaskini</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_pemohon", {
      header: () => <Text variant="tableHeading">Nama Pemohon</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kp", {
      header: () => <Text variant="tableHeading">No. K/P</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_fon", {
      header: () => <Text variant="tableHeading">No. Tel</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("hubungan_dengan_simati", {
      header: () => <Text variant="tableHeading">Hubungan Dengan Si Mati</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("harga_nilaian", {
      header: () => <Text variant="tableHeading">Harga Jualan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("jumlah_bayaran", {
      header: () => <Text variant="tableHeading">Habis Bayar</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("status_bayaran", {
      header: () => <Text variant="tableHeading">Status Bayaran</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {fetchLabel(info.getValue(), statusBayaranOps)}
        </Text>
      ),
    }),
    
    columnHelper.accessor("baki_bayaran", {
      header: () => <Text variant="tableHeading">Baki Belum Bayar</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.row.original.harga_nilaian - info.row.original.jumlah_bayaran}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <Link
            as={"button"}
            onClick={() => {
              toggleFormBuyback("edit", info.row.original.id);
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </Link>

          <Link
            as={"button"}
            onClick={() => {
              toggleFormBuyback("show", info.row.original.id);
            }}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </Link>

          <Link
            as={"button"}
            onClick={() => {
              removeBaitulmalBuyback(info.row.original.id);
            }}
            disabled={isDeleting}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return (
    <Resource
      columns={columns}
      data={baitulmalBuybacks}
      title="Senarai Rekod Beli Balik"
      createTitle="Tambah Rekod Beli Balik"
      onCreate={() => toggleFormBuyback("create")}
      allowDownload
      onClickDownload={() => console.log("Download")}
    />
  );
}

export default TableBuyback;
