import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getOrganizationOption } from "services/setting/dataSetting/organizationSettingService";

export function useOrganizationSetting() {
  const { organizationId } = useParams<{ organizationId: string }>();
  const toast = useToast();

  const {
    isLoading,
    data: organization,
    error,
  } = useQuery({
    queryKey: ["organization", organizationId],
    queryFn: () => getOrganizationOption(organizationId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: "always",
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isLoading, error, organization };
}

