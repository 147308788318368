import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditBaitulmalRegistration } from "services/admin/baitulmalRegistration/baitulmalRegistrationService";
import { IBaitulmalRegistrationFormValues } from "entities/admin/baitulmalRegistration";

export function useCreateBaitulmalRegistration() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createBaitulmalRegistration, isLoading: isCreating } = useMutation({
    mutationFn: ({newBaitulmalRegistration, id}: {newBaitulmalRegistration: IBaitulmalRegistrationFormValues, id?: number | null}) => createEditBaitulmalRegistration(newBaitulmalRegistration, id),
    onSuccess: () => {
      toast({
        title: "New baitulmal registration successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["baitulmalRegistration"] });
      history.push("/admin/baitulmal/permohonan");
    },
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createBaitulmalRegistration };
}

