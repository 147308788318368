import axios from "utils/axios";
import { IGunatanahSemasaSetting } from "entities/setting/dataSettings";

export async function getGunatanahSemasaOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/gunaTanahSemasa",
    params,
  });
  return data;
}

export async function getGunatanahSemasaOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/gunaTanahSemasa/${id}`,
  });
  return data;
}

export async function createEditGunatanahSemasaOption(
  values: IGunatanahSemasaSetting,
  id: string | null
) {
  const apiUrl = id ? `/general/gunaTanahSemasa/${id}` : "/general/gunaTanahSemasa";
  const apiMethod = id ? "put" : "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}


export async function deleteGunatanahSemasaOption(
  id: string | number | null
) {
  const apiUrl = `/general/gunaTanahSemasa/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}

