import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditBaitulmalAssessment } from "services/admin/baitulmalManagement/baitulmalAssessmentService";
import { IBaitulmalAssessmentFormValues } from "entities/admin/baitulmalManagement";

export function useCreateBaitulmalAssessment() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createBaitulmalAssessment, isLoading: isCreating } = useMutation({
    mutationFn: ({newBaitulmalAssessment, id}: {newBaitulmalAssessment: IBaitulmalAssessmentFormValues, id?: string | null}) => createEditBaitulmalAssessment(newBaitulmalAssessment, id),
    onSuccess: () => {
      toast({
        title: "New baitulmal assessment successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["baitulmalAssessment"] });
      history.push("/admin/baitulmal/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createBaitulmalAssessment };
}

