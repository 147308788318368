import React from "react";
import FormBasicInformation from "./FormBasicInformation";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useBaitulmalAsset } from "hooks/admin/baitulmalManagement/baitulmalAsset/useBaitulmalAsset";

export default function ShowBasicInformation() {
  const { baitulmalAsset, isLoading } = useBaitulmalAsset();

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <FormBasicInformation
      action="show"
      initialData={{
        ...baitulmalAsset?.data,
        senarai_lampiran: baitulmalAsset?.data?.senarai_lampiran.filter(
          (file: any) => file.tab_name === "aset_baitulmal_index"
        ),
      }}
    />
  );
}
