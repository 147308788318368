import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { deleteBaitulmalTax } from "services/admin/baitulmalManagement/baitulmalTaxService";

export function useDeleteBaitulmalTax() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: removeBaitulmalTax, isLoading: isDeleting } = useMutation({
    mutationFn: (id: number) => deleteBaitulmalTax(id),
    onSuccess: () => {
      toast({
        title: "Baitulmal tax successfully deleted",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["baitulmalTaxes"] });
      history.push("/admin/baitulmal/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isDeleting, removeBaitulmalTax };
}

