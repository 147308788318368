import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { getAttachmentNameOptions, createEditAttachmentNameOptions } from "services/setting/dataSetting/attachmentService";

export default function useDataAttachment() {
  const queryClient = useQueryClient();

  const {
    data: attachmentNameOptions,
  } = useQuery({
    queryKey: ["attachmentName"],
    queryFn: getAttachmentNameOptions,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: "always",
  });

  const { mutate: createAttachmentNameOptions, isLoading: isCreatingAttachmentNameOptions } = useMutation({
    mutationFn: ({newOption, id}: {newOption: {nama: string}, id: string | null}) => createEditAttachmentNameOptions(newOption, id),
    onSuccess: () => {
        queryClient.invalidateQueries(["attachmentName"]);
    },
    onError: (err: any) => {
      console.error("ERROR", err);
    },
  });

  // API
  const attachmentNameOps = attachmentNameOptions?.data || []

  // OUTPUT
  return {
    attachmentNameOps,
    createAttachmentNameOptions,
    isCreatingAttachmentNameOptions,
  };
}
