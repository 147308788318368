import React from "react";
import FormKaedahPerolehanSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useKaedahPerolehanSetting } from "hooks/setting/dataSetting/kaedahPerolehanSetting/useKaedahPerolehanSetting";

export default function EditKaedahPerolehanSetting() {
  const { isLoading, kaedahPerolehan, error } = useKaedahPerolehanSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormKaedahPerolehanSetting action="edit" initialData={kaedahPerolehan?.data} />;
}
