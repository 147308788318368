import { ISignInFormValues } from "entities/auth/auth";
import axios from "utils/axios";
import "./authMockup";

export async function login({ email, password }: ISignInFormValues) {
  const { data } = await axios.call({
    method: "post",
    url: "/auth/login",
    data: { email, password },
  });
  return data;
}

export async function forgotPassword({ email }: any) {
  const { data } = await axios.call({
    method: "post",
    url: "/auth/forgot-password",
    data: { email },
  });

  return data;
}

export async function resetPassword({ email, token, password, password_confirmation }: any) {
  const { data } = await axios.call({
    method: "post",
    url: "/auth/reset-password",
    data: { email, token, password, password_confirmation },
  });
  
  return data;
}