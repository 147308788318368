import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";

import Card from "components/card/Card";
import TabBasicInformation from "./components/TabBasicInformation";
import TabAcquisition from "./components/TabAcquisition";
import TabAssessment from "./components/TabAssessment";
import TabMaintenance from "./components/TabMaintenance";
import TabTax from "./components/TabTax";
import TabBuyback from "./components/TabBuyback";
import TabDevelopment from "./components/TabDevelopment";
import TabHistory from "./components/TabHistory";
import TabChronology from "./components/TabChronology/TabChronology";
import TabAudit from "./components/TabAudit/TabAudit";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { useBaitulmalAsset } from "hooks/admin/baitulmalManagement/baitulmalAsset/useBaitulmalAsset";

interface LocationState {
  tabIndex?: number;
}

const BaitulmalManagement = () => {
  const [action, setAction] = useState<"create" | "show" | "edit">("create");
  const [tabIndex, setTabIndex] = useState(0);

  const { baitulmalAsset, isInitialLoading } = useBaitulmalAsset();
  const location = useLocation<LocationState>();

  useEffect(() => {
    if (location.pathname.includes("show")) {
      setAction("show");
    } else if (location.pathname.includes("kemaskini")) {
      setAction("edit");
    } else {
      setAction("create");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.state && location.state.tabIndex !== undefined) {
      setTabIndex(location.state.tabIndex);
    }
  }, [location.state]);

  if (isInitialLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Tabs variant="main" defaultIndex={tabIndex}>
          <TabList>
            <Tab>Maklumat Asas</Tab>
            <Tab isDisabled={action === "create"}>Pembelian</Tab>
            <Tab isDisabled={action === "create"}>Penilaian</Tab>
            <Tab isDisabled={action === "create"}>Penyelenggaraan</Tab>
            <Tab isDisabled={action === "create"}>Cukai</Tab>
            <Tab isDisabled={action === "create"}>Mohon Beli Balik</Tab>
            <Tab isDisabled={action === "create"}>Pembangunan</Tab>
            <Tab isDisabled={action === "create"}>Sejarah</Tab>
            <Tab isDisabled={action === "create"}>Kronologi</Tab>
            <Tab isDisabled={action === "create"}>Audit Data</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <TabBasicInformation action={action} />
            </TabPanel>
            <TabPanel>
              <TabAcquisition
                action={action}
                baitulmalAsset={baitulmalAsset?.data}
              />
            </TabPanel>
            <TabPanel>
              <TabAssessment
                baitulmalAssetId={baitulmalAsset?.data?.id}
              />
            </TabPanel>
            <TabPanel>
              <TabMaintenance baitulmalAssetId={baitulmalAsset?.data?.id} />
            </TabPanel>
            <TabPanel>
              <TabTax baitulmalAssetId={baitulmalAsset?.data?.id} />
            </TabPanel>
            <TabPanel>
              <TabBuyback baitulmalAssetId={baitulmalAsset?.data?.id} />
            </TabPanel>
            <TabPanel>
              <TabDevelopment
                baitulmalAssetId={baitulmalAsset?.data?.id}
                pecahBahagian={baitulmalAsset?.data?.pecah_bahagian_baitulmal}
                pecahSempadan={baitulmalAsset?.data?.pecah_sempadan_baitulmal}
                pencantuman={baitulmalAsset?.data?.pencantuman_baitulmal}
                assetArea={baitulmalAsset?.data?.luas_aset}
              />
            </TabPanel>
            <TabPanel>
              <TabHistory baitulmalAssetId={baitulmalAsset?.data?.id} />
            </TabPanel>
            <TabPanel>
              <TabChronology chronologies={baitulmalAsset?.data?.kronologi} />
            </TabPanel>
            <TabPanel>
              <TabAudit audits={baitulmalAsset?.data?.audits} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Box>
  );
};

export default BaitulmalManagement;
