import React from "react";
import FormContractorSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useContractorSetting } from "hooks/setting/dataSetting/contractorSetting/useContractorSetting";

export default function EditContractorSetting() {
  const { isLoading, contractor, error } = useContractorSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormContractorSetting action="edit" initialData={contractor?.data} />;
}
