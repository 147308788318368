import React from "react";
import FormAcquisition from "./FormAcquisition";
import { IBaitulmalAsset } from "entities/admin/baitulmalManagement";

interface ShowAcquisitionProps {
  baitulmalAsset?: IBaitulmalAsset;
}

export default function ShowAcquisition({ baitulmalAsset }: ShowAcquisitionProps) {
  return (
    <FormAcquisition
      action="show"
      initialData={{
        id: baitulmalAsset?.id,
        tarikh_belian: baitulmalAsset?.tarikh_belian,
        deskripsi_belian: baitulmalAsset?.deskripsi_belian,
        harga_belian: baitulmalAsset?.harga_belian,
        harga_nilaian: baitulmalAsset?.harga_nilaian,
        senarai_lampiran: baitulmalAsset?.senarai_lampiran.filter((file: any) => file.tab_name === "aset_baitulmal"),
      }}
    />
  );
}
