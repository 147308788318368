import React from "react";
import FormBuildingNoSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useBuildingNoSetting } from "hooks/setting/dataSetting/buildingNoSetting/useBuildingNoSetting";

export default function EditBuildingNoSetting() {
  const { isLoading, buildingNo, error } = useBuildingNoSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormBuildingNoSetting action="edit" initialData={buildingNo?.data} />;
}
