import React from "react";
import FormWakafRegistration from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useWakafRegistration } from "hooks/admin/wakafRegistration/useWakafRegistration";

export default function EditWakafRegistration() {
  const { wakafRegistration, isInitialLoading } = useWakafRegistration();

  if (isInitialLoading) {
    return <FullScreenLoader />
  }

  return <FormWakafRegistration action="edit" initialData={wakafRegistration?.data} />;
}
