import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditPerolehWakafOption } from "services/setting/dataSetting/perolehWakafSettingService";
import { IPerolehWakafSetting } from "entities/setting/dataSettings";

export function useEditPerolehWakafSetting() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: editPerolehWakafSetting, isLoading: isEditing } = useMutation({
    mutationFn: ({
      perolehWakafSetting,
      id,
    }: {
      perolehWakafSetting: IPerolehWakafSetting;
      id: string | null;
    }) => createEditPerolehWakafOption(perolehWakafSetting, id),
    onSuccess: () => {
      toast({
        title: "Peroleh wakaf successfully updated",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["perolehWakaf"] });
      history.push("/admin/settings/data");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isEditing, editPerolehWakafSetting };
}
