import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Grid,
  SimpleGrid,
  GridItem,
  Button,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import Section from "components/section/Section";
import NumberGroupField from "components/fields/NumberGroupField";
import FileUploadField from "components/fields/FileUploadField";
import TextField from "components/fields/TextField";
import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { IWakafIstibdalAssessmentFormValues } from "entities/admin/wakafManagement";
import { useCreateWakafIstibdalAssessment } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalAssessment/useCreateWakafIstibdalAssessment";
import { useEditWakafIstibdalAssessment } from "hooks/admin/wakafManagement/wakafIstibdal/wakafIstibdalAssessment/useEditWakafIstibdalAssessment";

import {
  formatDate,
  convertsAcreToHectare,
  convertsAcreToSquareMeter,
} from "utils/helpers";

interface FormAcquisitionLandProps {
  action: "create" | "edit" | "show";
  wakafAssetId?: number;
  acquisitionType?: string;
  initialData?: IWakafIstibdalAssessmentFormValues;
  assetArea?: {
    luas_hektar: number;
    luas_ekar: number;
    luas_m2: number;
    luas_kp2: number;
  };
}

const schema = yup
  .object({
    no_fail_istibdal: yup.string().required(),
  })
  .required();

const FormAcquisitionLand: React.FC<FormAcquisitionLandProps> = ({
  action,
  wakafAssetId,
  acquisitionType,
  initialData,
  assetArea,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDescriptions, setFileDescriptions] = useState<any>(
    initialData?.senarai_lampiran || [
      {
        id_senarai_lampiran: "",
        tab_name: "aset_wakaf_istibdal_pengambilan",
        file_name: "",
        file_description: "",
      },
    ]
  );

  const history = useHistory();
  const { tujuanPengambilanOps } = useOptions();
  const { createWakafIstibdalAssessment, isCreating } =
    useCreateWakafIstibdalAssessment();
  const { editWakafIstibdalAssessment, isEditing } =
    useEditWakafIstibdalAssessment();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IWakafIstibdalAssessmentFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      tarikh_borang_e: formatDate(initialData?.tarikh_borang_e),
      tarikh_borang_f: formatDate(initialData?.tarikh_borang_f),
      tarikh_resit: formatDate(initialData?.tarikh_resit),
      tarikh_borang_k: formatDate(initialData?.tarikh_borang_k),
    },
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createWakafIstibdalAssessment({
        newWakafIstibdalAssessment: {
          ...data,
          id_aset_wakaf: wakafAssetId,
          jenis_pengambilan: acquisitionType,
          senarai_lampiran: JSON.stringify(fileDescriptions),
          lampiran: files,
        },
      });
    } else if (action === "edit") {
      editWakafIstibdalAssessment({
        newWakafIstibdalAssessment: {
          ...data,
          senarai_lampiran: JSON.stringify(
            fileDescriptions.map((file: any) => {
              return {
                id_lampiran: file?.id,
                id_senarai_lampiran: file?.id_senarai_lampiran,
                file_name: file?.file_name,
                file_description: file?.file_description,
                tab_name: "aset_wakaf_istibdal_pengambilan",
              };
            })
          ),
          lampiran: files,
        },
        id: initialData?.id,
      });
    }
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "luas_lot_diambil_ektar") {
        // handle auto conversion
        const luasLotDiambilHektar = convertsAcreToHectare(
          value.luas_lot_diambil_ektar
        );
        const luasLotDiambilM2 = convertsAcreToSquareMeter(
          value.luas_lot_diambil_ektar
        );

        setValue("luas_lot_diambil_hektar", luasLotDiambilHektar, {
          shouldValidate: false,
        });
        setValue("luas_lot_diambil_m2", luasLotDiambilM2, {
          shouldValidate: false,
        });

        // handle baki keluasan
        const bakiLuasanHektar = parseFloat(
          (assetArea?.luas_hektar - luasLotDiambilHektar).toFixed(3)
        );
        const bakiLuasanEkar = parseFloat(
          (assetArea?.luas_ekar - value.luas_lot_diambil_ektar).toFixed(3)
        );
        const bakiLuasanM2 = parseFloat(
          (assetArea?.luas_m2 - luasLotDiambilM2).toFixed(3)
        );

        setValue("baki_luas_lot_diambil_hektar", bakiLuasanHektar, {
          shouldValidate: false,
        });
        setValue("baki_luas_lot_diambil_ektar", bakiLuasanEkar, {
          shouldValidate: false,
        });
        setValue("baki_luas_lot_diambil_m2", bakiLuasanM2, {
          shouldValidate: false,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <Flex mt="30px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={action === "create" ? "reset" : "button"}
            onClick={() => {
              if (action !== "create") {
                history.push("/admin/wakaf/aset");
              }
            }}
          >
            Batal
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            disabled={isCreating || isEditing}
          >
            {action === "edit" ? "Kemaskini Pengambilan" : "Daftar Pengambilan"}
          </Button>
        </Flex>

        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <TextField
            id="no_fail_istibdal"
            label="No. Fail Istibdal"
            isInvalid={!!errors.no_fail_istibdal}
            errorMessage={errors.no_fail_istibdal?.message}
            readOnly={action === "show"}
            {...register("no_fail_istibdal")}
          />

          <TextField
            id="tuan_punya_daftar"
            label="Tuanpunya Berdaftar Atau Penduduk Yang Direkodkan"
            isInvalid={!!errors.tuan_punya_daftar}
            errorMessage={errors.tuan_punya_daftar?.message}
            readOnly={action === "show"}
            {...register("tuan_punya_daftar")}
          />

          <GridItem
            display={"grid"}
            colSpan={2}
            gap={{ sm: "6px", md: "18px" }}
          >
            <FormControl>
              <FormLabel variant="main" mb="8px">
                Luas Aset
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="luas_lot_hektar"
                  label="Hektar"
                  step="any"
                  value={assetArea?.luas_hektar}
                  readOnly={action === "show"}
                  disabled
                />

                <NumberGroupField
                  id="luas_lot_ektar"
                  label="Ekar"
                  step="any"
                  value={assetArea?.luas_ekar}
                  readOnly={action === "show"}
                  disabled
                />

                <NumberGroupField
                  id="luas_lot_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  step="any"
                  value={assetArea?.luas_m2}
                  readOnly={action === "show"}
                  disabled
                />
              </SimpleGrid>
            </FormControl>

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Anggaran Keluasan Yang Diambil
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="luas_lot_diambil_hektar"
                  label="Hektar"
                  step="any"
                  isInvalid={!!errors.luas_lot_diambil_hektar}
                  errorMessage={errors.luas_lot_diambil_hektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_lot_diambil_hektar")}
                />

                <NumberGroupField
                  id="luas_lot_diambil_ektar"
                  label="Ekar"
                  step="any"
                  isInvalid={!!errors.luas_lot_diambil_ektar}
                  errorMessage={errors.luas_lot_diambil_ektar?.message}
                  readOnly={action === "show"}
                  {...register("luas_lot_diambil_ektar")}
                />

                <NumberGroupField
                  id="luas_lot_diambil_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  step="any"
                  isInvalid={!!errors.luas_lot_diambil_m2}
                  errorMessage={errors.luas_lot_diambil_m2?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("luas_lot_diambil_m2")}
                />
              </SimpleGrid>
            </FormControl>

            <FormControl>
              <FormLabel variant="main" mb="8px">
                Baki Keluasan
              </FormLabel>

              <SimpleGrid columns={{ base: 3 }} gap={{ sm: "6px", md: "18px" }}>
                <NumberGroupField
                  id="baki_luas_lot_diambil_hektar"
                  label="Hektar"
                  step="any"
                  isInvalid={!!errors.baki_luas_lot_diambil_hektar}
                  errorMessage={errors.baki_luas_lot_diambil_hektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("baki_luas_lot_diambil_hektar")}
                />

                <NumberGroupField
                  id="baki_luas_lot_diambil_ektar"
                  label="Ekar"
                  step="any"
                  isInvalid={!!errors.baki_luas_lot_diambil_ektar}
                  errorMessage={errors.baki_luas_lot_diambil_ektar?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("baki_luas_lot_diambil_ektar")}
                />

                <NumberGroupField
                  id="baki_luas_lot_diambil_m2"
                  label="Meter Persegi"
                  labelWidth={"160px"}
                  step="any"
                  isInvalid={!!errors.baki_luas_lot_diambil_m2}
                  errorMessage={errors.baki_luas_lot_diambil_m2?.message}
                  readOnly={action === "show"}
                  disabled
                  {...register("baki_luas_lot_diambil_m2")}
                />
              </SimpleGrid>
            </FormControl>
          </GridItem>

          <SelectField
            label={"Tujuan Pengambilan"}
            options={tujuanPengambilanOps}
            isInvalid={!!errors.id_tujuan_pengambilan}
            errorMessage={errors.id_tujuan_pengambilan?.message}
            readOnly={action === "show"}
            {...register("id_tujuan_pengambilan")}
          />

          <TextField
            id="deskripsi_pengambilan"
            label="Deskripsi Pengambilan"
            type="textarea"
            isInvalid={!!errors.deskripsi_pengambilan}
            errorMessage={errors.deskripsi_pengambilan?.message}
            readOnly={action === "show"}
            {...register("deskripsi_pengambilan")}
          />

          <TextField
            id="no_warta"
            label="No. Warta"
            isInvalid={!!errors.no_warta}
            errorMessage={errors.no_warta?.message}
            readOnly={action === "show"}
            {...register("no_warta")}
          />

          <TextField
            id="tarikh_borang_e"
            label="Tarikh Borang E"
            type="date"
            isInvalid={!!errors.tarikh_borang_e}
            errorMessage={errors.tarikh_borang_e?.message}
            readOnly={action === "show"}
            {...register("tarikh_borang_e")}
          />

          <TextField
            id="nama"
            label="Nama"
            isInvalid={!!errors.nama}
            errorMessage={errors.nama?.message}
            readOnly={action === "show"}
            {...register("nama")}
          />

          <TextField
            id="alamat_rumah"
            label="Alamat Rumah"
            type="textarea"
            isInvalid={!!errors.alamat_rumah}
            errorMessage={errors.alamat_rumah?.message}
            readOnly={action === "show"}
            {...register("alamat_rumah")}
          />

          <TextField
            id="no_fon"
            label="No. Tel"
            isInvalid={!!errors.no_fon}
            errorMessage={errors.no_fon?.message}
            readOnly={action === "show"}
            {...register("no_fon")}
          />

          <TextField
            id="no_akaun"
            label="No. Akaun"
            isInvalid={!!errors.no_akaun}
            errorMessage={errors.no_akaun?.message}
            readOnly={action === "show"}
            {...register("no_akaun")}
          />

          <TextField
            id="jenis_akaun"
            label="Jenis Akaun"
            isInvalid={!!errors.jenis_akaun}
            errorMessage={errors.jenis_akaun?.message}
            readOnly={action === "show"}
            {...register("jenis_akaun")}
          />

          <TextField
            id="nama_bank"
            label="Nama Bank"
            isInvalid={!!errors.nama_bank}
            errorMessage={errors.nama_bank?.message}
            readOnly={action === "show"}
            {...register("nama_bank")}
          />

          <TextField
            id="warganegara"
            label="Warganegara"
            isInvalid={!!errors.warganegara}
            errorMessage={errors.warganegara?.message}
            readOnly={action === "show"}
            {...register("warganegara")}
          />

          <TextField
            id="tarikh_borang_f"
            label="Tarikh Borang F"
            type="date"
            isInvalid={!!errors.tarikh_borang_f}
            errorMessage={errors.tarikh_borang_f?.message}
            readOnly={action === "show"}
            {...register("tarikh_borang_f")}
          />

          <TextField
            id="kod_program"
            label="Kod Program"
            isInvalid={!!errors.kod_program}
            errorMessage={errors.kod_program?.message}
            readOnly={action === "show"}
            {...register("kod_program")}
          />

          <TextField
            id="no_resit_rasmi"
            label="No. Resit Rasmi"
            isInvalid={!!errors.no_resit_rasmi}
            errorMessage={errors.no_resit_rasmi?.message}
            readOnly={action === "show"}
            {...register("no_resit_rasmi")}
          />

          <TextField
            id="terima_daripada"
            label="Terima Daripada"
            placeholder="PEJABAT DAERAH DAN TANAH (PDT)"
            isInvalid={!!errors.terima_daripada}
            errorMessage={errors.terima_daripada?.message}
            readOnly={action === "show"}
            {...register("terima_daripada")}
          />

          <TextField
            id="tarikh_resit"
            label="Tarikh Resit"
            type="date"
            isInvalid={!!errors.tarikh_resit}
            errorMessage={errors.tarikh_resit?.message}
            readOnly={action === "show"}
            {...register("tarikh_resit")}
          />

          <TextField
            id="perihal_terimaan"
            label="Perihal Terimaan"
            isInvalid={!!errors.perihal_terimaan}
            errorMessage={errors.perihal_terimaan?.message}
            readOnly={action === "show"}
            {...register("perihal_terimaan")}
          />

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Jumlah Pampasan Bangunan
            </FormLabel>

            <NumberGroupField
              id="jumlah_pampasan_bangunan"
              label="RM"
              step="any"
              isInvalid={!!errors.jumlah_pampasan_bangunan}
              errorMessage={errors.jumlah_pampasan_bangunan?.message}
              readOnly={action === "show"}
              {...register("jumlah_pampasan_bangunan")}
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Jumlah Pampasan Tanah
            </FormLabel>

            <NumberGroupField
              id="jumlah_pampasan_tanah"
              label="RM"
              step="any"
              isInvalid={!!errors.jumlah_pampasan_tanah}
              errorMessage={errors.jumlah_pampasan_tanah?.message}
              readOnly={action === "show"}
              {...register("jumlah_pampasan_tanah")}
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              Jumlah Keseluruhan
            </FormLabel>

            <NumberGroupField
              id="jumlah"
              label="RM"
              step="any"
              isInvalid={!!errors.jumlah}
              errorMessage={errors.jumlah?.message}
              readOnly={action === "show"}
              {...register("jumlah")}
            />
          </FormControl>

          <TextField
            id="tarikh_borang_k"
            label="Tarikh Borang K"
            type="date"
            isInvalid={!!errors.tarikh_borang_k}
            errorMessage={errors.tarikh_borang_k?.message}
            readOnly={action === "show"}
            {...register("tarikh_borang_k")}
          />
        </Grid>

        <Section size="md" title="Lampiran Pengambilan Tanah">
          <FileUploadField
            files={files}
            setFiles={setFiles}
            fileDescriptions={fileDescriptions}
            setFileDescriptions={setFileDescriptions}
            tabName="aset_wakaf_istibdal_pengambilan"
            readOnly={action === "show"}
          />
        </Section>
      </form>
    </Flex>
  );
};

export default FormAcquisitionLand;
