import React from "react";
import FormPerolehWakafSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { usePerolehWakafSetting } from "hooks/setting/dataSetting/perolehWakafSetting/usePerolehWakafSetting";

export default function EditPerolehWakafSetting() {
  const { isLoading, perolehWakaf, error } = usePerolehWakafSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormPerolehWakafSetting action="edit" initialData={perolehWakaf?.data} />;
}
