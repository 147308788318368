import { format } from 'date-fns';

// Handle On Change Input
export const handleOnChangeInput = (
  e: React.ChangeEvent<HTMLSelectElement>,
  setType: (e: string) => void
) => {
  const selectedApplicationType = e.target.value;
  setType(selectedApplicationType);
};

// Fetch Label
export const fetchLabel = (value: string | number, options: any) => {
  const option = options.find((option: any) => option.value == value);
  return option ? option.label : ''; 
};

// Format Date
export const formatDate = (dateString: string | undefined, dateFormat: string = 'yyyy-MM-dd') => {
  return dateString ? format(new Date(dateString), dateFormat) : '';
};

// Measurement Conversion
export const convertsSquareFeetToHectare = (
  value: number
) => {
  return parseFloat((value / 107639.104).toFixed(3));
};

export const convertsSquareFeetToAcre = (
  value: number
) => {
  return parseFloat((value / 43560).toFixed(3));
};

export const convertsSquareFeetToSquareMeter = (
  value: number
) => {
  return parseFloat((value / 10.764).toFixed(3));
};

export const convertsSquareMeterToHectare = (
  value: number
) => {
  return parseFloat((value / 10000).toFixed(3));
};

export const convertsSquareMeterToAcre = (
  value: number
) => {
  return parseFloat((value / 4046.86).toFixed(3));
};

export const convertsAcreToHectare = (
  value: number
) => {
  return parseFloat((value * 0.404686).toFixed(3));
};

export const convertsAcreToSquareMeter = (
  value: number
) => {
  return parseFloat((value * 4046.86).toFixed(3));
};

export const convertsAcreToSquareFeet = (
  value: number
) => {
  return parseFloat((value * 43560).toFixed(3));
};

export const handleAreaConversion = (type: string, value: number) => {
  let hektar, ekar, m2, kp2;

  if (type === "kp2") {
    hektar = convertsSquareFeetToHectare(value);
    ekar = convertsSquareFeetToAcre(value);
    m2 = convertsSquareFeetToSquareMeter(value);
  } else if (type === "m2") {
    hektar = convertsSquareMeterToHectare(value);
    ekar = convertsSquareMeterToAcre(value);
  } else if (type === "ekar") {
    hektar = convertsAcreToHectare(value);
    m2 = convertsAcreToSquareMeter(value);
    kp2 = convertsAcreToSquareFeet(value);
  }

  return { hektar, ekar, m2, kp2 };
};