import axios from "utils/axios";
import { IBaitulmalMaintenanceFormValues } from "entities/admin/baitulmalManagement";

export async function getBaitulmalMaintenances(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/penyelenggaraan-aset-baitulmal",
    params
  });
  return data;
}

export async function getBaitulmalMaintenance(id: number) {
  const { data } = await axios.call({
    method: "get",
    url: `/penyelenggaraan-aset-baitulmal/${id}`,
  });
  return data;
}

export async function createEditBaitulmalMaintenance(
  values: IBaitulmalMaintenanceFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/penyelenggaraan-aset-baitulmal/${id}` : "/penyelenggaraan-aset-baitulmal";
  const formData = id ? { ...values, _method: 'PUT' } : values;

  const { data } = await axios.call({
    customContentType: 'multipart/form-data',
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteBaitulmalMaintenance(
  id: string | number | null
) {
  const apiUrl = `/penyelenggaraan-aset-baitulmal/${id}`;

  const { data } = await axios.call({
    method: "delete",
    url: apiUrl,
  });
  return data;
}