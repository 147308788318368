import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/dataSetting/useOptions";
import { useHistory, useLocation } from "react-router-dom";

export default function SortingWakafIstibdal() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const [searchValues, setSearchValues] = useState({
    sebabIstibdal: "",
    noLot: "",
    noPT: "",
    jenisAset: "",
    statusIstibdal: ""
  });

  const { jenisAsetOps, jenisPengambilanIstibdalOps, istibdalStatusOps } = useOptions();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const search = () => {
    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      sebabIstibdal: "",
      noLot: "",
      noPT: "",
      jenisAset: "",
      statusIstibdal: ""
    });

    document.querySelectorAll('select').forEach(select => select.value = "");

    history.push({ search: searchParams.toString() });
  };

  const getSearchParams = (key: string) => {
    return searchParams.get(key);
  };

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <SelectField
          label={"Jenis Aset"}
          value={searchValues.jenisAset ?? getSearchParams("jenisAset")}
          options={jenisAsetOps}
          onChange={(e: any) => handleOnChange(e, "jenisAset")}
        />

        <SelectField
          label={"Sebab Istibdal"}
          value={searchValues.sebabIstibdal ?? getSearchParams("sebabIstibdal")}
          options={jenisPengambilanIstibdalOps}
          onChange={(e: any) => handleOnChange(e, "sebabIstibdal")}
        />

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No Lot
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="100897"
            fontWeight="500"
            size="md"
            value={searchValues.noLot ?? getSearchParams("noLot")}
            onChange={(e: any) => handleOnTextChange(e, "noLot")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No PT
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.noPT ?? getSearchParams("noPT")}
            onChange={(e: any) => handleOnTextChange(e, "noPT")}
          />
        </FormControl>

        <SelectField
          label={"Status Istibdal"}
          value={searchValues.statusIstibdal ?? getSearchParams("statusIstibdal")}
          options={istibdalStatusOps}
          onChange={(e: any) => handleOnChange(e, "statusIstibdal")}
        />
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>
      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}