import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

import FormMaintenance from "./FormMaintenance";
import TableMaintenance from "./TableMaintenance";

interface TabMaintenanceProps {
  wakafAssetId?: number;
}

const TabMaintenance: React.FC<TabMaintenanceProps> = ({ wakafAssetId }) => {
  const [action, setAction] = useState<"create" | "edit" | "show">("create");
  const [wakafMaintenanceId, setWakafMaintenanceId] = useState<number | null>(null);
  const [showFormAssessment, setShowFormAssessment] = useState(false);

  const toggleFormMaintenance = (action: "create" | "edit" | "show", wakafMaintenanceId?: number) => {
    setAction(action);
    setWakafMaintenanceId(wakafMaintenanceId);
    setShowFormAssessment(true);
  };

  return (
    <Flex direction="column">
      <TableMaintenance
        wakafAssetId={wakafAssetId}
        toggleFormMaintenance={toggleFormMaintenance}
      />

      {showFormAssessment && (
        <FormMaintenance
          action={action}
          wakafAssetId={wakafAssetId}
          wakafMaintenanceId={wakafMaintenanceId}
        />
      )}
    </Flex>
  );
};

export default TabMaintenance;
