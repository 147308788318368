import React from "react";
import FormStateSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useDistrictSetting } from "hooks/setting/dataSetting/districtSetting/useDistrictSetting";

export default function EditDistrictSetting() {
  const { isLoading, district, error } = useDistrictSetting();

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormStateSetting action="edit" initialData={district?.data} />;
}
