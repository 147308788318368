import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { deleteWakafTax } from "services/admin/wakafManagement/wakafTaxService";

export function useDeleteWakafTax() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: removeWakafTax, isLoading: isDeleting } = useMutation({
    mutationFn: (id: number) => deleteWakafTax(id),
    onSuccess: () => {
      toast({
        title: "Wakaf tax successfully deleted",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["wakafTaxes"] });
      history.push("/admin/wakaf/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isDeleting, removeWakafTax };
}

