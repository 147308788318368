import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditWakafIstibdalInfo } from "services/admin/wakafManagement/wakafIstibdal";
import { IWakafIstibdalInfoFormValues } from "entities/admin/wakafManagement";

export function useEditWakafIstibdalInfo() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: editWakafIstibdalInfo, isLoading: isEditing } = useMutation({
    mutationFn: ({
      newWakafIstibdalInfo,
      id,
    }: {
      newWakafIstibdalInfo: IWakafIstibdalInfoFormValues;
      id: string | number | null;
    }) => createEditWakafIstibdalInfo(newWakafIstibdalInfo, id),
    onSuccess: () => {
      toast({
        title: "Wakaf istibdal info successfully updated",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["wakafIstibdalInfo", "wakafIstibdalInfos"] });
      history.push("/admin/wakaf/aset");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isEditing, editWakafIstibdalInfo };
}
